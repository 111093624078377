import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {apiUrl} from "../../environments/environment";
import {Area, AreaWeight} from "../_models/area";
import {Observable} from "rxjs";
import {RoomAreaHistoryItem} from "../_models/roomAreaHistoryItem";
import {AreaComment} from "../_models/areaComment";
import {FormFile} from "../_models/material";

@Injectable({
  providedIn: 'root'
})
export class AreasService {

  constructor(private http: HttpClient) { }

  updateAreaStatusById(areaId: number, statusId: number) {
    let timestamp = new Date().getTime();
    return this.http.put(`${apiUrl}/area/${areaId}/status`, null,{params: {timestamp, statusId: statusId}, withCredentials: true})
  }

  updateAreaWeightById(areaId: number, weight: AreaWeight) {
    let timestamp = new Date().getTime();
    return this.http.put(`${apiUrl}/area/${areaId}/weight`, null,{params: {timestamp, areaWeight: weight}, withCredentials: true})
  }
  updateAreaStatusProgressById(areaId: number, progress: number) {
    let timestamp = new Date().getTime();
    return this.http.put(`${apiUrl}/area/${areaId}/progress`, null, {params: {progress, timestamp}, withCredentials: true})
  }

  getAreaHistory(areaId: number): Observable<RoomAreaHistoryItem[]> {
    return this.http.get<RoomAreaHistoryItem[]>(`${apiUrl}/area/${areaId}/history`, {withCredentials: true})
  }

  getAreaComments(areaId: number): Observable<AreaComment[]> {
    return this.http.get<AreaComment[]>(`${apiUrl}/comment`, {params: {areaId: areaId}, withCredentials: true})
  }

  createAreaComment(areaId: number, comment: FormData): Observable<AreaComment> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post<AreaComment>(`${apiUrl}/comment`, comment, {params: {areaId: areaId}, headers: headers, withCredentials: true})
  }

  editAreaComment(comment: FormData): Observable<AreaComment> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.put<AreaComment>(`${apiUrl}/comment`, comment, {withCredentials: true})
  }

  deleteAreaComment(commentId: number) {
    let params = new HttpParams();
    params = params.append('commentId', commentId);
    return this.http.delete(`${apiUrl}/comment`, {params: params, withCredentials: true})
  }

  deleteAreaFile(areaId: number, fileId: number) {
    let params = new HttpParams();
    params = params.append('fileId', fileId);
    return this.http.delete(`${apiUrl}/area/${areaId}/files`, {params: params, withCredentials: true})
  }

  getAreaById(areaId: number): Observable<Area> {
    return this.http.get<Area>(`${apiUrl}/area/${areaId}`, {withCredentials: true})
  }

  updateAreaFiles(areaId: number, files: FormData): Observable<FormFile[]> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.put<FormFile[]>(`${apiUrl}/area/${areaId}/files`, files, {withCredentials: true})
  }

}
