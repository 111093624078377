import {
  Component,
  EventEmitter, isDevMode,
  OnDestroy,
  Output,
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Company} from "../../../../../_models/company";
import {UsersService} from "../../../../../_services/users.service";
import {NotificationService} from "../../../../../_services/notification.service";
import {InviteUser, User, UserRole} from "../../../../../_models/user";
import {CompaniesService} from "../../../../../_services/companies.service";
import {modalAnimation, userRoleStringForSelector} from 'src/app/config/constants';
import {convertUserRoleToEnum} from "../../../../../_util/utils";
import {emailValidatorByPattern, requiredValidator} from "../../../../../_util/validators";
import {HttpErrorResponse} from "@angular/common/http";
import {GlobalStore} from "../../../../../global.store";
import {Subject, take, takeUntil} from "rxjs";
import {DomainsService} from "../../../../../_services/domains.service";
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-invite-user-form',
  templateUrl: './invite-user-form.component.html',
  styleUrls: ['./invite-user-form.component.scss'],
  animations: [modalAnimation]
})
export class InviteUserFormComponent implements OnDestroy{
  @Output() created:EventEmitter<any> = new EventEmitter<any>();
  @Output() close:EventEmitter<any> = new EventEmitter<any>();
  private readonly destroy$ = new Subject<void>();
  inviteUser = new InviteUser();
  companiesList: Company[] = [];
  currentUser: User;
  submitBtnDisabled = false;
  userInvitationForm: FormGroup;

  userCompany: Company;

  companyId: number;
  projectId: number;

  constructor(private fb: FormBuilder,
              private userService: UsersService,
              private notifService: NotificationService,
              private companyService: CompaniesService,
              readonly globalStore: GlobalStore,
              private domainService: DomainsService) {
    this.globalStore.currentUser$.pipe(take(1)).subscribe((user) => this.currentUser = user);

    this.globalStore.projectId$.pipe(takeUntil(this.destroy$)).subscribe((projectId) => this.projectId = projectId);
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => {
      this.companyId = companyId;
      if (companyId) {
        this.inviteUser.companyId = companyId;
        this.companyService.getCompanyById(companyId).subscribe((data) => {
          this.userCompany = data;
          this.companiesList = [data];
          this.fillForm()
          this.subscribeToFormChanges();
        });
      } else {
        this.companyService.getShortCompanyList().subscribe((data) => {
          this.companiesList = data;
          this.fillForm()
          this.subscribeToFormChanges();
        });
      }
    });
  }

  subscribeToFormChanges(){
    this.userInvitationForm.valueChanges.subscribe(() => {
      this.inviteUser.email = this.userInvitationForm.controls['email'].value;
      this.inviteUser.role = convertUserRoleToEnum(this.userInvitationForm.controls['role'].value);
      this.inviteUser.projectIds = this.userInvitationForm.controls['projectIds'].value;
      this.inviteUser.companyId = this.userInvitationForm.controls['companyId'].value;
    })
    this.userInvitationForm.controls['companyId'].valueChanges.subscribe(() => this.changeCompany(this.userInvitationForm.controls['companyId'].value))
  }

  fillForm() {
    this.userInvitationForm = this.fb.group({
      email: [null, [emailValidatorByPattern(), requiredValidator()]],
      companyId: [{value: this.userCompany?.id || null, disabled: this.companyId}, Validators.required ],
      role: [{value: this.projectId || this.currentUser.role === UserRole.ROLE_PROJECT_MANAGER ? UserRole.ROLE_EMPLOYEE.toString() : UserRole.ROLE_ADMIN.toString(), disabled: this.currentUser.role === UserRole.ROLE_EMPLOYEE || this.currentUser.role === UserRole.ROLE_PROJECT_MANAGER} , Validators.required],
      projectIds: [{
        value: this.projectId ? this.userCompany?.projects.filter(project => project.id === this.projectId).map(e => e.id) : [],
        disabled: !!this.projectId
      }],
    })
  }

  addAllProjects(event: any) {
    event.preventDefault()
    let projectIds = this.userCompany.projects.map(project => project.id)
    this.userInvitationForm.controls['projectIds'].setValue(projectIds)
  }

  addAttrsToFilterByRoleDropdownOptions() {
    setTimeout(() => {
      this.userRoleStringForSelector.forEach(role => {
        // @ts-ignore
        document.querySelectorAll('#userRole .ng-option-label').forEach((e: HTMLElement) => {
          if(role === e.textContent) {
            e.setAttribute('role', `${role}`)
          }
        })
      })
    });
  }

  submitForm() {
    if (this.userInvitationForm.invalid) return;

    if (environment.production) {
      this.domainService.checkSite(this.userCompany.subdomain).subscribe((result) => {
        if (result) this.sendSubmitRequest();
        else alert("Please first register subdomain with name - " + this.userCompany.subdomain);
      })
    } else this.sendSubmitRequest();
  }

  sendSubmitRequest() {
    this.submitBtnDisabled = true;

    this.userService.inviteUser(this.inviteUser).subscribe(() => {
      this.created.emit();
      this.notifService.successNotification("Changes have been saved")
    }, (error: HttpErrorResponse) => {
      this.submitBtnDisabled = false;
      if (error.status === 400 && error.error.error === 'User already exists') this.userInvitationForm.controls['email'].setErrors({error: 'User with this email already exists.'});
    });
  }

  changeCompany(companyId: number) {
    this.companyService.getCompanyById(companyId).subscribe((data) => this.userCompany = data);
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly UserRole = UserRole;
  protected readonly userRoleStringForSelector = userRoleStringForSelector;
}
