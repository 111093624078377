import {ComponentStore} from "@ngrx/component-store";
import {Injectable} from "@angular/core";
import {Company} from "../../../../_models/company";
import {Project} from "../../../../_models/project";
import {GlobalState} from "../../../../global.store";

export interface DashboardState {
  companiesTotalAmount: number | null;
  projectTotalAmount: number | null;
  tasksTotalAmount: number | null;
}

@Injectable()
export class DashboardStore extends ComponentStore<DashboardState> {

  constructor() {
    super({
      companiesTotalAmount: null,
      projectTotalAmount: null,
      tasksTotalAmount: null,
    });
  }

  readonly companiesTotalAmount$ = this.select(
    (state) => state.companiesTotalAmount ?? null,
  );

  readonly projectsTotalAmount$ = this.select(
    (state) => state.projectTotalAmount ?? null,
  );

  readonly tasksTotalAmount$ = this.select(
    (state) => state.tasksTotalAmount ?? null,
  );

  readonly updateCompaniesTotalAmount = this.updater((state: DashboardState, newAmount: number) => {
    return {
      ...state,
      companiesTotalAmount: newAmount
    };
  });

  readonly updateProjectsTotalAmount = this.updater((state: DashboardState, newAmount: number) => {
    return {
      ...state,
      projectTotalAmount: newAmount
    };
  });

  readonly updateTasksTotalAmount = this.updater((state: DashboardState, newAmount: number) => {
    return {
      ...state,
      tasksTotalAmount: newAmount
    };
  });
}
