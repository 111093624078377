<div class="backdrop">
  <div class="modal">
    <form class="form-invite" #form="ngForm" firstInputAutofocus scrollToFirstInvalidInput [formGroup]="usersInvitationForm">
      <button type="button" id="btnClose" class="close-modal-btn" (click)="close.emit()">
        <svg class="close-modal-icon">
          <use spriteVersion iconName="modal-close"></use>
        </svg>
      </button>
      <div class="form-inner-wrapper">
        <p class="form-modal-title">Add Existing User</p>

        <div class="main-fields-wrapper">
          <p class="main-error" *ngIf="usersInvitationForm.invalid && form.submitted" id="mainError">
            <svg class="error-icon">
              <use spriteVersion iconName="error-icon"></use>
            </svg>
            All fields are required
          </p>

          <div class="field-wrapper project-list">
            <label class="form-label project-optional-label"> Users </label>
            <span class="input-with-error-wrapper">
              <div class="ng-select-wrapper ng-select-wrapper--wider">
                <ng-select #usersSelector id="selectUsers"
                           formControlName="users"
                           [clearable]="false"
                           notFoundText="No results matching"
                           [hideSelected]="true"
                           [multiple]="true"
                           placeholder="Select Users">
                  <ng-option *ngFor="let user of usersNotInProject" [value]="user.id">{{user.name}}</ng-option>
                  <ng-template [ngIf]="usersNotInProject.length !== 0" ng-footer-tmp><a [attr.hasUsers]="usersNotInProject.length !== 0 && usersNotInProject.length !== usersSelector.selectedValues.length" (click)="addAllUsers($event)">Add All Users</a></ng-template>
                  <ng-option *ngIf="usersNotInProject.length === usersSelector.selectedValues.length" value="no-results" disabled>{{usersSelector.notFoundText}}</ng-option>
                </ng-select>
              </div>
              <span class="small-error-text-field" *ngIf="form.submitted && usersInvitationForm.controls['users'].invalid">This field is required</span>
            </span>
          </div>

          <p class="info-room-text">
            <svg class="exclamaition-icon">
              <use spriteVersion iconName="exclamation-circle"></use>
            </svg>
            <span>
              Here you can select users who are already in the system
            </span>
          </p>
        </div>
      </div>

      <div class="form-btn-wrapper">
        <button type="button" class="btn-cancel" id="btnCancel" (click)="close.emit()">Cancel</button>
        <button type="submit" class="btn-invite" id="btnAddUsers" [disabled]="submitBtnDisabled" loader="white" [showLoader]="submitBtnDisabled" (click)="submitForm()">Add to Project</button>
      </div>
    </form>
  </div>
</div>

