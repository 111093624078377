<li class="rooms-list__item" #roomItem>
    <div class="room-name">
        <div class="room-arrow-with-name-wrapper" [title]="room.name">
            <svg class="room-arrow-icon" (click)="onRollUpRoom()">
                <use spriteVersion iconName="arrow-room"></use>
            </svg>
          <span>{{room.name}} {{room.subtitle ? room.subtitle : ''}}</span>
            <svg class="room-template-icon" *ngIf="room.templateName">
                <use spriteVersion iconName="template-icon"></use>
            </svg>
        </div>
        <div class="menu-list-dots-wrapper" *ngIf="(globalStore.currentUser$ | async).role !== UserRole.ROLE_EMPLOYEE" (appOutsideClick)="editMenuRoom.removeAttribute('editMenuRoomOpen'); projectViewStore.updateIsCdkDragDisabled(false)" [enabled]="editMenuRoom.hasAttribute('editMenuRoomOpen')">
            <span class="menu-list-dots menu-list-dots-room" id="menuRoomDots" (click)="editMenuRoom.toggleAttribute('editMenuRoomOpen'); projectViewStore.updateIsCdkDragDisabled(!this.isCdkDragDisabled)"></span>
            <ul class="editMenuList" #editMenuRoom>
                <li class="editMenuList__item" id="editRoomBtn" (click)="onEditRoom(room.templateName); editMenuRoom.removeAttribute('editMenuRoomOpen')">
                    <svg class="editMenu-icon">
                        <use spriteVersion iconName="edit-icon"></use>
                    </svg>
                    Edit
                </li>
                <li class="editMenuList__item" id="deleteRoomBtn" (click)="editMenuRoom.removeAttribute('editMenuRoomOpen'); onDeleteRoom()">
                    <svg class="editMenu-icon">
                        <use spriteVersion iconName="trash-delete-icon"></use>
                    </svg>
                    Delete
                </li>
            </ul>
        </div>
    </div>
    <ul class="room-areas-list" #roomAreasList>
        <li class="room-areas-list__item" *ngFor="let area of room.areas" (click)="openAreaModal(area)">
          <div class="area-box-with-name-wrapper">
            <span class="area-box-status" [attr.areaStatus]="area.status.orderNumber" [attr.opacity]="setAreaOpacity(area)" [style.background-color]="area.status.orderNumber > 0 ? area.status.color : ''"></span>
            <span>{{area.name}}</span>
          </div>
          <div class="area-info-wrapper">
              <span class="area-info-item" *ngIf="area.attachmentsAmount > 0">
                  <svg class="area-info-icon">
                      <use spriteVersion iconName="attachment-area-mini"></use>
                  </svg>
                  {{area.attachmentsAmount}}
              </span>

          </div>
        </li>

        <li class="room-areas-list__item room-areas-list__item--no-area" *ngIf="room.areas.length === 0">
            No areas
        </li>
    </ul>
</li>



