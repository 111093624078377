import {Directive, ElementRef, EventEmitter, Inject, Output, PLATFORM_ID} from "@angular/core";
import {isPlatformBrowser} from "@angular/common";

@Directive({
  selector: '[isLowerThenMiddleOfScreen]'
})
export class IsLowerThenMiddleOfScreen {

  @Output() isLowerThenMiddleOfScreen: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(elementRef: ElementRef, @Inject(PLATFORM_ID) public platformId: any) {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        const windowHeight = window.innerHeight;
        const elementTop = elementRef.nativeElement.getBoundingClientRect().top;
        const distanceToBottom = windowHeight - elementTop;
        if (distanceToBottom < windowHeight / 2 && distanceToBottom < 200) this.isLowerThenMiddleOfScreen.emit();
      })
    }
  }
}
