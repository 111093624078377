import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Room} from "../_models/room";
import {apiUrl} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  constructor(private http: HttpClient) {}

  getRoomTemplatesShortList(projectId: number) {
    return this.http.get<Room[]>(`${apiUrl}/template/short`, {params: {projectId: projectId}, withCredentials: true})
  }

  getTemplateById(templateId: number) {
    return this.http.get<Room>(`${apiUrl}/template/${templateId}`, {withCredentials: true})
  }

  updateTemplate(templateId: number, template: Room) {
    return this.http.put<Room>(`${apiUrl}/template/${templateId}`,template, {withCredentials: true})
  }

  deleteTemplateById(templateId: number) {
    return this.http.delete(`${apiUrl}/template/${templateId}`, {withCredentials: true})
  }
}
