import {Directive, HostListener} from "@angular/core";

@Directive({
  selector: '[scrollToFirstInvalidInput]'
})
export class ScrollToFirstInvalidInputDirective {
  invalidFieldFound = false;
  timePassed = 0;
  maxTimeWaitingForInvalidFieldMs = 1000;
  checkIntervalMs = 100;
  interval: any;

  @HostListener('submit', ['$event'])
  onSubmit(event: Event) {
    const form = event.target as HTMLFormElement;
    const firstInvalidInput = form.querySelector('.ng-invalid');
    if (firstInvalidInput) {
      firstInvalidInput.scrollIntoView();
      this.invalidFieldFound = true;
    } else this.invalidFieldFound = false;

    if (!this.invalidFieldFound) {
      this.interval = setInterval(() => {
        const firstInvalidInput = form.querySelector('.ng-invalid');
        if (firstInvalidInput) {
          firstInvalidInput.scrollIntoView();
          this.clearInterval();
        }
        if (this.timePassed >= this.maxTimeWaitingForInvalidFieldMs) this.clearInterval()
        else this.timePassed += this.checkIntervalMs;
      }, this.checkIntervalMs)
    }
  }

  clearInterval() {
    this.invalidFieldFound = false;
    clearInterval(this.interval);
    this.timePassed = 0;
  }
}
