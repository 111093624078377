<div class="backdrop">
  <ng-scrollbar class="remove-overflow-on-tablet modal">
    <form class="form-invite form-create" #form="ngForm" autofocus firstInputAutofocus scrollToFirstInvalidInput [formGroup]="projectForm" (ngSubmit)="submitForm()" appAutoTrim>
      <button type="button" id="btnCloseMobile" class="close-modal-btn" (click)="close.emit()">
        <svg class="close-modal-icon">
          <use spriteVersion iconName="modal-close"></use>
        </svg>
      </button>
      <div class="form-modal-way-wrapper" *ngIf="project.id">
        <ng-template [ngIf]="(globalStore.currentUser$ | async).role === UserRole.ROLE_SUPERADMIN">
          <span class="form-modal-way">{{ project.company.name }}</span>
          /
        </ng-template>
        <span class="form-modal-way">{{ projectOld.name }}</span>
        /
        <span class="form-modal-way project-code-way">{{ project.publicId }}</span>
      </div>
      <div class="form-inner-wrapper" [attr.paddingTop]="!!project.id">
        <p class="form-modal-title">{{ projectForm.controls['id'].value ? 'Edit Project' : 'Create Project' }}</p>

        <div class="main-fields-wrapper">

          <p class="main-error" *ngIf="projectForm.invalid && form.submitted" id="mainErrorMobile">
            <svg class="error-icon">
              <use spriteVersion iconName="error-icon"></use>
            </svg>
            All fields are required
          </p>

          <div class="group-fields-wrapper">
            <div class="field-wrapper">
              <label class="form-label">Project Name</label>
              <div class="input-with-error-wrapper">
                <input class="form-input-modal" id="projectNameMobile" formInput="NAME" formControlName="name"
                       placeholder="Project Name">
                <p class="small-error-text-field" id="projectNameErrorMobile"
                   *ngIf="projectForm.controls['name'].invalid && form.submitted">
                  {{ projectForm.controls['name'].getError('error') }}
                </p>

                <div class="flex-field-wrapper">
                  <div class="field-wrapper-shorter">
                    <span class="input-with-error-wrapper">
                      <input class="form-input-modal"
                             mask="A||AA||AAA||AAAA||AAAAA||AAAAAA||AAAAAAA||AAAAAAAA||AAAAAAAAA||AAAAAAAAAA"
                             [ngClass]="project.id ? 'form-input-modal--disabled' : projectCodeError ? ['projectCodeError'] : ''"
                             id="projectCode" maxlength="4" formInput="PROJECT_CODE" formControlName="projectCode"
                             placeholder="Code">
                      <p class="small-error-text-field" id="projectCodeErrorMobile"
                         *ngIf="projectForm.controls['projectCode'].invalid && form.submitted">
                        {{ projectForm.controls['projectCode'].getError('error') }}
                      </p>
                      <p class="small-error-text-field" id="projectCodeExistErrorMobile"
                         *ngIf="projectForm.controls['projectCode'].invalid && projectCodeError && !form.submitted">
                        {{ projectForm.controls['projectCode'].getError('error') }}
                      </p>
                    </span>
                  </div>
                  <div class="field-wrapper-shorter">
                    <span class="input-with-error-wrapper">
                      <input class="form-input-modal" id="projectNumberMobile" formInput="PROJECT_NUMBER"
                             formControlName="number" placeholder="Project #">
                      <p class="small-error-text-field" id="projectNumberErrorMobile"
                         *ngIf="projectForm.controls['number'].invalid && form.submitted">
                        {{ projectForm.controls['number'].getError('error') }}
                      </p>
                    </span>
                  </div>
                </div>

                <div class="field-wrapper" *ngIf="!project.id && !companyId">
                  <span class="input-with-error-wrapper">
                    <div class="ng-select-wrapper ng-select-wrapper--wider">
                      <ng-select class="custom select-without-absolute-last-option"
                                 id="projectSelectCompanyMobile"
                                 bindLabel="name"
                                 bindValue="id"
                                 formControlName="companyId"
                                 [clearable]="false"
                                 notFoundText="No results matching"
                                 [editableSearchTerm]="true"
                                 [searchable]="true"
                                 placeholder="Company Name">
                        <ng-option *ngFor="let company of companies" [value]="company.id">{{ company.name }}</ng-option>
                      </ng-select>
                    </div>
                    <p class="small-error-text-field" id="projectSelectCompanyErrorMobile"
                       *ngIf="projectForm.controls['companyId'].invalid && form.submitted">
                      This field is required
                    </p>
                  </span>
                </div>
              </div>

            </div>
          </div>


          <div class="group-fields-wrapper">
            <div class="flex-field-wrapper">
              <div class="field-wrapper field-wrapper-shorter">
                <label class="form-label">Address</label>

                <div class="input-with-error-wrapper">

                  <input class="form-input-modal" id="projectAddressMobile" formInput="ADDRESS" formControlName="address1" placeholder="Street address, P.O. box.">
                  <p class="small-error-text-field" id="projectAddressErrorMobile"
                     *ngIf="projectForm.controls['address1'].invalid && form.submitted">
                    {{ projectForm.controls['address1'].getError('error') }}
                  </p>

                  <input class="form-input-modal" id="projectAddressSecond" formInput="ADDRESS" formControlName="address2" placeholder="Apartment, suite, unit, building, floor, etc.">
                  <p class="small-error-text-field" id="projectAddressSecondError" *ngIf="projectForm.controls['address2'].invalid && form.submitted">{{projectForm.controls['address2'].getError('error')}}</p>


                  <div class="flex-field-wrapper">
                    <div class="field-wrapper-shorter">
                        <span class="input-with-error-wrapper">
                          <input class="form-input-modal" id="projectCity" formInput="ADDRESS" formControlName="city" placeholder="City">
                          <p class="small-error-text-field" id="projectCityError" *ngIf="projectForm.controls['city'].invalid && form.submitted">{{projectForm.controls['city'].getError('error')}}</p>
                        </span>
                    </div>

                    <div class="input-with-error-wrapper">
                      <div class="ng-select-wrapper field-wrapper-shorter">
                        <ng-select class="custom select-without-absolute-last-option custom-select-city"
                                   id="projectSelectStateMobile"
                                   bindLabel="name"
                                   bindValue="id"
                                   formControlName="state"
                                   [clearable]="false"
                                   notFoundText="No results matching"
                                   [editableSearchTerm]="true"
                                   [searchable]="true"
                                   placeholder="Select State">
                          <ng-option *ngFor="let state of states" [value]="state">{{ state }}</ng-option>
                        </ng-select>
                      </div>
                      <p class="small-error-text-field" id="projectSelectStateErrorMobile"
                         *ngIf="projectForm.controls['state'].invalid && form.submitted">
                        This field is required
                      </p>
                    </div>

                    <div class="field-wrapper-shorter">
                        <span class="input-with-error-wrapper">
                          <input class="form-input-modal"  id="projectZipCode" [mask]="'00000'" formInput="ZIP_CODE" formControlName="zipCode" placeholder="ZIP Code">
                          <p class="small-error-text-field" id="projectZipCodeError" *ngIf="projectForm.controls['zipCode'].invalid && form.submitted">{{projectForm.controls['zipCode'].getError('error')}}</p>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="flex-field-wrapper">

            <div class="field-wrapper field-wrapper-shorter">
              <label class="form-label project-optional-label"><span class="label-text">Client Name</span></label>
              <span class="input-with-error-wrapper">
                <input class="form-input-modal" [matAutocomplete]="auto" formInput="NAME" id="projectClientNameMobile"
                       formControlName="clientName" placeholder="Client Name">
                <p class="small-error-text-field" id="projectClientNameErrorMobile"
                   *ngIf="projectForm.controls['clientName'].invalid && form.submitted">
                  {{ projectForm.controls['clientName'].getError('error') }}
                </p>
              </span>

              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let value of filteredCompanyClients | async" [value]="value">
                  {{ value }}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>


          <div class="date-wrapper">
            <div class="field-wrapper">
              <label class="form-label project-optional-label">Period<span class="project-optional-text">(Optional)</span></label>
              <div class="date-with-error-wrapper">
                <div class="flex-field-wrapper">
                  <div class="input-with-error-wrapper">
                    <span class="date-label">Start Date</span>
                    <app-custom-datepicker [id]="'projectStartDate'"
                                           [formControl]="getFormControl('startDate')">
                    </app-custom-datepicker>
                  </div>
                  <div class="input-with-error-wrapper">
                    <span class="date-label">End Date</span>
                    <app-custom-datepicker [id]="'projectEndDate'"
                                           [formControl]="getFormControl('endDate')">
                    </app-custom-datepicker>
                  </div>
                </div>
                <p class="small-error-text-field" id="projectDateErrorMobile" style="text-align: center"
                   *ngIf="(projectForm.controls['endDate'].invalid || projectForm.controls['startDate'].invalid) && form.submitted">
                  End date cannot be less then Start date
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-btn-wrapper">
        <button type="button" class="btn-cancel" id="btnCancelMobile" (click)="close.emit()">Cancel</button>
        <button type="submit" class="btn-invite" id="btnSubmitMobile" [disabled]="submitBtnDisabled" loader="white"
                [showLoader]="submitBtnDisabled">{{ projectForm.controls['id'].value ? 'Update Project' : 'Create Project' }}
        </button>
      </div>
    </form>
  </ng-scrollbar>
</div>
