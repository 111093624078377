import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {apiUrl} from "../../environments/environment";
import {map, tap} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class VersionFromBackendService {

  constructor(private http: HttpClient) { }

  getVersion() {
    return this.http.get<any>(`${apiUrl}/version`, {withCredentials: true, observe: 'response'}).pipe(
      map(data => {
        return data.body.version;
      })
    )
  }
}
