import {User} from "./user";
import {FormFile} from "./material";

export class AreaComment {
  id: number;
  timestamp: number;
  author: User;
  html: any;
  safeHtml: any;
  files: FormFile[] = [];
}
