import {Directive, ElementRef, Input, SimpleChanges} from '@angular/core';
import {default as  hashes} from 'src/hashes.json';

@Directive({
  selector: '[appDocumentsTypeIcon]'
})
export class DocumentsTypeIconDirective {
  @Input() documentFile!: { name: string }; // Вхідний параметр файлу

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['documentFile']) {
      const iconName = this.getIconNameBasedOnFormat(this.documentFile);
      (this.el.nativeElement as HTMLElement).setAttribute('href', '/assets/sprite.svg?v=' + hashes.sprite + '#' + iconName);
    }
  }

  private getIconNameBasedOnFormat(documentFile: { name: string }): string {
    const fileName = documentFile?.name
    const isArchive = fileName.endsWith('.zip') || fileName.endsWith('.rar') || fileName.endsWith('.7z') || fileName.endsWith('cab')
    if (fileName.endsWith('.pdf')) {
      return 'pdf';
    }
    if (fileName.endsWith('.doc') || fileName.endsWith('.docx') ) {
      return 'doc';
    }
    if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx')) {
      return 'xls';
    }
    if (isArchive) {
      return 'archive';
    }
    return 'otherDocumentFormat'; // Іконка за замовчуванням для невідомих форматів
  }

}
