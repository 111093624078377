import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient
} from '@angular/common/http';
import {Observable, switchMap} from 'rxjs';
import {environment} from "../../environments/environment";


@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

  constructor(
    private http: HttpClient
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(environment.serverUrl)) {
      let modifiedReq = req.clone({
        setHeaders: {'X-Current-URL': window.location.href}
      });

      if (environment.csrfEnabled && ['POST', 'DELETE', 'PUT'].includes(req.method)) {
        return this.http
          .get<{ token: string }>(`${environment.serverUrl}/csrf/token`, {withCredentials: true})
          .pipe(
            switchMap((result) => next.handle(modifiedReq.clone({setHeaders: {'X-XSRF-TOKEN': JSON.parse(JSON.stringify(result)).token}})))
          );
      } else {
        return next.handle(modifiedReq);
      }
    } else {
      return next.handle(req);
    }
  }
}

