<li class="swiper-slide unit-without-bg"  #unitElements>
  <div class="unit-name-with-btn-wrapper">
    <div [title]="unit.name" class="unit-name-wrapper">
      <p (dblclick)="openEditUnitForm(unit)" ellipsis [ellipsis-content]="unit.name"></p>
      <span class="unit-order">({{unit.rooms.length}})</span>
    </div>
    <div class="unit-btn-with-dots-wrapper" *ngIf="(globalStore.currentUser$ | async).role !== UserRole.ROLE_EMPLOYEE">
      <div class="add-room-btn-with-menu-wrapper" (appOutsideClick)="isAddRoomMenuOpened = false; projectViewStore.updateIsCdkDragDisabled(false)">
        <button type="button" class="add-room-btn" id="addRoom" (click)="addRoomClicked()">Add Room</button>
        <div *ngIf="isAddRoomMenuOpened" [@modalAnimation] class="add-floor-menu add-room-menu">
          <button type="button" id="createNewRoom" class="create-new-room-btn" (click)="openCreateRoomForm()">
            <svg class="create-icon">
              <use spriteVersion iconName="create-icon"></use>
            </svg>
            Create New Room
          </button>
          <p class="duplicate-text">Use Template</p>
          <ul class="duplicate-floor-variants-list">
            <li *ngFor="let room of this.projectViewStore.roomTemplatesShortList$ | async" class="duplicate-floor-variants__item rooms-variants__item" [id]="room.id" (click)="openCreateRoomBasedOnTemplateRoomForm(room)">
              {{room.name}}
              <div class="template-icons-wrapper" stopClickPropagation>
                <svg class="editRoom-icon" (click)="onOpenEditTemplate(room)">
                  <use spriteVersion iconName="edit-template-icon"></use>
                </svg>
                <svg class="editRoom-icon" (click)="openDeleteTemplate(room)">
                  <use spriteVersion iconName="trash-delete-icon"></use>
                </svg>
              </div>
            </li>
          </ul>
        </div>
      </div>


      <div (appOutsideClick)="isUnitMenuOpened = false; projectViewStore.updateIsCdkDragDisabled(false)" [ngStyle]="{display: 'flex', alignItems: 'center', justifyContent: 'center'}">

        <span class="menu-list-dots" id="menuListAddRoomOpen" (click)="isUnitMenuOpened = !isUnitMenuOpened; projectViewStore.updateIsCdkDragDisabled(!isCdkDragDisabled)"></span>

        <ul *ngIf="isUnitMenuOpened" [@modalAnimation] class="editMenuList">
          <li class="editMenuList__item" id="editUnitBtn" (click)="openEditUnitForm(unit)">
            <svg class="editMenu-icon">
              <use spriteVersion iconName="edit-icon"></use>
            </svg>
            Edit
          </li>
          <li class="editMenuList__item" id="deleteUnitBtn" (click)="isUnitMenuOpened = false;; openDeletingPopup.emit({type: 'unit', template: null, unit: unit}); projectViewStore.updateIsCdkDragDisabled(true)">
            <svg class="editMenu-icon">
              <use spriteVersion iconName="trash-delete-icon"></use>
            </svg>
            Delete
          </li>
        </ul>
      </div>
    </div>
  </div>

  <form class="form-create-unit-instead-btn"
        *ngIf="isUnitFormOpened"
        firstInputAutofocus
        #ngFormEditUnit="ngForm"
        stopClickPropagation [formGroup]="unitForm" (ngSubmit)="updateUnitName()"
        (appOutsideClick)="unitEditCloseBtn.click()">
    <input type="text" class="input-create-item" id="editUnitInput" formInput="FLOOR_UNIT_NAME" formControlName="name">
    <p class="small-error-text-field" id="unitNameError" *ngIf="ngFormEditUnit.submitted && unitForm.controls['name'].invalid">{{unitForm.controls['name'].getError('error')}}</p>
    <div class="form-create-btn-wrapper">
      <button type="submit" id="editUnitBtnSave" class="create-item-btn">Save</button>
      <button type="reset" id="canselBtnEditUnit" class="cansel-create-btn" #unitEditCloseBtn (click)="isUnitFormOpened = false; projectViewStore.updateIsCdkDragDisabled(false)">
        <svg class="close-icon-form">
          <use spriteVersion iconName="close-icon"></use>
        </svg>
      </button>
    </div>
  </form>

  <!--Rooms/Areas-->

  <ul class="rooms-list"
      #roomList
      cdkDropList
      cdkDropListOrientation="vertical"
      [cdkDropListAutoScrollStep]="40"
      [cdkDropListDisabled]="isCdkDragDisabled"
      (cdkDropListDropped)="dropRooms($event)"
      [style.height]="unitElements.scrollHeight - 70 + 'px'">
    <app-room-block cdkDrag
                    [cdkDragDisabled]="projectViewStore.isCdkDragDisabled$ | async"
                    [cdkDragData]="{unitId: unit.id, room: room}"
                    cdkDragPreviewContainer="parent" *ngFor="let room of unit.rooms" [room]="room"
                    [id]="'room-' + room.id"
                    [unit]="unit"
                    [currentFloor]="currentFloor"
                    [isCdkDragDisabled]="isCdkDragDisabled"
                    (rollUpRoom)="roomList.toggleAttribute('rollUp')">
    </app-room-block>
  </ul>
</li>

