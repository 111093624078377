import {Component, OnDestroy} from '@angular/core';
import {Router} from "@angular/router";
import {CompaniesService} from "../../../../_services/companies.service";
import {NotificationService} from "../../../../_services/notification.service";
import {companyTypeByEnum} from "../../../../config/constants";
import {User, UserRole} from "../../../../_models/user";
import {GlobalStore} from "../../../../global.store";
import {Company} from "../../../../_models/company";
import {Subject, Subscription, switchMap, takeUntil} from "rxjs";

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss']
})
export class CompanySettingsComponent implements OnDestroy {
  companyEditFormOpened = false;
  isOpenConfirmDeletingModal: boolean = false;
  deletingBtnDisabled = false;

  company: Company;

  private readonly destroy$ = new Subject<void>()


  constructor(private companyService: CompaniesService,
              private notifService: NotificationService,
              private router: Router,
              readonly globalStore: GlobalStore) {
    this.globalStore.companyId$
      .pipe(
        switchMap((companyId) => this.companyService.getCompanyById(companyId)),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.company = data;
        this.globalStore.updateCompany(data);

      });
  }


  deleteCompany() {
    this.deletingBtnDisabled = true;
    this.companyService.deleteCompany([this.company.id]).subscribe({
      next: () => {
        this.notifService.successNotification("Changes have been saved")
        this.router.navigateByUrl('/system/companies')
        this.deletingBtnDisabled = false;
      },
      error: () => {
        this.isOpenConfirmDeletingModal = false;
        this.deletingBtnDisabled = false;
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly companyTypeByEnum = companyTypeByEnum;
    protected readonly UserRole = UserRole;
}
