import {AfterViewInit, Directive, ElementRef, EventEmitter, Inject, Input, Output, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

@Directive({
  selector: '[isLowerThenMiddleOfContainer]'
})
export class IsLowerThenMiddleOfContainerDirective {

  @Input() containerElement: ElementRef;
  @Input() isScrollable: boolean;
  @Output() isLowerThenMiddleOfContainer: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private elementRef: ElementRef, @Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => this.checkElementPosition());
    }
  }

  private checkElementPosition(): void {
    const element = this.elementRef.nativeElement;
    const containerElement = this.containerElement.nativeElement;

    const firstParent = containerElement.parentElement;
    const secondParent = firstParent.parentElement;

    if(!this.isScrollable) return

    const elementRelativeTop = element.getBoundingClientRect().top - secondParent.getBoundingClientRect().top;

    const isLower = (secondParent.clientHeight / elementRelativeTop) / 2 < 1;

    if (isLower) this.isLowerThenMiddleOfContainer.emit();
  }

}
