<div class="activity-area-wrapper__editor-with-image" [attr.marginBottom]="comment ? 'true' : 'false'">
    <label class="comment-label" *ngIf="!comment">Comment:</label>
    <div (mouseup)="isShowEditorMenu = true" [attr.focus]="isShowEditorMenu || !!comment" class="NgxEditor__Wrapper" [style.margin-bottom]="getSumCommentFiles().length !== 0 ? '8px': ''">
        <div id="editor-{{randEditorId}}"></div>
        <div [style.display]="isShowEditorMenu || !!comment ? 'flex' : 'none'" id="toolbar-{{randEditorId}}">
          <div class="toolbar-wrapper">
            <span class="ql-formats">
               <button (click)="quill.getModule('mention').openMenu('@')" id="custom-mention"><svg><use spriteVersion iconName="mention"></use></svg></button>
            </span>
            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
              <button class="ql-strike"></button>
            </span>
            <span class="ql-formats">
              <button class="ql-list" value="ordered"></button>
              <button class="ql-list" value="bullet"></button>
            </span>
            <span class="ql-formats">
              <button class="ql-link"></button>
            </span>
            <span class="ql-formats">
                <button class="custom-file" type="button" (appOutsideClick)="fileInput.removeAttribute('open')" fileDropArea (click)="fileInput.click(); fileInput.setAttribute('open', 'true')" [inputElement]="fileInput"  (filesDropped)="toggleStatusLoadingFile($event)">
                    <input type="file" #fileInput class="input-file" accept="audio/*,video/*,image/*,application/*" id="fileInput" >
                    <svg><use spriteVersion iconName="attachment-icon"></use></svg>
                </button>
            </span>
          </div>
          <div class="btn-editor-wrapper">
            <button type="button" id="canselBtnEditComment" class="cansel-create-btn" (click)="cancelComment()">Cancel</button>
            <button type="submit" loader="white" [showLoader]="showLoader" [disabled]="submitBtnDisabled" class="btn-save-comment" (click)="sendComment(); fileInput.value = ''">Save</button>
          </div>
        </div>
    </div>

    <p class="small-error-text-field upload-file-error" id="uploadFileError" *ngIf="errorUploadFileSize || errorUploadFilesAmount">{{errorUploadFileSize ? 'Error: Unable to upload. The file size must not exceed 25MB.' : 'Error: Unable to upload. The number of files must not exceed 4.'}}</p>

    <ul class="files-list">
        <li class="file-item" *ngFor="let file of getSumCommentFiles()">
            <div class="file-item-inner-wrapper">
              <img [src]="file.imgSrc || file.fileUrl" class="file-img" *ngIf="(file.imgSrc || file.fileUrl) && isFileCanBePreviewed(file.name)">
              <span *ngIf="!isFileCanBePreviewed(file.name)" class="document-icon-wrapper">
                <svg width="32" height="32" class="document-icon">
                  <use appDocumentsTypeIcon [documentFile]="file"></use>
                </svg>
              </span>
                <div class="fileName-with-loader">
                    <p class="fileName-with-timeLeft">{{file.name | sliceText: 40}} <span *ngIf="file.progress < 100" class="timeLeft">{{file.progress + '% / ' + file.timeLeft + 's'}}</span></p>
                    <p *ngIf="file.progress < 100"  class="loadingBar" id="loaderFile" [style.background]="'linear-gradient(to right, #FF4E00 0%, #FF4E00 ' + file.progress + '%, #F0F2F6 ' + file.progress + '%), #FF4E00 100%'"></p>
                    <p *ngIf="file.progress === 100" class="fileSize" id="fileSize">{{file.sizeBytes | fileSizePrettify}}</p>
                </div>
            </div>
            <button (click)="removeFile(file)" class="delete-file-btn">
                <svg class="delete-icon" >
                    <use spriteVersion iconName="trash-delete-icon"></use>
                </svg>
            </button>
        </li>
    </ul>
</div>
