import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivityService} from "../../../../../_services/activity.service";
import {Activity} from "../../../../../_models/activity";
import {Page} from "../../../../../_models/page";
import {TimePrettifyPipe} from "../../../../../_pipes/timePrettify.pipe";
import {DatePipe} from "@angular/common";
import {Router} from "@angular/router";
import {GlobalStore} from "../../../../../global.store";
import {Subject, Subscription, takeUntil} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-dashboard-activity-table',
  templateUrl: './dashboard-activity-table.component.html',
  styleUrls: ['./dashboard-activity-table.component.scss'],
  providers: [TimePrettifyPipe, DatePipe]
})
export class DashboardActivityTableComponent implements OnInit, OnDestroy {
  page = 0;
  pageSize = 10;
  activityPage: Page<Activity> = new Page<Activity>()
  isIOS = this.globalStore.isIOS$;
  currentTime = Date.now();
  isLoading = false;
  companyId: number;

  private readonly destroy$ = new Subject<void>()

  constructor(private activitySecrvice: ActivityService,
              private timePrettify: TimePrettifyPipe,
              private date: DatePipe,
              readonly globalStore: GlobalStore,
              public router: Router,
              private sanitizer: DomSanitizer) {
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => this.companyId = companyId);
  }

  ngOnInit() {
    this.getActivityList();
  }

  getActivityList() {
    this.isLoading = true;
    this.activitySecrvice.getActivityList(this.companyId, null, this.page, this.pageSize).subscribe(data => {
      this.activityPage = {
        ...data,
        content: [...this.activityPage.content, ...data.content]
      };
      this.isLoading = false;
    })
  }

  getTimeCreatedAtActivity(createdAt: number) {
    let diffTime = this.currentTime - createdAt;
    let seconds = Math.floor(diffTime/1000);
    let minutes = Math.floor(seconds/60);
    let hours = Math.floor(minutes/60);
    let days = Math.floor(hours/24);
    if (days >= 2) return this.date.transform(createdAt, 'MMM d, y h:mm a')
    else return this.timePrettify.transform(createdAt)
  }

  sanitizeHtmlText(text: any) {
    document.querySelectorAll('b[areastatus]').forEach((el: Element) => el.classList.add('areaStatusActivity'))
    return this.sanitizer.bypassSecurityTrustHtml(text)
  }

  getNextPage() {
    if (this.activityPage.last) return;

    this.page += 1;
    this.getActivityList()
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
