import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  OnDestroy, OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormControl} from "@angular/forms";
import {UsersService} from "../../../../../_services/users.service";
import {NotificationService} from "../../../../../_services/notification.service";
import {HttpErrorResponse} from "@angular/common/http";
import {modalAnimation} from 'src/app/config/constants';
import {matchValidator, minLengthValidator, requiredValidator} from "../../../../../_util/validators";

class ChangePasswordInfo {
  oldPassword: string
  newPassword: string
}

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['../../../../auth/auth.scss', './change-password-form.component.scss', ],
  animations: [modalAnimation]
})
export class ChangePasswordFormComponent {
  private _passwordInfo = new ChangePasswordInfo()
  @HostBinding('@modalAnimation') modalAnimation: any;
  isShowPassword: boolean = false
  isShowConfirmPassword: boolean = false
  isShowCurrentPassword = false;
  submitBtnDisabled = false;

  userChangePasswordForm = this.fb.group({
    currentPassword: ['', [minLengthValidator('PASSWORD'), requiredValidator()]],
    newPassword: new FormControl('', [matchValidator('confirmPassword', true), minLengthValidator('PASSWORD'), requiredValidator()]),
    confirmPassword: new FormControl('', [matchValidator('newPassword'), minLengthValidator('PASSWORD'), requiredValidator()]),
  })

  @Output() created: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  constructor(private fb: FormBuilder,
              private userService: UsersService,
              private notifService: NotificationService) {
    this.subscribeToFormChanges();
  }

  subscribeToFormChanges() {
    this.userChangePasswordForm.valueChanges.subscribe(() => {
      this._passwordInfo.oldPassword = this.userChangePasswordForm.controls['currentPassword'].value;
      this._passwordInfo.newPassword = this.userChangePasswordForm.controls['newPassword'].value;
    })
  }

  showPassword() {
    this.isShowPassword = !this.isShowPassword;
  }

  showCurrentPassword() {
    this.isShowCurrentPassword = !this.isShowCurrentPassword;
  }

  showConfirmPassword() {
    this.isShowConfirmPassword = !this.isShowConfirmPassword;
  }

  submitForm() {
    if (this.userChangePasswordForm.invalid) return;
    this.userChangePasswordForm.setErrors(null);

    this.submitBtnDisabled = true;

    this.userService.updateUserPassword(this._passwordInfo).subscribe(() => {
        this.created.emit();
        this.notifService.successNotification("Changes have been saved")
      },
      (error: HttpErrorResponse) => {
        this.submitBtnDisabled = false;
        if (error.status === 400) {
          if (error.error.error === 'Passwords not match') this.userChangePasswordForm.setErrors({error: 'Invalid current password'});
          else if (error.error.error === 'Same password') this.userChangePasswordForm.setErrors({error: 'New password should be different from current one'});
        }
      });
  }
}







