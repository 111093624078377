import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Material, MaterialPaymentStatus, OrderStatus, SubmittalStatus} from "../_models/material";
import {Observable} from "rxjs";
import {Page} from "../_models/page";
import {apiUrl} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MaterialPaymentStatusService {

  constructor(private http: HttpClient) { }

  getAllMaterialPaymentStatuses(projectId: number):Observable<MaterialPaymentStatus[]> {
    return this.http.get<MaterialPaymentStatus[]>(`${apiUrl}/material/paymentStatus`, {params: {projectId: projectId}, withCredentials: true})
  }

  createPaymentStatus(projectId: number, name: string) {
    return this.http.post(`${apiUrl}/material/paymentStatus`, name,{params: {projectId: projectId}, withCredentials: true})
  }

  deletePaymentStatus(paymentStatusId: number) {
    let params = new HttpParams();
    if (paymentStatusId) params = params.set('id', paymentStatusId);

    return this.http.delete(`${apiUrl}/material/paymentStatus`, {
      params: params,
      observe: "response",
      withCredentials: true
    })
  }
}
