import { ChangeDetectionStrategy, Component, EventEmitter, Input, TrackByFunction, Output } from '@angular/core';
import { Floor } from '../../../../../../_models/floor';
import { AreaClickPayload } from '../../types';
import {ProjectProgressStore} from "../project-progress.store";
import {ProjectProgressUserSettings} from "../../../../../../_models/projectProgressUserSettings";


@Component({
  selector: 'app-floors-list',
  templateUrl: './floors-list.component.html',
  styleUrls: ['./floors-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloorsListComponent {
  @Input() public floorsFilter: number[] = [];
  @Input() public amountMocksVertical: number;
  @Input() public amountMocksHorizontal: number;
  @Input() public designSettings: ProjectProgressUserSettings;
  @Input() public isNotDesktopScreen: boolean;
  @Output() public areaClick = new EventEmitter<AreaClickPayload>();
  @Output() public areaMouseOver = new EventEmitter<HTMLElement>();
  @Output() public areaMouseLeave = new EventEmitter<void>();

  trackByFloor: TrackByFunction<Floor> = (_: number, floor: Floor): number => {
    return floor.id;
  }

  constructor(readonly projectProgressStore: ProjectProgressStore) {
  }
}
