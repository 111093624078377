import {Component} from '@angular/core';
import {UserRole} from "../../../../../../_models/user";
import {Floor} from "../../../../../../_models/floor";
import {ProjectProgressStore} from "../project-progress.store";

@Component({
  selector: 'app-filters-project-progress',
  templateUrl: './filters-project-progress.component.html',
  styleUrls: ['./filters-project-progress.component.scss']
})
export class FiltersProjectProgressComponent {
  floors$ = this.projectProgressStore.floors$;
  floorFilter: number[] = [];
  floorFilterCopyForModify: number[] = [];

  isSelectorOpened = false;

  constructor(readonly projectProgressStore: ProjectProgressStore) {
    this.projectProgressStore.floorFilter$.subscribe((data) => {
      this.floorFilter = data;
      this.floorFilterCopyForModify = [...data];
    })
  }

  closeSelector() {
    this.isSelectorOpened = false;
    this.floorFilterCopyForModify = [...this.floorFilter];
  }

  toggleDropdown() {
    if (this.isSelectorOpened) this.closeSelector();
    else this.isSelectorOpened = true;
  }

  toggleCheck(floorId: number) {
    if (this.floorFilterCopyForModify.includes(floorId)) {
      this.floorFilterCopyForModify = this.floorFilterCopyForModify.filter(e => e !== floorId);
    } else {
      this.floorFilterCopyForModify = [...this.floorFilterCopyForModify, floorId];
    }
    return
  }

  setFilters() {
    this.projectProgressStore.updateFloorFilter([...this.floorFilterCopyForModify]);
    this.closeSelector();
  }

  clearAllFilters() {
    this.projectProgressStore.updateFloorFilter(null);
  }

  protected readonly UserRole = UserRole;
  protected readonly Floor = Floor;
}
