import {inject} from '@angular/core';
import {AuthService} from "../_services/auth.service";
import {Router} from "@angular/router";
import {UserRole} from "../_models/user";

export const superAdminGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const currentUser =  authService.getCurrentUser();

  if (currentUser && currentUser.role === UserRole.ROLE_SUPERADMIN ) return true;
  // Redirect to the login page
 return router.parseUrl('/login');
};
