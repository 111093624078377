import {Component, OnDestroy} from '@angular/core';
import {GlobalStore} from "../../../../global.store";
import {NotificationService} from "../../../../_services/notification.service";

@Component({
  selector: 'app-system-home',
  templateUrl: './system-home.component.html',
  styleUrls: ['./system-home.component.scss']
})
export class SystemHomeComponent implements OnDestroy {
  constructor(private notificationService: NotificationService,
              readonly globalStore: GlobalStore) {
    this.notificationService.connectToWebsocket();
  }

  ngOnDestroy() {
    this.notificationService.socket.close();

    if (this.notificationService.stompClient && this.notificationService.stompClient.connected) {
      this.notificationService.stompClient.disconnect(() => {
        console.log('WebSocket disconnected');
      });
    }
  }
}
