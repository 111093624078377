import {
  Component,
  ElementRef,
  EventEmitter,
  Input, OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {AreasService} from "../../../../../../../_services/areas.service";
import {AreaComment} from "../../../../../../../_models/areaComment";
import {User, UserRole} from "../../../../../../../_models/user";
import {NotificationService} from "../../../../../../../_services/notification.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {AreaFormStore} from "../area-form.store";
import {SelectedArea} from "../../../types";
import {GlobalStore} from "../../../../../../../global.store";
import {Subject, Subscription, take, takeUntil} from "rxjs";
import {waitForElm} from "../../../../../../../_util/utils";
import {Router} from "@angular/router";

const fadeIn = trigger('fadeIn', [
  state('void', style({ opacity: 0 })),
  transition(':enter, :leave', [animate(300)]),
]);

@Component({
  selector: 'app-activity-area',
  templateUrl: './activity-area.component.html',
  styleUrls: ['./activity-area.component.scss'],
  animations: [fadeIn]
})
export class ActivityAreaComponent implements OnDestroy {
  @ViewChild('fileInput') fileInputRef: ElementRef;
  currentUser: User;
  imageFormatsToRenderImage: string[] = ['jpeg', 'png', 'svg', 'webp', 'jpg'];

  @Input() selectedArea: SelectedArea;
  @Output() updated: EventEmitter<void> = new EventEmitter();
  @Output() fileToPreview: EventEmitter<any> = new EventEmitter<any>()

  comments: AreaComment[] = [];
  companyId: number;
  projectId: number;

  private readonly destroy$ = new Subject<void>()

  constructor(private areasService: AreasService,
              readonly globalStore: GlobalStore,
              public notifService: NotificationService,
              private router: Router,
              private areaFormStore: AreaFormStore) {
    this.globalStore.currentUser$.pipe(take(1)).subscribe((user) => this.currentUser = user);
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => this.companyId = companyId);
    this.globalStore.projectId$.pipe(takeUntil(this.destroy$)).subscribe((projectId) => this.projectId = projectId);
    this.areaFormStore.comments$.subscribe((data) => this.comments = data);
  }

  commentClicked(event: any) {
    let mentionItem = event.target.parentElement;
    if (mentionItem.classList.contains('mention') && mentionItem.getAttribute("no-link") !== 'true') {
      if (this.currentUser.role === UserRole.ROLE_EMPLOYEE) this.router.navigateByUrl(`/system/company/${this.companyId}/profile`);
      else this.router.navigateByUrl(`/system/company/${this.companyId}/users/${mentionItem.getAttribute('data-id')}`);
    }
  }

  isFileCanBePreviewed(fileName: string) {
    let result = false;
    this.imageFormatsToRenderImage.forEach(e => {
      if (fileName.endsWith(e)) {
        result = true;
        return;
      }
    })
    return result;
  }

  createComment(comment: AreaComment) {
    this.areaFormStore.addComment(comment);
    this.selectedArea.area.commentsAmount = this.selectedArea.area.comments.length + 1;
    this.selectedArea.area.attachmentsAmount = this.selectedArea.area.files.length
    this.updated.emit();
  }

  editComment(comment: AreaComment) {
    this.areaFormStore.updateComment(comment);
    this.selectedArea.area.attachmentsAmount = this.selectedArea.area.files.length
    this.updated.emit();
  }

  deleteComment(comment: AreaComment) {
    this.areasService.deleteAreaComment(comment.id).subscribe( () => {
      this.notifService.successNotification('Changes have been saved')
      this.areaFormStore.deleteComment(comment.id);
      this.selectedArea.area.commentsAmount = this.selectedArea.area.comments.length - 1;
      this.selectedArea.area.attachmentsAmount = this.selectedArea.area.files.length
      this.updated.emit();
    })
  }

  updateAttachments(filesAmount: number) {
    this.selectedArea.area.attachmentsAmount = filesAmount
    this.selectedArea.area.commentsAmount = this.selectedArea.area.comments.length
    this.updated.emit();
  }

  scrollTo(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center'});
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly UserRole = UserRole;
}
