import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from "@angular/router";
import {ProjectCustomStatuses} from "../../../../../_models/project";
import {UserRole} from "../../../../../_models/user";
import { SwiperOptions } from 'swiper';
import {GlobalStore} from "../../../../../global.store";
import {ProjectProgressUserSettings} from "../../../../../_models/projectProgressUserSettings";

@Component({
  selector: 'app-view-area-status',
  templateUrl: './view-area-status.component.html',
  styleUrls: ['./view-area-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewAreaStatusComponent {
  config: SwiperOptions = {
    navigation: false,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
    slidesPerView: 'auto',
    grabCursor: true,
    freeMode: false
  };

  private _statusesInUseAmount: Map<number, number>;
  totalElements = 0;
  isViewPage = false;
  isOpenStatusMenu = false;
  openCustomizeForm = false;
  openDesignSettingsForm = false;

  isRoomOrderingMode = false;
  isAreaOrderingMode = false;
  saveBtnDisabled = false;

  @Output() updateStatuses: EventEmitter<void> = new EventEmitter<void>();
  @Output() startOrderChanging: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelOrderChanging: EventEmitter<any> = new EventEmitter<any>();
  @Output() deletedStatusWithReplacement: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveOrder: EventEmitter<any> = new EventEmitter<any>();
  @Input() roomsAmount: number;
  private _projectStatuses: ProjectCustomStatuses[];
  private _designSettings: ProjectProgressUserSettings;

  get statusesInUseAmount(): Map<number, number> {
    return this._statusesInUseAmount;
  }

  @Input()
  set statusesInUseAmount(value: Map<number, number>) {
    this._statusesInUseAmount = value;
    if (this.projectStatuses) this.fillData();
  }

  get projectStatuses(): ProjectCustomStatuses[] {
    return this._projectStatuses;
  }

  @Input()
  set projectStatuses(value: ProjectCustomStatuses[]) {
    this._projectStatuses = value;
    if (this.statusesInUseAmount) this.fillData();
  }

  get designSettings() {
    return this._designSettings;
  }

  @Input()
  set designSettings (value: ProjectProgressUserSettings) {
    if(value) this._designSettings = value
  }



  constructor(private route: Router,
              readonly globalStore: GlobalStore) {
    this.isViewPage = this.route.routerState.snapshot.url.includes('view');
  }

  fillData() {
      this.totalElements = 0;
      this.statusesInUseAmount.forEach((value, key) => this.totalElements += value);
  }

  cancelOrder() {
    if(this.isRoomOrderingMode) {
      this.isRoomOrderingMode = false;
      this.cancelOrderChanging.emit('rooms')
    }
    else {
      this.isAreaOrderingMode = false;
      this.cancelOrderChanging.emit('areas')
    }
  }

  protected readonly Math = Math;
  protected readonly UserRole = UserRole;
}
