<div class="backdrop">
  <div class="modal">
    <form class="form-invite form-create" autofocus firstInputAutofocus scrollToFirstInvalidInput appAutoTrim #form="ngForm" [formGroup]="documentForm" (ngSubmit)="submitForm()">
      <button type="button" id="btnClose" class="close-modal-btn" (click)="close.emit()">
        <svg class="close-modal-icon">
          <use spriteVersion iconName="modal-close"></use>
        </svg>
      </button>

      <div class="form-modal-way-wrapper" *ngIf="document.id">
        <ng-template [ngIf]="(globalStore.currentUser$ | async).role === UserRole.ROLE_SUPERADMIN">
          <span class="form-modal-way">{{globalStore.companyName$ | async}}</span>
          /
        </ng-template>
        <span class="form-modal-way">{{globalStore.projectName$ | async}}</span>
        /
        <span class="form-modal-way">{{linkInitialName}}</span>
      </div>

      <div class="main-form-inner-wrapper">
        <div class="form-inner-wrapper" [attr.paddingTop]="!!document.id">
          <p class="form-modal-title">{{documentForm.controls['id'].value ? 'Edit Link' : 'Add Document'}}</p>

          <div class="main-fields-wrapper">
            <div class="document-type-btns-wrapper" *ngIf="!document.id">
              <button type="button" class="btn-document-type" [attr.active]="selectedDocumentType === DocumentType.FILE" (click)="changeDocumentType('file')">
                <span class="btn-document-type__icon-wrapper">
                  <svg class="btn-document-type__icon">
                    <use spriteVersion iconName="new-file-icon"></use>
                  </svg>
                </span>
                File
              </button>
              <button type="button" class="btn-document-type" [attr.active]="selectedDocumentType === DocumentType.LINK" (click)="changeDocumentType('link')">
                <span class="btn-document-type__icon-wrapper">
                  <svg class="btn-document-type__icon">
                    <use spriteVersion iconName="new-link-icon"></use>
                  </svg>
                </span>
                Link
              </button>
            </div>
            <p class="main-error" *ngIf="documentForm.invalid && form.submitted" id="mainErrorMobile">
              <svg class="error-icon">
                <use spriteVersion iconName="error-icon"></use>
              </svg>
              All fields are required
            </p>

            <div class="group-fields-wrapper" *ngIf="document.id || selectedDocumentType === DocumentType.LINK">
              <div class="field-wrapper">
                <label class="form-label"><span class="label-text">Link Name</span></label>
                <span class="input-with-error-wrapper">
                  <input class="form-input-modal" id="documentName" formInput="LINK_NAME" formControlName="name" placeholder="Link Name">
                  <p class="small-error-text-field" id="documentNameError" *ngIf="documentForm.controls['name'].invalid && form.submitted">{{documentForm.controls['name'].getError('error')}}</p>
                </span>
              </div>
              <div class="field-wrapper">
                <label class="form-label"><span class="label-text">Link</span></label>
                <span class="input-with-error-wrapper">
                  <input class="form-input-modal" id="documentLink" formControlName="link" placeholder="Document Link">
                  <p class="small-error-text-field" id="documentLinkError" *ngIf="documentForm.controls['link'].invalid && form.submitted">{{documentForm.controls['link'].getError('error')}}</p>
                </span>
              </div>
            </div>

            <div class="field-wrapper" *ngIf="selectedDocumentType === DocumentType.FILE">
              <label class="form-label">Add Files</label>
              <app-add-files-to-form (updatedNewFilesList)=updateFiles($event)></app-add-files-to-form>
            </div>
          </div>
        </div>
      </div>

      <div class="form-btn-wrapper">
        <button type="button" class="btn-cancel"  id="btnCancel" (click)="close.emit()">Cancel</button>
        <button type="submit" class="btn-invite" [disabled]="submitBtnDisabled || (selectedDocumentType === DocumentType.FILE && filesList.length === 0)
                || (selectedDocumentType === DocumentType.LINK && (!documentForm.controls['name'].value || !documentForm.controls['link'].value || documentForm.controls['name'].invalid || documentForm.controls['link'].invalid))" id="btnSubmit" loader="white" [showLoader]="submitBtnDisabled">{{!this.document.id ? 'Add Document' : 'Save'}}</button>
      </div>
    </form>

  </div>
</div>
