import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {apiUrl} from "../../environments/environment";
import {Room} from "../_models/room";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RoomsService {

  constructor(private http: HttpClient) { }

  createRoom(unitId: number, templateId: number, isDetached: boolean, templateName: string, room: Room): Observable<Room> {
    let params = new HttpParams()
    params = params.append('unitId', unitId)
    if(templateName && typeof templateName !== 'number' ) params = params.append('templateName', templateName)
    if(templateId) params = params.append('templateId', templateId);
    if(isDetached) params = params.append('isDetached', isDetached);
    return this.http.post<Room>(`${apiUrl}/room`, room,  {params: params, withCredentials: true})
  }

  updateRoom(room: Room,  newTemplateId: number, newTemplateName: string | null, isDetached: boolean): Observable<Room> {
    let params = new HttpParams();
    if(newTemplateName) params = params.append('newTemplateName', newTemplateName);
    if(newTemplateId) params = params.append('newTemplateId', newTemplateId);
    if(isDetached) params = params.append('isDetached', isDetached);
    return this.http.put<Room>(`${apiUrl}/room`, room, {params: params, withCredentials: true})
  }

  updateRoomOrder(roomId: number, newOrder: number,) {
    return this.http.put(`${apiUrl}/room/${roomId}/order?newOrder=${newOrder}`, null, {withCredentials: true})
  }

  deleteRoom(roomId: number) {
    let params = new HttpParams().append('id', roomId);
    return this.http.delete(`${apiUrl}/room`, {params: params, withCredentials: true})
  }
}
