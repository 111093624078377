
<ng-scrollbar [style.height]="tasksPage.content.length > 0 && tasksPage.content.length < 4 ? tasksPage.content.length*48 + 46 + 'px' :  tasksPage.content.length === 0 ? '134px' : '218px'" [attr.isIOS]="(isIOS | async)"  [autoHeightDisabled]="true" [track]="'all'" class="table-wrapper-with-dots">
  <div class="innerScrollBarWrapper">
    <div [attr.pagination-showed]="this.tasksPage.totalElements > 20" class="table-wrapper-with-scroll">
      <div class="table-tasks" *ngIf="!tasksPage.empty; else emptyList" >
        <ul class="table-tasks__header-row">
          <li class="table-tasks__id-title fixed-left-column">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['publicId']">
              ID
              <button class="table-sort-icon-btn" (click)="onSortBy('publicId')" [style.opacity]="sorting['publicId'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['publicId'] === SortType.DESC" *ngIf="sorting['publicId']; else grayicon">
                 <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                     <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>
          <li class="table-tasks__name-title fixed-left-column-name">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['name']">
              Task Name
              <button class="table-sort-icon-btn" (click)="onSortBy('name')" [style.opacity]="sorting['name'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['name'] === SortType.DESC" *ngIf="sorting['name']; else grayicon">
                   <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-tasks__status">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['status']">
              Status
              <button class="table-sort-icon-btn" (click)="onSortBy('status')" [style.opacity]="sorting['status'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['status'] === SortType.DESC" *ngIf="sorting['status']; else grayicon">
                  <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-tasks__creator-name">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['creator.name']">
              Created By
              <button class="table-sort-icon-btn" (click)="onSortBy('creator.name')" [style.opacity]="sorting['creator.name'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['creator.name'] === SortType.DESC" *ngIf="sorting['creator.name']; else grayicon">
                  <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-tasks__due-date-title">
                <span class="table-title-wrapper" [attr.shouldBlack]="sorting['dueDate']">
                Due Date
                  <button class="table-sort-icon-btn" (click)="onSortBy('dueDate')" [style.opacity]="sorting['dueDate'] ? 1 : ''">
                    <svg class="table-sort" [attr.rotate]="sorting['dueDate'] === SortType.DESC" *ngIf="sorting['dueDate']; else grayicon">
                      <use spriteVersion iconName="sort-icon-up"></use>
                    </svg>

                    <ng-template #grayicon>
                      <svg class="table-sort">
                        <use spriteVersion iconName="sort-icon-default"></use>
                      </svg>
                    </ng-template>
                  </button>
                </span>
          </li>

          <li class="table-tasks__project-name">
                <span class="table-title-wrapper" [attr.shouldBlack]="sorting['project.name']">
                  Project Name
                  <button class="table-sort-icon-btn" (click)="onSortBy('project.name')" [style.opacity]="sorting['project.name'] ? 1 : ''">
                    <svg class="table-sort" [attr.rotate]="sorting['project.name'] === SortType.DESC" *ngIf="sorting['project.name']; else grayicon">
                      <use spriteVersion iconName="sort-icon-up"></use>
                    </svg>

                    <ng-template #grayicon>
                      <svg class="table-sort">
                        <use spriteVersion iconName="sort-icon-default"></use>
                      </svg>
                    </ng-template>
                  </button>
                </span>
          </li>

          <li class="table-tasks__priority-title">
                <span class="table-title-wrapper" [attr.shouldBlack]="sorting['priority']">
                  Priority
                  <button class="table-sort-icon-btn" (click)="onSortBy('priority')" [style.opacity]="sorting['priority'] ? 1 : ''">
                    <svg class="table-sort" [attr.rotate]="sorting['priority'] === SortType.DESC" *ngIf="sorting['priority']; else grayicon">
                     <use spriteVersion iconName="sort-icon-up"></use>
                    </svg>

                    <ng-template #grayicon>
                      <svg class="table-sort">
                        <use spriteVersion iconName="sort-icon-default"></use>
                      </svg>
                    </ng-template>
                  </button>
                </span>
          </li>
          <li class="table-tasks__actions no-right-border"></li>
        </ul>

        <ul class="table-body-list">
          <li *ngFor="let task of tasksPage.content; let i = index" [id]="task.id" class="table-tasks__row">
            <p *ngIf="i == tasksPage.content.length - 2" (visible)="getNextPage()"></p>
            <ul class="item-info-list">
              <li class="task-id fixed-left-column" [attr.done]="task.status.toLowerCase()" [routerLink]="['/system/company', task.company.id, 'projects', task.project.id, 'tasks']" [queryParams]="{ params: task.id}">
                  <span class="item-code-way">
                    <span class="list-name-wrapper">
                      {{task.publicId}}
                    </span>
                  </span>
              </li>
              <li class="task-name fixed-left-column-name" [attr.done]="task.status.toLowerCase()" [routerLink]="['/system/company', task.company.id, 'projects', task.project.id, 'tasks']" [queryParams]="{ params: task.id}">
                <div class="task-name__wrapper">
                  <div class="list-name-wrapper">
                    <ng-template [nestedEllipsis]>
                      <span>
                      {{task.name}}
                      </span>
                    </ng-template>

                    <div class="task-file-with-description-icon">
                      <svg class="attachment-icon" *ngIf="task?.hasAttachments">
                        <use spriteVersion iconName="attachment"></use>
                      </svg>
                      <svg class="description-icon" *ngIf="task?.description !== null && task?.description.length !== 0">
                        <use spriteVersion iconName="description"></use>
                      </svg>
                    </div>
                  </div>

                </div>
              </li>

              <li class="task-status-td" [attr.status]="taskStatusByEnum.get(task.status)?.toLowerCase()">
                <button class="task-status-btn">
                  {{taskStatusByEnum.get(task.status)}}
                </button>
              </li>

              <li class="task-project-name">
                <span class="list-name-wrapper">{{task.creator?.name || '-'}}</span>
              </li>

              <li class="task-dueDate">
                <div class="date-box" [attr.done]="task.status.toLowerCase()">
                  <ng-container *ngIf="task.dueDate">
                    <svg class="calendar-icon-table">
                      <use spriteVersion iconName="calendar"></use>
                    </svg>
                    {{task.dueDate | date: 'MM.dd.YY'}}
                  </ng-container>
                  <ng-container *ngIf="!task.dueDate">-</ng-container>
                </div>
              </li>
              <li class="task-project-name">
                <span class="list-name-wrapper">{{task.project.name}}</span>
              </li>

              <li class="task-priority">
                <svg class="priority-icon" *ngIf="task.priority && task.priority !== TaskPriority.NO_PRIORITY">
                  <use [attr.href]="'assets/sprite.svg#flag-priority-' + task.priority.toLowerCase()"></use>
                </svg>
                <span *ngIf="!task.priority || task.priority === TaskPriority.NO_PRIORITY">-</span>
              </li>
              <li class="dots-actions" id="dotsMenu"></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

  </div>
</ng-scrollbar>

<!---->

<app-loader [isDashboardListLoading]="true" *ngIf="isLoadingTasks"></app-loader>

<ng-template #emptyList>
  <div class="empty-list-wrapper">
    <p class="empty-list-text" *ngIf="tasksPage.empty; else notFound">The list of tasks is empty.</p>
    <ng-template #notFound>
      <p class="empty-list-text">No Tasks found</p>
    </ng-template>
    <img class="empty-list-icon" src="assets/images/empty-list.png" alt="empty-list">
  </div>
</ng-template>




