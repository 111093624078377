import {Unit} from "./unit";

export class Floor {
  id: number;
  name: string;
  orderNumber: number;
  units: Unit[] = [];
  version: number;
}


export class FloorDuplicateMenu {
  name: string;
  units: Unit[] = [];
}
