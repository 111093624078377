<app-sub-header [title]="['Dashboard']"></app-sub-header>
<div class="dashboard-wrapper">
  <div class="dashboard-head-wrapper">
    <p class="time-wrapper">{{ actualDate | date: 'EEEE, MMMM d, y' }}</p>
    <p class="greeting">{{getPartOfTheDay() + ", " + currentUser.name}}</p>
    <div class="general-statistic-wrapper">
      <p class="total-statistic" *ngIf="currentUser.role === UserRole.ROLE_SUPERADMIN">
        <span class="dashboard-icon-wrapper">
          <svg class="dashboard-icon">
            <use spriteVersion iconName="companies-dashboard"></use>
          </svg>
        </span>
        Total Companies
        <span class="total-statistic-amount">{{dashboardStore.companiesTotalAmount$ | async}}</span>
      </p>
      <p class="total-statistic">
        <span class="dashboard-icon-wrapper">
          <svg class="dashboard-icon">
            <use spriteVersion iconName="projects-dashboard"></use>
          </svg>
        </span>
        {{currentUser.role === UserRole.ROLE_SUPERADMIN ? 'Total ' : currentUser.role !== UserRole.ROLE_ADMIN ? 'My ' : ''}} Open Projects
        <span class="total-statistic-amount">{{dashboardStore.projectsTotalAmount$ | async}}</span>
      </p>

      <p class="total-statistic" *ngIf="currentUser.role !== UserRole.ROLE_SUPERADMIN">
        <span class="dashboard-icon-wrapper">
          <svg class="dashboard-icon">
            <use spriteVersion iconName="tasks-list"></use>
          </svg>
        </span>
        My Open Tasks
        <span class="total-statistic-amount">{{dashboardStore.tasksTotalAmount$ | async}}</span>
      </p>

    </div>
  </div>


  <div class="tables-dashboard-wrapper">
    <div class="settings-wrapper" *ngIf="currentUser.role === UserRole.ROLE_SUPERADMIN">
      <div class="settings-wrapper__header">
        <p class="table-title">Companies</p>
        <a class="table-link" [routerLink]="['/system/companies']">
          See All Companies

          <svg class="arrow-icon">
            <use spriteVersion iconName="arrow-right-dashboard"></use>
          </svg>
        </a>
      </div>

      <div class="list-wrapper">
        <app-dashboard-companies-table></app-dashboard-companies-table>
      </div>
    </div>

    <div class="settings-wrapper">
      <div class="settings-wrapper__header">
        <p class="table-title">{{currentUser.role === UserRole.ROLE_PROJECT_MANAGER || currentUser.role === UserRole.ROLE_EMPLOYEE ? 'My ' : ''}} Open Projects</p>
        <a class="table-link" [routerLink]="currentUser.role === UserRole.ROLE_SUPERADMIN ? ['/system/projects'] : ['/system/company', (globalStore.companyId$ | async)]">
          See All Projects

          <svg class="arrow-icon">
            <use spriteVersion iconName="arrow-right-dashboard"></use>
          </svg>
        </a>
      </div>

      <div class="list-wrapper">
        <app-dashboard-projects-table></app-dashboard-projects-table>
      </div>
    </div>

    <div class="settings-wrapper" *ngIf="currentUser.role !== UserRole.ROLE_SUPERADMIN">
      <div class="settings-wrapper__header">
        <p class="table-title">My Open Tasks</p>
      </div>

      <div class="list-wrapper">
        <app-dashboard-tasks-table></app-dashboard-tasks-table>
      </div>
    </div>

    <div class="settings-wrapper" *ngIf="currentUser.role !== UserRole.ROLE_SUPERADMIN">
      <div class="settings-wrapper__header">
        <p class="table-title">Activity</p>
      </div>

      <div class="list-wrapper">
        <app-dashboard-activity-table></app-dashboard-activity-table>
      </div>
    </div>
  </div>

</div>
