import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormFile} from "../../../../_models/material";

@Component({
  selector: 'app-add-files-to-form',
  templateUrl: './add-files-to-form.component.html',
  styleUrls: ['./add-files-to-form.component.scss']
})
export class AddFilesToFormComponent {
  @ViewChild('fileInput') fileInputRef: ElementRef;
  @Input() filesFromBack:  FormFile[] = [];
  @Input() maxFileSizeInMb: number = 25;
  @Output() updatedFilesListFromBack: EventEmitter<any> = new EventEmitter<any>()
  @Output() updatedNewFilesList: EventEmitter<any> = new EventEmitter<any>()
  @Output() fileToPreview: EventEmitter<any> = new EventEmitter<any>()

  filesList: FormFile[] = [];
  errorUploadFileSize = false;
  errorUploadFilesAmount = false;
  imageFormatsToRenderImage: string[] = ['jpeg', 'png', 'svg', 'webp', 'jpg'];
  bitePerMb = 1048576;

  toggleStatusLoadingFile(files: any) {
    for (let file of files) {
      if (file.size > this.maxFileSizeInMb * this.bitePerMb) {
        this.errorUploadFileSize = true;
        this.updatedFilesListFromBack.emit(this.filesFromBack)
        this.updatedNewFilesList.emit(this.filesList)
        return
      }

      this.errorUploadFileSize = false;

      if (this.filesList.length + this.filesFromBack.length === 4) {
        this.errorUploadFilesAmount = true;
        this.updatedFilesListFromBack.emit(this.filesFromBack)
        this.updatedNewFilesList.emit(this.filesList)
        return
      }
      this.errorUploadFilesAmount = false;
      const newFile = new FormFile()
      newFile.name = file.name;
      newFile.sizeBytes = file.size;
      newFile.fileToSave = file;
      this.filesList.push(newFile);

      if (files.length !== 0) {
        const reader = new FileReader();

        reader.onloadstart = (e) => newFile.timeLeft = Math.floor(e.timeStamp / 1000);
        reader.onprogress = (e) => {
          if (e.lengthComputable) newFile.progress = Math.floor((e.loaded / e.total) * 100);
        };

        reader.onload = (e) => {
          const binaryString = Array.from(new Uint8Array(e.target.result as ArrayBuffer)).map(byte => String.fromCharCode(byte)).join('');
          if(file.name.includes('png') || file.name.includes('jpg') || file.name.includes('jpeg')) newFile.imgSrc = 'data:image/png;base64,' + btoa(binaryString);
        }

        reader.readAsArrayBuffer(file);
      }
    }
    this.updatedFilesListFromBack.emit(this.filesFromBack)
    this.updatedNewFilesList.emit(this.filesList)
  }

  isFileCanBePreviewed(fileName: string) {
    let result = false;
    this.imageFormatsToRenderImage.forEach(e => {
      if (fileName.endsWith(e)) {
        result = true;
        return;
      }
    })
    return result;
  }

  removeFile(file: FormFile) {
    let objIdInFilesToSend = this.filesList.indexOf(file);

    if (objIdInFilesToSend !== -1) {
      this.filesList.splice(objIdInFilesToSend, 1);
    } else this.filesFromBack.splice(this.filesFromBack.indexOf(file), 1);
    this.fileInputRef.nativeElement.value = '';
    this.updatedFilesListFromBack.emit(this.filesFromBack)
    this.updatedNewFilesList.emit(this.filesList)
  }

  getSumMaterialFileList(): FormFile[] {
    return [...this.filesFromBack, ...this.filesList];
  }
}
