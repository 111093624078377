import {
  AfterViewInit,
  Component, ElementRef,
  OnDestroy, QueryList, ViewChild,
  ViewChildren
} from '@angular/core';
import {User, UserRole} from 'src/app/_models/user';
import {GlobalStore} from "../../../../global.store";
import {Company} from "../../../../_models/company";
import {Project} from "../../../../_models/project";
import {Subject, Subscription, take, takeUntil} from "rxjs";
import {NgScrollbar} from "ngx-scrollbar";


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnDestroy, AfterViewInit {
  @ViewChildren('projectEl', { read: ElementRef }) projectEls: QueryList<ElementRef>;
  @ViewChild('ngScrollbar') ngScrollbar: NgScrollbar;

  userRole: UserRole;
  showProjectsList = false;
  showSuperAdminMainMenu = false;

  company: Company;
  project: Project;

  private readonly destroy$ = new Subject<void>();

  constructor(readonly globalStore: GlobalStore) {
    this.globalStore.currentUser$.pipe(take(1)).subscribe((user: User) => this.userRole = user.role);
    this.globalStore.company$.pipe(takeUntil(this.destroy$)).subscribe((company) => {
      this.company = company;
      this.checkForShowSuperAdminMenu();
    })
    this.globalStore.project$.pipe(takeUntil(this.destroy$)).subscribe((project) => {
      this.project = project;
      this.checkForShowSuperAdminMenu();
    })
  }

  ngAfterViewInit() {
    this.projectEls.changes.pipe(takeUntil(this.destroy$)).subscribe((el: QueryList<ElementRef>) => {
      if (this.ngScrollbar) this.ngScrollbar.update();
      if (this.project) {
        const projectElement = el.find((el) => el.nativeElement.id === `sidebar-project-link-${this.project.id}`);
        if (projectElement) setTimeout(() => projectElement.nativeElement.scrollIntoView({ block: "nearest", inline: "nearest", behavior: "smooth" }), 100);
      }
    })
  }

  checkForShowSuperAdminMenu() {
    this.showSuperAdminMainMenu = !this.company && !this.project && this.userRole === UserRole.ROLE_SUPERADMIN;
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly UserRole = UserRole;
}
