import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {apiUrl} from "../../environments/environment";
import {map, Observable} from "rxjs";
import {Task, TaskStatus} from "../_models/task";
import {convertTaskPriorityToEnum, convertTaskStatusToEnum} from "../_util/utils";
import {Page} from "../_models/page";

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  constructor(private http: HttpClient) {
  }

  getTasksListByParams(companyId: number, projectId: number, hideCompleted: boolean, includeSelfCreatedTasks: boolean = true, memberId: number, status: TaskStatus, query: string, sortField: string, sortDirection: string, page: number, pageSize: number): Observable<Page<Task>> {
    let params = new HttpParams();
    if (companyId) params = params.set('companyId', companyId);
    if (projectId) params = params.set('projectId', projectId);
    if (hideCompleted) params = params.set('hideCompleted', hideCompleted);
    if (memberId) params = params.set('member', memberId);
    if (status) params = params.set('status', status);
    if (query && query.length > 0) params = params.set('query', query);
    if (sortField) params = params.set('sortField', sortField);
    if (sortDirection) params = params.set('sortDirection', sortDirection);
    if (page) params = params.set('page', page);
    if (pageSize) params = params.set('pageSize', pageSize);
    params = params.set('includeSelfCreatedTasks', includeSelfCreatedTasks)

    return this.http.get<Page<Task>>(`${apiUrl}/task/list`, {params: params, withCredentials: true}).pipe(map((tasksPage) => {
      tasksPage.content.forEach(e => {
        e.status = convertTaskStatusToEnum(e.status)
        e.priority = convertTaskPriorityToEnum(e.priority)
      })
      return tasksPage;
    }))
  }

  export(companyId: number, projectId: number, hideCompleted: boolean, includeSelfCreatedTasks: boolean = true, memberId: number, status: TaskStatus, query: string, sortField: string, sortDirection: string) {
    let params = new HttpParams();
    if (companyId) params = params.set('companyId', companyId);
    if (projectId) params = params.set('projectId', projectId);
    if (hideCompleted) params = params.set('hideCompleted', hideCompleted);
    if (memberId) params = params.set('member', memberId);
    if (status) params = params.set('status', status);
    if (query && query.length > 0) params = params.set('query', query);
    if (sortField) params = params.set('sortField', sortField);
    if (sortDirection) params = params.set('sortDirection', sortDirection);
    params = params.set('includeSelfCreatedTasks', includeSelfCreatedTasks)

    return this.http.get(`${apiUrl}/task/export`, {
      params: params,
      withCredentials: true,
      responseType: 'blob',
      observe: 'response'});
  }

  getTaskById(taskId: number) {
    return this.http.get<Task>(`${apiUrl}/task/${taskId}`, {withCredentials: true}).pipe(map(result => {
      result.status = convertTaskStatusToEnum(result.status)
      result.priority = convertTaskPriorityToEnum(result.priority)
      return result;
    }))
  }

  createTask(task: FormData, projectId: number) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(`${apiUrl}/task`, task, {params: {projectId: projectId}, headers: headers, observe: "response", withCredentials: true})
  }

  updateStatusTask(taskId: number, status: TaskStatus) {
    return this.http.put(`${apiUrl}/task/${taskId}/status?status=${status}`, null, {observe: "response", withCredentials: true})
  }

  updateTask(task: FormData) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.put(`${apiUrl}/task`, task, {headers: headers, observe: "response", withCredentials: true})
  }

  deleteTasks(taskIds: number[]) {
    let params = new HttpParams();
    taskIds.forEach(e => params = params.append('taskIds', e));
    return this.http.delete(`${apiUrl}/task`, {params: params, observe: "response", withCredentials: true})
  }
}
