import {Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {MaterialPaymentStatus, MaterialStoredLocation, MaterialUnit} from "../../../../../../../_models/material";
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {minLengthValidator, requiredValidator} from "../../../../../../../_util/validators";

@Component({
  selector: 'app-material-custom-selector',
  templateUrl: './material-custom-selector.component.html',
  styleUrls: ['./material-custom-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialCustomSelectorComponent),
      multi: true
    }
  ]
})
export class MaterialCustomSelectorComponent implements ControlValueAccessor{
  @ViewChild('input') inputRef: ElementRef;
  @Input()
  set elementList(value: any[]) {
    if(value) {
      this._elementList = [...value];
      this.filteredElementList = [...this._elementList]
    }
  }

  get elementList() {
    return this._elementList
  }
  @Input()
  set selectedOption(value: MaterialUnit | MaterialPaymentStatus | MaterialStoredLocation) {
    if(value) {
      this._selectedOption = value;
      this.inputValue = value.name
    } else if(!value && !this.isDeletable) {
      this._selectedOption = null;
      this.inputValue = null;
    }
  }

  get selectedOption() {
    return this._selectedOption;
  }
  @Input() isOptional = false;
  @Input() isDeletable = true;
  @Input() isClearFieldBtn = false;
  @Input() isLongerName = false;
  @Input() placeholder: string;
  @Input() noOptionFind: string;
  @Input() id: string;
  @Output() changeEl: EventEmitter<any> = new EventEmitter<any>()
  @Output() createNew: EventEmitter<any> = new EventEmitter<any>()
  @Output() deleteOption: EventEmitter<any> = new EventEmitter<any>()
  @Output() close: EventEmitter<any> = new EventEmitter<any>()

  constructor(private fb: FormBuilder) {
  }

  createForm: FormGroup = this.fb.group({
    name: [null, [minLengthValidator(this.isLongerName ? 'NAME': 'FLOOR_UNIT_NAME'), requiredValidator()]]
  })

  private _elementList = new Array<any>()
  private _selectedOption: MaterialUnit | MaterialPaymentStatus | MaterialStoredLocation;
  filteredElementList: any[] = [];
  isAddFormOpen = false;
  inputValue: string = null;
  filterListTimeout: any;

  writeValue(value: string): void {
    if (!value) return;
    this.inputValue = value;

    setTimeout(() => {
      this.onChange(this.inputValue);
    })
  }

  onChange: any = () => {
  }
  onTouch: any = () => {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  searchEl(event: any) {
    this.filteredElementList = this.elementList.filter(el => el.name.toLowerCase().includes(event.target.value.toLowerCase()))
  }

  toggleDropDownMenu(materialSelect: HTMLElement) {
    if(materialSelect.hasAttribute('openMenu')) {
      materialSelect.removeAttribute('openMenu')
      this.closeSelect()
    } else materialSelect.setAttribute('openMenu', 'true')
  }

  updateSelectedOption() {
    this.inputValue = this.createForm.controls['name'].value;
    this.onChange(this.inputValue)
  }

  clearField() {
    this.inputValue = null;
    this.onChange(null);
    this.changeEl.emit(null)
  }

  onDeleteOption(optionId: number) {
    if(optionId === this.selectedOption?.id) this.inputValue = null
    this.deleteOption.emit(optionId);
  }

  closeSelect() {
    this.inputRef.nativeElement.value = this.inputValue ? this.inputValue : null
    if(this.filterListTimeout) clearTimeout(this.filterListTimeout)
    this.filterListTimeout = setTimeout(() => this.filteredElementList = [...this.elementList], 250)
    this.close.emit()
  }
}
