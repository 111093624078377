import {Component} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {UsersService} from "../../../_services/users.service";
import {User} from "../../../_models/user";
import {NotificationService} from "../../../_services/notification.service";
import {emailValidatorByPattern, requiredValidator} from "../../../_util/validators";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../auth.scss', './forgot-password.component.scss']
})
export class ForgotPasswordComponent{
  user: User = new User();
  error: boolean = false;
  showLoader = false;

  forgotPasswordForm: FormGroup = new FormGroup({
    email: new FormControl('', [emailValidatorByPattern(), requiredValidator()]),
  })
  constructor(private userService: UsersService,
              private notificationService: NotificationService) {
    this.forgotPasswordForm.valueChanges.subscribe(() => {
      this.user.email = this.forgotPasswordForm.get('email').value;
    })
  }

  onSubmit(resetBtn: HTMLElement) {
    if (this.forgotPasswordForm.invalid) return;

    this.error = false;
    this.showLoader = true;

    this.userService.resetPasswordSendEmail(this.user.email).subscribe({
      next: () => {
        this.notificationService.successNotification("Email has been sent. Please check your email box");
        this.forgotPasswordForm.reset();
        resetBtn.click();
        this.showLoader = false;
      },
      error: () => {
        this.error = true;
        this.showLoader = false;
      }
    })
  }
}
