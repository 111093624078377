import {Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {MaterialPaymentStatus, MaterialStoredLocation, MaterialUnit} from "../../../../../../_models/material";
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {minLengthValidator, requiredValidator} from "../../../../../../_util/validators";
import {DocumentType} from "../../../../../../_models/document";
import {originalOrder} from "../../../../../../_util/utils";

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent{
  @Input()
  set elementMap(value: Map<DocumentType, string>) {
    if(value) {
      this._elementMap = new Map(value);
      this._elementMap.forEach((value, key) => this.documentTypeFromKeysList.push(key.toString()))
    }
  }

  get elementMap() {
    return this._elementMap
  }
  @Input()
  set selectedOption(value: DocumentType) {
    if(value) {
      this._selectedOption = value;
    } else {
      this._selectedOption = null
    }
  }

  get selectedOption() {
    return this._selectedOption;
  }
  @Input() isClearFieldBtn = false;
  @Input() placeholder: string;
  @Input() id: string;
  @Output() changeEl: EventEmitter<any> = new EventEmitter<any>()
  @Output() close: EventEmitter<any> = new EventEmitter<any>()

  private _elementMap = new Map<DocumentType, string>()
  private _selectedOption: DocumentType | null;
  documentTypeFromKeysList: string[] = [];


  toggleDropDownMenu(materialSelect: HTMLElement) {
    if(materialSelect.hasAttribute('openMenu')) {
      materialSelect.removeAttribute('openMenu')
      this.closeSelect()
    } else materialSelect.setAttribute('openMenu', 'true')
  }

  clearField() {
    this.selectedOption = null;
    this.changeEl.emit(null)
  }

  closeSelect(){
    this.selectedOption = this.selectedOption ? this.selectedOption : null
    this.close.emit()
  }

  protected readonly originalOrder = originalOrder;
}
