import {Component, EventEmitter, Input, Output} from '@angular/core';
import {User, UserRole} from "../../../../../_models/user";
import {NotificationSettingsService} from "../../../../../_services/notification-settings.service";
import {NotificationSettings} from "../../../../../_models/notificationSettings";
import {error} from "@angular/compiler-cli/src/transformers/util";
import {modalAnimation} from "../../../../../config/constants";
import {NotificationService} from "../../../../../_services/notification.service";

@Component({
  selector: 'app-email-notification-settings',
  templateUrl: './email-notification-settings.component.html',
  styleUrls: ['./email-notification-settings.component.scss'],
  animations: [modalAnimation]
})
export class EmailNotificationSettingsComponent {
  @Input() currentUser: User
  @Output() close: EventEmitter<any> = new EventEmitter<any>()

  submitBtnDisabled = false;
  isSelectAllNotifications = false;
  notificationSettings = new NotificationSettings()

  constructor(private notificationSettingsService: NotificationSettingsService, private notifService: NotificationService) {
    this.notificationSettingsService.getNotificationSettings().subscribe(data => {
      this.notificationSettings = data
      this.switchAllNotification()
    })
  }

  switchNotifications(value: string) {
    switch (value) {
      case 'all': {
        this.isSelectAllNotifications = !this.isSelectAllNotifications;
        this.notificationSettings.tasks = this.isSelectAllNotifications;
        this.notificationSettings.areaComments = this.isSelectAllNotifications;
        if (this.currentUser.role !== UserRole.ROLE_ADMIN) this.notificationSettings.projects = this.isSelectAllNotifications;
        break
      }
      case 'projects': {
        this.notificationSettings.projects = !this.notificationSettings.projects;
        this.switchAllNotification()
        break
      }
      case 'tasks': {
        this.notificationSettings.tasks = !this.notificationSettings.tasks
        this.switchAllNotification()
        break
      }
      case 'areaComments': {
        this.notificationSettings.areaComments = !this.notificationSettings.areaComments;
        this.switchAllNotification()
        break
      }
    }
  }

  switchAllNotification() {
    if (this.currentUser && this.currentUser.role === UserRole.ROLE_ADMIN) this.isSelectAllNotifications = this.notificationSettings.tasks && this.notificationSettings.areaComments;
    else this.isSelectAllNotifications = this.notificationSettings.tasks && this.notificationSettings.projects && this.notificationSettings.areaComments;
  }

  submit() {
    this.notificationSettingsService.updateNotificationSettings(this.notificationSettings).subscribe(data => {
      this.notifService.successNotification('Changes have been saved')
      this.close.emit()
    })
  }

  protected readonly UserRole = UserRole;
}
