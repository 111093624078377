import {Directive, ElementRef, HostListener} from "@angular/core";
import {FormGroupDirective} from "@angular/forms";

@Directive({
  selector: '[appAutoTrim]'
})
export class AutoTrimDirective {

  constructor(private el: ElementRef, private formGroupDirective: FormGroupDirective) {
    let formEl = this.el.nativeElement as  HTMLElement;

    formEl.addEventListener("submit", ev => {
      const form = this.formGroupDirective.form;

      Object.keys(form.controls).forEach(key => {
        const control = form.get(key);
        let controlEl = formEl.querySelector(`input[formcontrolname=${key}`);

        if (controlEl && controlEl.parentElement.getAttribute("role") !== 'combobox' && control && control.value && typeof control.value === 'string') {
          if (control.value !== control.value.trim()) {
            control.setValue(control.value.trim());
            control.updateValueAndValidity({onlySelf: true, emitEvent: true});
          }
        }
      });
    })
  }
}
