<div class="backdrop" *ngIf="!selectedFileForPreview" [@modalAnimation]>
<ng-scrollbar class="remove-overflow-on-tablet modal material-scrollbar-modal">
  <form class="form-invite form-create" firstInputAutofocus scrollToFirstInvalidInput appAutoTrim [formGroup]="materialForm" (ngSubmit)="submitForm()">
    <button type="button" id="btnCloseMobile" class="close-modal-btn" (click)="close.emit()">
      <svg class="close-modal-icon">
        <use spriteVersion iconName="modal-close"></use>
      </svg>
    </button>
    <div class="form-modal-way-wrapper" *ngIf="material.id">
      <ng-template [ngIf]="(globalStore.currentUser$ | async).role === UserRole.ROLE_SUPERADMIN">
        <span class="form-modal-way">{{globalStore.companyName$ | async}}</span>
        /
      </ng-template>
      <span class="form-modal-way">{{globalStore.projectName$ | async}}</span>
      /
      <span class="form-modal-way">Material</span>
      /
      <span class="form-modal-way item-code-way">{{material.id}}</span>
    </div>
    <div class="form-inner-wrapper" [attr.paddingTop]="!!material.id">
      <p class="form-modal-title" [attr.isLowerStripe]="!!material.id">{{material.id ? 'Edit Material' : 'Add Material'}}</p>
      <p class="main-error" *ngIf="materialForm.invalid && isSubmittedForm" id="mainErrorMobile">
        <svg class="error-icon">
          <use spriteVersion iconName="error-icon"></use>
        </svg>
        All fields are required
      </p>

      <div class="main-content-form-wrapper">

        <div class="main-filed-wrapper">
          <div class="field-wrapper">
            <label class="form-label">Tag</label>
            <span class="input-with-error-wrapper">
              <input class="form-input-modal" id="tagNameMobile" formInput="NAME" formControlName="tag" placeholder="Tag Name">
              <p class="small-error-text-field" id="tagNameErrorMobile" *ngIf="materialForm.controls['tag'].invalid && isSubmittedForm">{{materialForm.controls['tag'].getError('error')}}</p>
            </span>
          </div>

          <div class="field-wrapper">
            <label class="form-label project-optional-label">Description <span class="project-optional-text">(Optional)</span></label>
            <textarea class="form-input-modal form-textarea" rows="3" formInput="TASK_DESCRIPTION" id="materialDescription" formControlName="description" placeholder="Description"></textarea>
          </div>
        </div>

        <div class="gray-wrapper-fields" #graySectionWrapper>
          <p class="material-form-subtitle">
            Submittal

            <svg class="gray-section-icon-rollUp" (click)="onRollUpSection(1)">
              <use spriteVersion iconName="arrow-select-area-status"></use>
            </svg>
          </p>

          <div class="gray-inner-wrapper-fields" #graySectionInnerWrapper>
            <div class="field-wrapper">
              <label class="form-label">Unit</label>
              <span class="input-with-error-wrapper">
                <app-material-custom-selector [elementList]="materialUnits"
                                              [selectedOption]="material.unit"
                                              [id]="'materialUnit'"
                                              [placeholder]="'Select Unit'"
                                              [noOptionFind]="'No Unit'"
                                              formControlName="unitName"
                                              (changeEl)="changeOption($event, 'unit')"
                                              (createNew)="createNewOption($event, 'unit')"
                                              (deleteOption)="deleteOption($event, 'unit')"
                                              (close)="materialForm.controls['unitName'].setValue(this.material.unit?.name || null)">

                </app-material-custom-selector>
                <p class="small-error-text-field" id="unitNameError" *ngIf="materialForm.controls['unitName'].invalid && isSubmittedForm">{{materialForm.controls['unitName'].getError('error')}}</p>
              </span>
            </div>

            <div class="field-wrapper">
              <label class="form-label">Supplier</label>
              <span class="input-with-error-wrapper">
                <input class="form-input-modal" id="supplierName" formInput="NAME" formControlName="supplier" placeholder="Supplier Name">
                <p class="small-error-text-field" id="supplierError" *ngIf="materialForm.controls['supplier'].invalid && isSubmittedForm">{{materialForm.controls['supplier'].getError('error')}}</p>
              </span>
            </div>

            <div class="field-wrapper">
              <label class="form-label">Area</label>
              <span class="input-with-error-wrapper">
                <input class="form-input-modal" id="areaName" formInput="NAME" formControlName="area" placeholder="Area Name">
                <p class="small-error-text-field" id="areaNameError" *ngIf="materialForm.controls['area'].invalid && isSubmittedForm">{{materialForm.controls['area'].getError('error')}}</p>
              </span>
            </div>

            <div class="field-wrapper">
              <label class="form-label">Submittal Status</label>
              <span class="input-with-error-wrapper">
                <app-material-custom-selector-with-colored-options
                  [statusesMap]="submittalStatusByEnum"
                  [formControl]="$any(materialForm).controls['submittalStatus']"
                  [selectedOption]="{value: material.submittalStatus, isPlaceholder: false}"
                  (changeEl)="changeSubmittalStatus($event)">

                </app-material-custom-selector-with-colored-options>

                <p class="small-error-text-field" id="submittalStatusNameError" *ngIf="materialForm.controls['submittalStatus'].invalid && isSubmittedForm">{{materialForm.controls['submittalStatus'].getError('error')}}</p>
              </span>
            </div>

            <div class="field-wrapper field-wrapper-file-relative">
              <label class="form-label  project-optional-label">Add Files <span class="project-optional-text">(Optional)</span> </label>
              <app-add-files-to-form [filesFromBack]="material.submittalFiles || []"
                                     [maxFileSizeInMb]="25"
                                     (updatedFilesListFromBack)="updateFiles($event, 'oldSubmittal')"
                                     (updatedNewFilesList)="updateFiles($event, 'newSubmittal')"
                                     (fileToPreview)="selectedFileForPreview = $event">
              </app-add-files-to-form>
            </div>

          </div>
        </div>

  <!--      Purchasing-->

        <div class="gray-wrapper-fields" #graySectionWrapper>
          <p class="material-form-subtitle">
            Purchasing

            <svg class="gray-section-icon-rollUp" (click)="onRollUpSection(2)">
              <use spriteVersion iconName="arrow-select-area-status"></use>
            </svg>
          </p>

          <div class="gray-inner-wrapper-fields" #graySectionInnerWrapper>
            <div class="field-wrapper">
              <label class="form-label">Order Status</label>
              <span class="input-with-error-wrapper">
                <app-material-custom-selector-with-colored-options
                  [statusesMap]="orderStatusByEnum"
                  [formControl]="$any(materialForm).controls['orderStatus']"
                  [selectedOption]="{value: material.orderStatus, isPlaceholder: false}"
                  (changeEl)="changeOrderStatus($event)">
                </app-material-custom-selector-with-colored-options>

                <p class="small-error-text-field" id="orderStatusNameError" *ngIf="materialForm.controls['orderStatus'].invalid && isSubmittedForm">{{materialForm.controls['orderStatus'].getError('error')}}</p>
              </span>
            </div>

            <div class="field-wrapper start-date-wrapper">
              <label class="form-label">ETA<span class="project-optional-text">(Optional)</span></label>
               <app-custom-datepicker [formControl]="$any(materialForm).controls['etaDate']" [id]="'materialEtaDate'"></app-custom-datepicker>
            </div>

            <div class="field-wrapper field-wrapper--qta">
              <label class="form-label">Gross Qty<span class="project-optional-text">(Optional)</span></label>
                <input class="form-input-modal"
                       id="grossQtyName"
                       mask="0||00||000||0000||00000||000000"
                       formControlName="grossQty"
                       placeholder="Gross Qty"
                >
            </div>

            <div class="field-wrapper field-wrapper--qta">
              <label class="form-label">Order Qty<span class="project-optional-text">(Optional)</span></label>
                <input class="form-input-modal"
                       id="orderQtyName"
                       mask="0||00||000||0000||00000||000000"
                       formControlName="orderQty"
                       placeholder="Order Qty"
                >
            </div>

            <div class="field-wrapper start-date-wrapper">
              <label class="form-label">Order Date<span class="project-optional-text">(Optional)</span></label>
              <app-custom-datepicker [formControl]="$any(materialForm).controls['orderDate']" [id]="'materialOrderDate'"></app-custom-datepicker>
            </div>

            <div class="field-wrapper">
              <label class="form-label">Payment Status <span class="project-optional-text">(Optional)</span> </label>
              <span class="input-with-error-wrapper">
                <app-material-custom-selector [elementList]="paymentStatusList"
                                              [selectedOption]="material.paymentStatus"
                                              [isOptional]="true"
                                              [id]="'paymentStatus'"
                                              [placeholder]="'Select Payment status'"
                                              [noOptionFind]="'No Payment status find'"
                                              formControlName="paymentStatus"
                                              (changeEl)="changeOption($event, 'paymentStatus')"
                                              (createNew)="createNewOption($event, 'paymentStatus')"
                                              (deleteOption)="deleteOption($event, 'paymentStatus')"
                                              (close)="materialForm.controls['paymentStatus'].setValue(this.material.paymentStatus?.name || null)">
                </app-material-custom-selector>

                <p class="small-error-text-field" id="paymentStatusNameError" *ngIf="materialForm.controls['paymentStatus'].invalid && isSubmittedForm">{{materialForm.controls['paymentStatus'].getError('error')}}</p>
              </span>
            </div>

            <div class="field-wrapper field-wrapper--qta">
              <label class="form-label">Received Qty<span class="project-optional-text">(Optional)</span></label>
                <input class="form-input-modal"
                       id="receivedQtyName"
                       mask="0||00||000||0000||00000||000000"
                       formControlName="receivedQty"
                       placeholder="Received Qty"
                >
            </div>

            <div class="field-wrapper">
              <label class="form-label">Stored Location <span class="project-optional-text">(Optional)</span> </label>
              <span class="input-with-error-wrapper">
                <app-material-custom-selector [elementList]="storedLocationList"
                                              [selectedOption]="material.storedLocation"
                                              [isOptional]="true"
                                              [id]="'storedLocation'"
                                              [isLongerName]="true"
                                              [placeholder]="'Select Location'"
                                              [noOptionFind]="'No Location'"
                                              formControlName="storedLocation"
                                              (changeEl)="changeOption($event, 'storedLocation')"
                                              (createNew)="createNewOption($event, 'storedLocation')"
                                              (deleteOption)="deleteOption($event, 'storedLocation')"
                                              (close)="materialForm.controls['storedLocation'].setValue(this.material.paymentStatus?.name || null)">
                </app-material-custom-selector>
              </span>
            </div>

            <div class="field-wrapper">
              <label class="form-label project-optional-label">Notes <span class="project-optional-text">(Optional)</span></label>
              <textarea class="form-input-modal form-textarea" rows="3" formInput="TASK_DESCRIPTION" id="materialNotes" formControlName="notes" placeholder="Notes"></textarea>
            </div>


            <div class="field-wrapper field-wrapper-file-relative">
              <label class="form-label  project-optional-label">Add Files <span class="project-optional-text">(Optional)</span> </label>
              <app-add-files-to-form [filesFromBack]="material.purchasingFiles || []"
                                     [maxFileSizeInMb]="25"
                                     (updatedFilesListFromBack)="updateFiles($event, 'oldPurchasing')"
                                     (updatedNewFilesList)="updateFiles($event, 'newPurchasing')"
                                     (fileToPreview)="selectedFileForPreview = $event">
              </app-add-files-to-form>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="form-btn-wrapper">
      <button type="button" class="btn-cancel" id="btnCancelMobile" (click)="close.emit()">Cancel</button>
      <button type="submit" class="btn-invite" id="btnSubmitMobile" [disabled]="submitBtnDisabled" loader="white" [showLoader]="submitBtnDisabled">{{material.id ? 'Update Material' : 'Save'}}</button>
    </div>
  </form>
</ng-scrollbar>
</div>

<app-file-preview *ngIf="selectedFileForPreview"
                  [file]="selectedFileForPreview"
                  (close)="selectedFileForPreview = null"
                  [@modalAnimation]>
</app-file-preview>

