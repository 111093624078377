<div class="container">
  <div class="login-wrapper">
    <svg class="logo">
      <use spriteVersion iconName="colorjob-logo"></use>
    </svg>
    <h1 class="auth-title">Forgot Password</h1>

    <form [formGroup]="forgotPasswordForm" #form="ngForm" class="form-login" (ngSubmit)="onSubmit(resetBtn)">
      <p class="main-error" *ngIf="error && form.submitted" id="mainError">
        <svg class="error-icon">
          <use spriteVersion iconName="error-icon"></use>
        </svg>
        Email address not found
      </p>
      <div class="form__field-wrapper">
        <label class="form-label" for="email">Email
          <input formInput="EMAIL" formControlName="email" placeholder="Email" type="email" id="email" class="form-input"/>
        </label>
        <span *ngIf="form.submitted && forgotPasswordForm.controls['email'].invalid" class="error-message" id="emailError">{{forgotPasswordForm.controls['email'].getError('error')}}</span>
      </div>
      <button #resetBtn type="reset" hidden></button>
      <button loader="white" [showLoader]="showLoader" class="btn-login" type="submit" id="btn-submit">Submit</button>

      <a class="link" routerLink="/login" id="linkCancel">Cancel</a>
    </form>
  </div>
</div>
