<div class="backdrop">
  <div class="modal-narrow">
    <button type="button" id="btnCloseModal" class="close-modal-btn close-modal-deleting" (click)="close.emit()">
      <svg class="close-modal-icon">
        <use spriteVersion iconName="modal-close"></use>
      </svg>
    </button>

    <div class="confirmDeleting-wrapper">
      <p class="confirmDeleting-title">Delete Custom Status “{{deletingStatus?.name}}”</p>
      <p class="confirmDeleting-text">
        You may have an area with the status you want to delete. Please select a new status to assign an area to
      </p>

      <div class="ng-select-wrapper areastatusSelect">
        <p class="status-progress-label status-progress-label--margin-bottom">New assigned status:</p>
        <div class="area-status-btn-wrapper" #areaStatusSelect [enabled]="areaStatusSelect.hasAttribute('openMenu')" (appOutsideClick)="areaStatusSelect.removeAttribute('openMenu')">
          <button class="area__status-btn" [attr.isError]="isError" [attr.istodo]="!selectedStatus || selectedStatus.orderNumber === -3"  [style.background-color]="selectedStatus ? addBackgroundToStatusItem(selectedStatus) : 'transparent' " [style.color]="selectedStatus ? addColorToSelectValue(selectedStatus) : '#9FA1A4'" (click)="isLoadingStatus ? null : areaStatusSelect.toggleAttribute('openMenu')" >
            {{selectedStatus && selectedStatus.name ? selectedStatus.name : 'Select'}}
            <svg *ngIf="!isLoadingStatus" class="area__status-icon-dropdown" [style.stroke]="selectedStatus ? addColorToSelectValue(selectedStatus) : 'black'">
              <use spriteVersion iconName="arrow-select-area-status"></use>
            </svg>

            <div *ngIf="isLoadingStatus" class="loader-spin">
              <svg [style.stroke]="selectedStatus ? addColorToSelectValue(selectedStatus) : 'black'">
                <use spriteVersion iconName="loader-icon"></use>
              </svg>
            </div>
          </button>
          <div class="area__status-menu-wrapper">
            <ul class="area__status-menu">
              <ng-container *ngFor="let areaStatus of projectStatuses">
                <li class="area__status-menu__item" *ngIf="areaStatus.orderNumber < 0 || (checkStatusPresent(areaStatus.id) && areaStatus.id !== deletingStatus.id)" (click)="changeAreaStatus(areaStatus.id); areaStatusSelect.removeAttribute('openMenu')">
                  <p class="area__status-menu__text" [attr.istodo]="areaStatus.orderNumber === -3" [style.color]="addColorToSelectValue(areaStatus)" [style.background-color]="addBackgroundToStatusItem(areaStatus)" [attr.selectedStatus]="selectedStatus ? areaStatus.name === selectedStatus.name : false">{{areaStatus.name}}</p>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>

    </div>

    <div class="form-btn-wrapper">
      <button type="button" id="btnCancel" class="btn-cancel" (click)="close.emit()">Cancel</button>
      <button type="submit" id="btnDeleteProject" class="btn-delete" (click)="deleteCustomStatus()">
        <svg class="btn-delete__icon">
          <use spriteVersion iconName="trash-delete-icon"></use>
        </svg>
        Delete
      </button>
    </div>
  </div>
</div>
