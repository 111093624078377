<app-filter-fields btnText="Add Drawing" searchPlaceholder="Search by ID, Name" [disabled]="drawingsPage?.empty && isNoFilteringEnabled()" (openFormBtnClick)="drawingFormOpened = true"
                   (inputChanged)="query = $event; getDrawingsList()" [filtersAmount]="getFiltersActiveAmount()"
                   (deleteSelectedItem)="deletingPopupOpened = true" (cancelSelectedItem)="multiSelectedDocuments = []; isMultiSelectAll = false" [selectedItemsAmount]="multiSelectedDocuments.length" (clearAllFilters)="clearFilters()">

  <div class="filter-field-wrapper">
    <label class="filter-label">
      Type:
    </label>
    <div class="ng-select-wrapper">
      <app-custom-select
        [elementMap]="documentTypeByEnum"
        [selectedOption]="selectedDocumentType"
        [isClearFieldBtn]="!!selectedDocumentType"
        [id]="'documentType'"
        [placeholder]="'Select Document Type'"
        (changeEl)="changeStatusInFilters($event); getDrawingsList()">
      </app-custom-select>
    </div>
  </div>
  <button class="clear-all-filters-btn" (click)="clearFilters()">Clear all filters</button>
</app-filter-fields>

<ng-scrollbar *ngIf="drawingsPage && !isLoading" externalViewport [attr.isIOS]="(isIOS | async)" [attr.pagination-showed]="this.drawingsPage.totalElements > 20 && !(isMobile | async)" [autoHeightDisabled]="true" [track]="'all'" class="table-wrapper-with-dots">
  <div class="innerScrollBarWrapper">
    <div [attr.pagination-showed]="this.drawingsPage.totalElements > 20" class="table-wrapper-with-scroll">
      <div class="table-document" *ngIf="!drawingsPage.empty; else emptyList" >
        <ul class="table-document__header-row">
          <li class="table-document__multiselected" *ngIf="multiSelectEnabled && multiSelectedDocuments.length > 0">
            <div class="checkbox-container">
              <label class="checkbox-label">
                <input type="checkbox" [checked]="isMultiSelectAll" (change)="toggleCheckAll()" [id]="'selectItemCheckbox'" class="visually-hidden"/>
                <span class="checkbox"></span>
              </label>
            </div>
          </li>
          <li class="table-document__id-title fixed-left-column" [attr.isMultiselect]="multiSelectEnabled && multiSelectedDocuments.length > 0">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['id']">
              ID
              <button class="table-sort-icon-btn" (click)="onSortBy('id')" [style.opacity]="sorting['id'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['id'] === SortType.DESC" *ngIf="sorting['id']; else grayicon">
                 <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                     <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>
          <li class="table-document__name-title fixed-left-column-name" [attr.isMultiselect]="multiSelectEnabled && multiSelectedDocuments.length > 0">
            <span class="table-title-wrapper">
              Name
            </span>
          </li>

          <li class="table-document__due-date-title">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['createdAt']">
            Date Added
              <button class="table-sort-icon-btn" (click)="onSortBy('createdAt')" [style.opacity]="sorting['createdAt'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['createdAt'] === SortType.DESC" *ngIf="sorting['createdAt']; else grayicon">
                  <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-document__actions no-right-border fixed-column"></li>
        </ul>

        <ul class="table-body-list">
          <li *ngFor="let drawing of drawingsPage.content" [id]="drawing.id" class="table-document__row" [attr.multiselected]="multiSelectedDocuments.includes(drawing.id)" [ngStyle]="multiSelectedDocuments.includes(drawing.id) ? {'background-color': '#F7F7F8'} : {}">
            <ul class="item-info-list">
              <li class="document-id multiselect-cell" *ngIf="multiSelectEnabled && multiSelectedDocuments.length > 0">
                <div class="checkbox-container">
                  <label class="checkbox-label">
                    <input type="checkbox" [checked]="multiSelectedDocuments.includes(drawing.id)" (change)="toggleCheck(drawing.id)" id="selectItemCheckbox" class="visually-hidden"/>
                    <span class="checkbox"></span>
                  </label>
                </div>
              </li>
              <li class="document-id fixed-left-column" [attr.isLink]="!!drawing.link" [attr.isMultiselect]="multiSelectEnabled && multiSelectedDocuments.length > 0" (click)="drawing.link ? '' : openPreviewDrawing(drawing); $event.stopPropagation()"
                  [ngStyle]="multiSelectedDocuments.includes(drawing.id) ? {'background-color': '#F7F7F8'} : {}">
                  <span class="item-code-way" *ngIf="!drawing.link">
                    <span class="list-name-wrapper">
                      {{drawing.id}}
                    </span>
                  </span>
              </li>
              <li class="document-name fixed-left-column-name" [attr.isMultiselect]="multiSelectEnabled && multiSelectedDocuments.length > 0" (click)="drawing.link ? openLink(drawing.link, drawing.name) : openPreviewDrawing(drawing); $event.stopPropagation()"
                  [ngStyle]="multiSelectedDocuments.includes(drawing.id) ? {'background-color': '#F7F7F8'} : {}">
                <div class="inner-document-name-with-image-wrapper">
                  <img class="document-image-preview" *ngIf="!drawing.name && isImageFormat(drawing.file)" [src]="drawing.file.fileUrl" alt="file-img">
                  <span *ngIf="!drawing.name && !isImageFormat(drawing.file)" class="document-icon-wrapper">
                    <svg width="24" height="24" class="document-icon">
                      <use appDocumentsTypeIcon [documentFile]="drawing.file"></use>
                    </svg>
                  </span>
                  <span *ngIf="drawing.name" class="document-icon-wrapper">
                    <svg class="document-icon">
                      <use spriteVersion iconName="new-link-icon"></use>
                    </svg>
                  </span>
                  <div class="document-name__wrapper">
                    <div class="list-name-wrapper">
                      <ng-template [nestedEllipsis]>
                        <span class="ellipsis-text">{{drawing.name || drawing.file?.name}}</span>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </li>

              <li class="document-dueDate">
                <div class="date-box">
                  {{getTimeCreatedAtDrawing(drawing.createdAt)}}
                </div>
              </li>
              <li tabindex="1" class="dots-actions fixed-column" id="dotsMenu" #taskDots [attr.isDotsMenuAvailable]="accessToDotsMenu(drawing)" (click)="taskDots.toggleAttribute('editMenuOpen'); $event.stopPropagation()" [enabled]="taskDots.hasAttribute('editMenuOpen')" (appOutsideClick)="taskDots.removeAttribute('editMenuOpen')">
                <ul class="editMenuList" #editMenuList [attr.editMenuListOffsetHeight]="editMenuList.offsetHeight" (isLowerThenMiddleOfScreen)="editMenuList.style.top='-' + editMenuList.offsetHeight + 'px'" [@modalAnimation] *ngIf="taskDots.hasAttribute('editMenuOpen')">
                  <li *ngIf="currentUser.role !== UserRole.ROLE_EMPLOYEE" class="editMenuList__item" id="selectItem" (click)="toggleCheck(drawing.id)">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="select-item-icon"></use>
                    </svg>
                    Select
                  </li>
                  <li *ngIf="drawing.name" class="editMenuList__item" id="editItem" (click)="drawingFormOpened = true; selectedDrawing = drawing">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="edit-icon"></use>
                    </svg>
                    Edit
                  </li>

                  <li *ngIf="!drawing.name" class="editMenuList__item" id="downloadItem" (click)="downloadFile(drawing.file.fileUrl, drawing.file?.name)" (click)="drawing.link ? drawingFormOpened = true : ''">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="download"></use>
                    </svg>
                    Download
                  </li>
                  <li *ngIf="currentUser.role !== UserRole.ROLE_EMPLOYEE" class="editMenuList__item" id="deleteItem" (click)="selectedDrawing = drawing; deletingPopupOpened = true">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="trash-delete-icon"></use>
                    </svg>
                    Delete
                  </li>
                </ul>
              </li>

            </ul>
          </li>

          <app-pagination *ngIf="this.drawingsPage.totalElements > 20 && (isMobile | async)" [isLoading]="!drawingsPage" [paginationInfo]="this.drawingsPage" [showAmountForMobile]="pageSizeOnMobile" (onChangeShowNumber)="changeShowNumber($event)" (changePage)="changeCurrentPage($event)"></app-pagination>
        </ul>
      </div>
    </div>

  </div>
</ng-scrollbar>

<div *ngIf="!drawingsPage || isLoading" class="table-wrapper-with-dots loading">
  <app-loader></app-loader>
</div>


<app-pagination *ngIf="this.drawingsPage?.totalElements > 20 && !(isMobile | async)" [showAmountForDesk]="pageSizeOnDesktop" [isLoading]="!drawingsPage" [paginationInfo]="this.drawingsPage" (onChangeShowNumber)="changeShowNumber($event)" (changePage)="changeCurrentPage($event)"></app-pagination>


<ng-template #emptyList>
  <div class="empty-list-wrapper">
    <p class="empty-list-text" *ngIf="isNoFilteringEnabled() && !hideCompleted && drawingsPage.empty; else notFound">The list of drawings is empty.</p>
    <ng-template #notFound>
      <p class="empty-list-text">No File found</p>
    </ng-template>
    <img class="empty-list-icon" src="assets/images/empty-list.png" alt="empty-list">
  </div>
</ng-template>

<div class="backdrop manual" [attr.show]="deletingPopupOpened">
  <div [enabled]="deletingPopupOpened" (appOutsideClick)="closeDeletingPopup()" class="modal-narrow">
    <button type="button" id="btnCloseModal" class="close-modal-btn close-modal-deleting" (click)="closeDeletingPopup()">
      <svg class="close-modal-icon">
        <use spriteVersion iconName="modal-close"></use>
      </svg>
    </button>

    <div class="confirmDeleting-wrapper">
      <p class="confirmDeleting-title" *ngIf="selectedDrawing">Delete the {{selectedDrawing.type === DocumentType.FILE ? 'Drawing' : 'Link'}} “{{selectedDrawing.name || selectedDrawing.file.name}}”?</p>
      <p class="confirmDeleting-title" *ngIf="!selectedDrawing">Delete All Selected Drawings?</p>
    </div>

    <div class="form-btn-wrapper">
      <button type="button" id="btnCancel" class="btn-cancel" (click)="closeDeletingPopup()">Cancel</button>
      <button type="submit" id="btnDeleteTask" class="btn-delete" [disabled]="deletingBtnDisabled" (click)="selectedDrawing ? deleteDrawing(selectedDrawing.id) : deleteSelected(); closeDeletingPopup();">
        <svg class="btn-delete__icon">
          <use spriteVersion iconName="trash-delete-icon"></use>
        </svg>
        Delete {{selectedDrawing && selectedDrawing.type === DocumentType.FILE  ? 'Drawing' : selectedDrawing?.type === DocumentType.LINK ? 'Link' : 'Drawings'}}
      </button>
    </div>
  </div>
</div>

<app-drawing-form *ngIf="drawingFormOpened" [drawing]="selectedDrawing" (close)="drawingFormOpened = false; selectedDrawing = null" (created)="drawingFormOpened = false; selectedDrawing = null; getDrawingsList()"></app-drawing-form>
<app-file-preview *ngIf="selectedDrawingForPreview" [document]="selectedDrawingForPreview" (close)="selectedDrawingForPreview = null"></app-file-preview>


