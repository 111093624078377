import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Area, AreaStatusDefaultOrder, AreaWeight} from "../../../../../../_models/area";
import {
  areaWeightByEnum,
  modalAnimation,
} from "../../../../../../config/constants";
import {originalOrder} from "../../../../../../_util/utils";
import {NotificationService} from "../../../../../../_services/notification.service";
import {AreasService} from "../../../../../../_services/areas.service";
import {SelectedArea} from '../../types';
import {ProjectCustomColors, ProjectCustomStatuses} from "../../../../../../_models/project";
import {User, UserRole} from "../../../../../../_models/user";
import {AreaFormStore} from "./area-form.store";
import {of, Subject, Subscription, takeUntil} from "rxjs";
import {GlobalStore} from "../../../../../../global.store";
import {ProjectViewStore} from "../../project-view/project-view.store";
import {FormFile} from "../../../../../../_models/material";
import {DatePipe} from "@angular/common";
import {TimePrettifyPipe} from "../../../../../../_pipes/timePrettify.pipe";

@Component({
  selector: 'app-area-form',
  templateUrl: './area-form.component.html',
  styleUrls: ['./area-form.component.scss'],
  animations: [modalAnimation],
  providers: [AreaFormStore, TimePrettifyPipe],
})
export class AreaFormComponent implements OnInit, OnDestroy {
  @HostBinding('@modalAnimation') modalAnimation: any;

  @Input() projectStatuses: ProjectCustomStatuses[] = [];
  private _selectedArea: SelectedArea;
  private readonly destroy$ = new Subject<void>()

  @Output() updated: EventEmitter<SelectedArea> = new EventEmitter<SelectedArea>();
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeAreaFile: EventEmitter<any> = new EventEmitter<any>()

  @ViewChild('areaStatusSelectRef') areaStatusSelectRef: ElementRef;
  activeTab: 'activity' | 'history' | 'files' = 'activity';

  startChangeStatusProgress = false;

  isChangeStatusAreaLoading = false
  isLoadingStatus = false;
  isLoadingWeight = false;
  isLoadingStatusProgress = false;

  statusProgressDump = 0;
  selectedFileForPreview: FormFile;


  get selectedArea(): SelectedArea {
    return this._selectedArea;
  }

  @Input()
  set selectedArea(value: SelectedArea) {
    this._selectedArea = value;
    this.statusProgressDump = value.area.statusProgress;
  }

  constructor(public areaFormStore: AreaFormStore,
              private areasService: AreasService,
              private notifService: NotificationService,
              readonly globalStore: GlobalStore,
              private date: DatePipe) {
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => this.areaFormStore.companyId = companyId);
    this.globalStore.projectId$.pipe(takeUntil(this.destroy$)).subscribe((projectId) => this.areaFormStore.projectId = projectId);
  }

  ngOnInit() {
    this.updateComments();
    this.updateHistory();
  }

  transformTheFirstLetter(value: string) {
    if(value === 'NO_WEIGHT') return 'No Weight';
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  addColorToSelectValue(statusSelected: ProjectCustomStatuses): any {
    if (statusSelected.orderNumber < 0) {
      if (statusSelected.orderNumber !== -3) return 'white'
      else return 'black'
    }
    if (statusSelected.color === ProjectCustomColors.YELLOW || statusSelected.color === ProjectCustomColors.GREY || statusSelected.color === ProjectCustomColors.MINT) {
      return "black"
    } else return "white"
  }

  addBackgroundToStatusItem(status: ProjectCustomStatuses) {
    if (status.orderNumber === -3) return null
    else return status.color
  }

  changeAreaWeight(areaWeight: AreaWeight) {
    this._selectedArea.area.areaWeight = areaWeight;
    this.isLoadingWeight = true;
    this.isChangeStatusAreaLoading = true;
    this.areasService.updateAreaWeightById(this._selectedArea.area.id, this._selectedArea.area.areaWeight).subscribe(() => {
      this.notifService.successNotification('Changes have been saved');
      this.updateHistory();
      this._selectedArea.area.attachmentsAmount = this._selectedArea.area.files.length
      this._selectedArea.area.commentsAmount = this._selectedArea.area.comments.length
      this.updated.emit(this._selectedArea);
      this.isChangeStatusAreaLoading = false;
      this.isLoadingWeight = false;
    })
  }

  findSelectedStatus(statusId: number) {
    return this.projectStatuses.find(status => status.id === statusId)
  }

  changeAreaStatus(statusId: number) {
    this._selectedArea.area.status = this.findSelectedStatus(statusId);
    this._selectedArea.area.status.inUse = false;
    this.isLoadingStatus = true;

    this.isChangeStatusAreaLoading = true
    this.areasService.updateAreaStatusById(this._selectedArea.area.id, statusId).subscribe(() => {
      this.startChangeStatusProgress = false;
      this.findSelectedStatus(statusId).inUse = true;
      this.notifService.successNotification('Changes have been saved');

      switch (this._selectedArea.area.status.orderNumber) {
        case AreaStatusDefaultOrder.TO_DO: {
          this._selectedArea.area.statusProgress = 0;
          this.statusProgressDump = this._selectedArea.area.statusProgress;
          break
        }

        case AreaStatusDefaultOrder.DONE: {
          this._selectedArea.area.statusProgress = 100;
          this.statusProgressDump = this._selectedArea.area.statusProgress;
          break
        }
      }
      this._selectedArea.area.attachmentsAmount = this._selectedArea.area.files.length
      this._selectedArea.area.commentsAmount = this._selectedArea.area.comments.length
      this.isChangeStatusAreaLoading = false
      this.updated.emit(this._selectedArea);
      this.isLoadingStatus = false;
      this.updateHistory();
    })
  }

  updateAreaStatusProgress() {
    this._selectedArea.area.statusProgress = this.statusProgressDump;
    this.isLoadingStatusProgress = true;
    this.areasService.updateAreaStatusProgressById(this._selectedArea.area.id, this._selectedArea.area.statusProgress).subscribe(() => {
      this.startChangeStatusProgress = false;
      this._selectedArea.area.attachmentsAmount = this._selectedArea.area.files.length
      this._selectedArea.area.commentsAmount = this._selectedArea.area.comments.length
      this.updated.emit(this._selectedArea);
      this.notifService.successNotification('Changes have been saved');
      this.updateHistory();
      this.isLoadingStatusProgress = false;
    })
  }

  calculateProgressSquareAttrs(el: HTMLElement, bar: number) {
    let backgroundColor;
    let opacity;
    if (this.statusProgressDump < bar) {
      backgroundColor = '#E5E8ED';
      opacity = 1;
    } else {
      backgroundColor = '#37A702';
      opacity = Math.max(this.statusProgressDump, 10) / 100;
    }
    el.style.backgroundColor = backgroundColor;
    el.style.opacity = String(opacity);
  }

  removeMentionList() {
    document.querySelector('body .ql-mention-list-container')?.remove()
  }

  updateComments() {
    this.loadComments(this._selectedArea.area.id);
  }

  loadComments(areaId: number) {
    this.areaFormStore.loadComments(of({areaId}));
  }

  updateHistory() {
    this.loadHistory(this._selectedArea.area.id);
  }

  loadHistory(areaId: number) {
    this.areaFormStore.loadHistory(of({areaId: areaId}));
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly originalOrder = originalOrder;
  protected readonly AreaStatusDefaultOrder = AreaStatusDefaultOrder;
  protected readonly areaWeightByEnum = areaWeightByEnum;
  protected readonly UserRole = UserRole;
}
