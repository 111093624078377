import { Injectable } from '@angular/core';
import {apiUrl} from "../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Activity} from "../_models/activity";
import {Page} from "../_models/page";

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(private http: HttpClient) {
  }

  getActivityList(companyId: number,  projectId: number, page: number, pageSize: number) {
    let params = new HttpParams();
    if (companyId) params = params.set('companyId', companyId);
    if (projectId) params = params.set('projectId', projectId);
    if (page) params = params.set('page', page);
    if (pageSize) params = params.set('pageSize', pageSize);
    return this.http.get<Page<Activity>>(`${apiUrl}/activity/list`, {params: params, withCredentials: true})
  }
}
