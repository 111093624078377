<div class="ng-select-wrapper">
  <div class="datapicker-field-wrapper">
    <input class="form-input-modal" [id]="id" mask="00.00.00" #input (change)="onInputChange(input.value)"
           placeholder="MM.DD.YY" [value]="result ? (result | dateFormatter) : ''">
    <button type="button" class="datapicker-button" (click)="pickerOpened = !pickerOpened;">
      <svg #openCloseBtnIcon class="calendar-icon">
        <use spriteVersion iconName="calendar"></use>
      </svg>
    </button>
    <div class="calendar-wrapper" [enabled]="pickerOpened" [excludeNodes]="[openCloseBtnIcon]" (appOutsideClick)="pickerOpened = false" [attr.calendar-on-top]="calendarOnTop" [attr.open]="pickerOpened">
      <div class="calendar-header">
        <button type="button" class="btn-calendar btn-arrow-left" (click)="goToPreviousMonth()">
          <svg class="arrow-calendar-icon">
            <use spriteVersion iconName="arrow-calendar"></use>
          </svg>
        </button>
        <p class="chosen-month-title">{{months[currentMonth - 1]}} {{currentYear}}</p>
        <button type="button" class="btn-arrow-right" (click)="goToNextMonth()">
          <svg class="arrow-calendar-icon">
            <use spriteVersion iconName="arrow-calendar"></use>
          </svg>
        </button>
      </div>

      <div class="calendar-content-wrapper">
        <table class="calendar-content">
          <thead>
          <tr class="weekdays-title-row">
            <td class="weekday-title">S</td>
            <td class="weekday-title">M</td>
            <td class="weekday-title">T</td>
            <td class="weekday-title">W</td>
            <td class="weekday-title">T</td>
            <td class="weekday-title">F</td>
            <td class="weekday-title">S</td>
          </tr>
          </thead>

          <tbody class="dates-body">
          <tr class="weeks-row">
            <td class="date-td" *ngFor="let day of daysInMonthArr.slice(0, 7)">
              <button type="button" class="btn-date" [ngClass]="isThisDateSelected(day) ? ['selected-date'] : ''"
                      (click)="onDayClicked(day)" [disabled]="day > 7" [attr.prevMonthDay]="day > 7"
                      [value]="day">{{day}}</button>
            </td>
          </tr>
          <tr class="weeks-row">
            <td class="date-td" *ngFor="let day of daysInMonthArr.slice(7, 14)">
              <button type="button" class="btn-date" [ngClass]="isThisDateSelected(day) ? ['selected-date'] : ''"
                      (click)="onDayClicked(day)" [value]="day">{{day}}</button>
            </td>
          </tr>
          <tr class="weeks-row">
            <td class="date-td" *ngFor="let day of daysInMonthArr.slice(14, 21)">
              <button type="button" class="btn-date" [ngClass]="isThisDateSelected(day) ? ['selected-date'] : ''"
                      (click)="onDayClicked(day)" [value]="day">{{day}}</button>
            </td>
          </tr>
          <tr class="weeks-row">
            <td class="date-td" *ngFor="let day of daysInMonthArr.slice(21, 28)">
              <button type="button" class="btn-date" [ngClass]="isThisDateSelected(day) ? ['selected-date'] : ''"
                      (click)="onDayClicked(day)" [value]="day">{{day}}</button>
            </td>
          </tr>
          <tr class="weeks-row">
            <td class="date-td" *ngFor="let day of daysInMonthArr.slice(28, 35)">
              <button type="button" class="btn-date" [ngClass]="isThisDateSelected(day) ? ['selected-date'] : ''"
                      (click)="onDayClicked(day)" [disabled]="day < 14" [attr.nextMonthDay]="day < 14"
                      [value]="day">{{day}}</button>
            </td>
          </tr>
          <tr class="weeks-row">
            <td class="date-td" *ngFor="let day of daysInMonthArr.slice(35, daysInMonthArr.length)">
              <button type="button" class="btn-date" [ngClass]="isThisDateSelected(day) ? ['selected-date'] : ''"
                      (click)="onDayClicked(day)" [disabled]="day < 14" [attr.nextMonthDay]="day < 14"
                      [value]="day">{{day}}</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
