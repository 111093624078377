import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {MaterialPaymentStatus, MaterialStoredLocation} from "../_models/material";
import {apiUrl} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MaterialStoredLocationService {

  constructor(private http: HttpClient) { }

  getAllMaterialStoredLocations(projectId: number):Observable<MaterialStoredLocation[]> {
    return this.http.get<MaterialStoredLocation[]>(`${apiUrl}/material/storedLocation`, {params: {projectId: projectId}, withCredentials: true})
  }

  createStoredLocation(projectId: number, name: string) {
    return this.http.post(`${apiUrl}/material/storedLocation`, name,{params: {projectId: projectId}, withCredentials: true})
  }

  deleteStoredLocation(storedLocationId: number) {
    let params = new HttpParams();
    if (storedLocationId) params = params.set('id', storedLocationId);

    return this.http.delete(`${apiUrl}/material/storedLocation`, {
      params: params,
      observe: "response",
      withCredentials: true
    })
  }
}
