import {Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {Page} from "../../../../../_models/page";
import {User, UserRole} from "../../../../../_models/user";
import {filter, forkJoin, Subject, switchMap, takeUntil, tap} from "rxjs";
import {NotificationService} from "../../../../../_services/notification.service";
import {GlobalStore} from "../../../../../global.store";
import {
  modalAnimation,
  orderStatusStringForSelector,
  SortType,
  submittalStatusStringForSelector
} from "src/app/config/constants";
import {getFilenameFromDisposition, originalOrder} from "../../../../../_util/utils";
import {Material, MaterialPaymentStatus, OrderStatus, SubmittalStatus} from "../../../../../_models/material";
import {MaterialsService} from "../../../../../_services/materials.service";
import {MaterialPaymentStatusService} from "../../../../../_services/material-payment-status.service";
import {saveAs} from "file-saver";

@Component({
  selector: 'app-project-materials',
  templateUrl: './project-materials.component.html',
  styleUrls: ['./project-materials.component.scss'],
  animations: [modalAnimation]
})
export class ProjectMaterialsComponent implements OnDestroy{
  @ViewChild('materialOrderStatus') materialOrderStatusRef: ElementRef
  sorting: any = {};
  query: string;
  pageNumber = 0;
  pageSizeOnDesktop = 20;
  pageSizeOnMobile: number;
  isLoading = false;

  selectedMaterial: Material;

  materialsPage: Page<Material>;
  multiSelectedMaterials: number[] = [];
  multiSelectEnabled = false;
  materialFormOpened = false;
  deletingPopupOpened = false;
  deletingBtnDisabled = false;
  isMultiSelectAll = false;

  currentUser: User;

  paymentStatusesList: MaterialPaymentStatus[] = [];

  selectedSubmittalStatus: SubmittalStatus;
  selectedOrderStatus: OrderStatus;
  selectedPaymentStatus: MaterialPaymentStatus;

  isMobile= this.globalStore.isMobile$;
  isIOS = this.globalStore.isIOS$;

  companyId: number;
  projectId: number;

  private readonly destroy$ = new Subject<void>()

  constructor(private materialService: MaterialsService,
              private materialPaymentStatusService: MaterialPaymentStatusService,
              private notifService: NotificationService,
              readonly globalStore: GlobalStore) {
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => this.companyId = companyId);
    this.globalStore.currentUser$.pipe(takeUntil(this.destroy$)).subscribe(user => this.currentUser = user)

    this.globalStore.projectId$.pipe(
      takeUntil(this.destroy$),
      filter((projectId) => !!projectId),
      tap((projectId) => {
        this.projectId = projectId;
      }),
      switchMap((projectId) =>
        forkJoin({
          paymentStatuses: this.materialPaymentStatusService.getAllMaterialPaymentStatuses(projectId),
        })

      ),
      takeUntil(this.destroy$)
    ).subscribe((data) => {
      this.paymentStatusesList = data.paymentStatuses
      this.getMaterialsList()
    });
  }


  getMaterialsList() {
    this.isLoading = true;
    this.multiSelectedMaterials = [];
    let sortingField;
    let sortingDirection;

    if (Object.keys(this.sorting).length > 0) {
      sortingField = Object.keys(this.sorting)[0];
      sortingDirection = this.sorting[Object.keys(this.sorting)[0]];
    }

    this.materialService.getAllMaterialsListByParams(this.projectId, this.selectedSubmittalStatus,
                                                    this.selectedOrderStatus, this.selectedPaymentStatus?.id,  this.query,
                                                    sortingField, sortingDirection, this.pageNumber, this.pageSizeOnDesktop).subscribe(data => {
      if (data.totalPages > 0 && this.pageNumber + 1 > data.totalPages) {
        this.pageNumber = data.totalPages - 1;
        this.getMaterialsList();
      } else {
        this.materialsPage = data;
        this.isLoading = false;
      }
    });
  }

  export() {
    let sortingField;
    let sortingDirection;

    if (Object.keys(this.sorting).length > 0) {
      sortingField = Object.keys(this.sorting)[0];
      sortingDirection = this.sorting[Object.keys(this.sorting)[0]];
    }

    this.materialService.export(this.projectId, this.selectedSubmittalStatus, this.selectedOrderStatus, this.selectedPaymentStatus?.id,  this.query, sortingField, sortingDirection).subscribe((response) => {
      const contentDisposition = response.headers.get('Content-Disposition');
      const filename = getFilenameFromDisposition(contentDisposition);

      saveAs(response.body, filename);
    })
  }

  changeShowNumber(newNumber: number) {
    this.pageSizeOnDesktop = newNumber;
    this.pageNumber = 0;
    this.pageSizeOnMobile = newNumber;
    this.getMaterialsList();
  }

  changeCurrentPage(newPage: number) {
    this.pageNumber = newPage;
    this.getMaterialsList();
    this.isMultiSelectAll = false;
  }

  updateMaterialStatus(material: Material, newStatus: any, type: 'submittalStatus' | 'orderStatus') {
    switch (type) {
      case 'submittalStatus': {
        this.materialService.updateSubmittalStatus(material.id, newStatus).subscribe(() => {
          material.submittalStatus = newStatus;
          material.version = material.version + 1
          this.materialsPage.content.filter(e => e.id === material.id)[0].submittalStatus = newStatus;
          this.notifService.successNotification('Changes have been saved');
        });
        break
      }
      case 'orderStatus': {
        this.materialService.updateOrderStatus(material.id, newStatus).subscribe(() => {
          material.orderStatus = newStatus;
          material.version = material.version + 1
          this.materialsPage.content.filter(e => e.id === material.id)[0].orderStatus = newStatus;
          this.notifService.successNotification('Changes have been saved');
        });
      }
    }
  }

  deleteMaterial(materialId: number) {
    this.deletingBtnDisabled = true;
    this.materialService.deleteMaterial([materialId]).subscribe(() => {
      this.getMaterialsList();
      this.notifService.successNotification('Changes have been saved');
      this.deletingBtnDisabled = false;
    }, error => this.deletingBtnDisabled = false)
  }

  openEditMaterialForm(material: Material) {
    this.selectedMaterial = material
    this.materialFormOpened = true
  }

  toggleCheckAll() {
    this.multiSelectedMaterials = [];
    if(this.isMultiSelectAll) {
      this.isMultiSelectAll = false;
    }
    else {
      this.materialsPage.content.map(task => this.multiSelectedMaterials.push(task.id))
      this.isMultiSelectAll = true
    }
  }

  toggleCheck(materialId: number) {
    if (this.multiSelectedMaterials.includes(materialId)) this.multiSelectedMaterials.splice(this.multiSelectedMaterials.indexOf(materialId), 1);
    else this.multiSelectedMaterials.push(materialId);

    this.multiSelectEnabled = this.multiSelectedMaterials.length > 0;
  }

  deleteSelected() {
    this.deletingBtnDisabled = true;
    this.materialService.deleteMaterial(this.multiSelectedMaterials).subscribe(() => {
      this.getMaterialsList()
      this.deletingPopupOpened = false;
      this.notifService.successNotification('Changes have been saved');
      this.deletingBtnDisabled = false;
    } ,error => this.deletingBtnDisabled = false)
  }

  getFiltersActiveAmount() {
    let result = 0;
    if (this.selectedSubmittalStatus) result++;
    if (this.selectedOrderStatus) result++;
    if (this.selectedPaymentStatus) result++;
    return result;
  }

  changeStatusInFilters(event: any, type: 'submittalStatus' | 'orderStatus' | 'paymentStatus') {
    switch (type) {
      case 'submittalStatus': {
        this.selectedSubmittalStatus = event
        break
      }
      case 'orderStatus': {
        this.selectedOrderStatus = event
        break
      }
      case 'paymentStatus': {
        this.selectedPaymentStatus = this.paymentStatusesList.find(status => status.id === event)
        break
      }
    }
  }

  closeEditableSelectorInFilters() {
    this.selectedPaymentStatus = this.selectedPaymentStatus ? this.selectedPaymentStatus : null
  }

  clearFilters() {
    this.selectedSubmittalStatus = null;
    this.selectedOrderStatus = null;
    this.selectedPaymentStatus = null;
    this.getMaterialsList()
  }

  onSortBy(sortBy: string) {
    if (this.sorting[sortBy] !== undefined) this.sorting[sortBy] = this.sorting[sortBy] === SortType.ASC ? SortType.DESC : SortType.ASC;
    else {
      this.sorting = {};
      this.sorting[sortBy] = SortType.DESC;
    }
    this.getMaterialsList();
  }

  isNoFilteringEnabled() {
    return this.getFiltersActiveAmount() === 0 && (!this.query || this.query.length === 0);
  }

  closeDeletingPopup() {
    this.deletingPopupOpened = false;
    setTimeout(() => this.selectedMaterial = undefined, 250);
  }


  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly SortType = SortType;
  protected readonly UserRole = UserRole;
  protected readonly originalOrder = originalOrder;
  protected readonly submittalStatusByEnum = submittalStatusStringForSelector;
  protected readonly orderStatusByEnum = orderStatusStringForSelector
  protected readonly Array = Array;
}
