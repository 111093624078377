<script src="../forgot-password/forgot-password.component.ts"></script>
<div class="container">
  <div class="login-wrapper">
    <svg class="logo">
      <use spriteVersion iconName="colorjob-logo"></use>
    </svg>
    <h1 class="create-password-title">Create New Password</h1>

    <form [formGroup]="newPasswordForm" class="form-login" #form="ngForm" (ngSubmit)="onSubmit()">
      <p class="main-error" *ngIf="form.submitted && form.hasError('error')" id="mainError">
        <svg class="error-icon">
          <use spriteVersion iconName="error-icon"></use>
        </svg>
        {{form.getError('error')}}
      </p>
      <div class="form__field-wrapper">
        <label class="form-label"> <span class="label-text">New Password</span>
          <input formControlName="newPassword" placeholder="Password" formInput="PASSWORD" [type]="isShowPassword ? 'text' : 'password'" id="newPassword" class="form-input"/>
          <button class="btn-show" (click)="showPassword()" type="button" id="showNewPassword">
            <svg class="show-password-icon" [class.black-icon]="newPasswordForm.controls['newPassword'].value !== ''">
              <use spriteVersion [iconName]="isShowPassword ? 'hide-password' : 'show-password'"></use>
            </svg>
          </button>
        </label>
        <span class="error-message" id="newPasswordError" *ngIf="form.submitted && newPasswordForm.controls['newPassword'].invalid">{{newPasswordForm.controls['newPassword'].getError('error')}}</span>
      </div>

      <div class="form__field-wrapper">
        <label class="form-label"> <span class="label-text">Confirm Password</span>
          <input formControlName="confirmPassword" placeholder="Password" formInput="PASSWORD" [type]="isShowConfirmPassword ? 'text' : 'password'" id="confirmPassword" class="form-input"/>
          <button class="btn-show" id="showConfirmPassword" (click)="showConfirmPassword()" type="button">
            <svg class="show-password-icon" [class.black-icon]="newPasswordForm.controls['confirmPassword'].value !== ''">
              <use spriteVersion [iconName]="isShowConfirmPassword ? 'hide-password' : 'show-password'"></use>
            </svg>
          </button>
        </label>
        <span class="error-message" id="confirmPasswordErrorRequired" *ngIf="form.submitted && newPasswordForm.controls['confirmPassword'].invalid">{{newPasswordForm.controls['confirmPassword'].getError('error')}}</span>
      </div>

      <button loader="white" [showLoader]="showLoader" class="btn-login" type="submit" id="btn-submit">Create</button>

      <a class="link" routerLink="/login" id="linkCancel">Cancel</a>
    </form>
  </div>
</div>

