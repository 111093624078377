import {Component, ElementRef, OnDestroy, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {User, UserRole} from "../../../../_models/user";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import { SwiperOptions } from 'swiper';
import {Project} from "../../../../_models/project";
import {GlobalStore} from "../../../../global.store";
import {filter, Subject, Subscription, takeUntil} from "rxjs";

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  selectedTab: string;
  @ViewChildren('tabItem') tabItems!: QueryList<ElementRef>;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  config: SwiperOptions = {
    navigation: false,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
    slidesPerView: 'auto',
    grabCursor: true,
    freeMode: false,

    breakpoints: {
      375: {
        slidesPerView: 4,
        spaceBetween: 0,
      }
    }
  };

  companyName: string;
  project: Project;

  constructor(private routerActive: ActivatedRoute,
              readonly globalStore: GlobalStore,
              private router: Router ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.getSubTitle(this.routerActive.snapshot.root.firstChild?.routeConfig?.path);
      });

    this.globalStore.companyName$.pipe(takeUntil(this.destroy$)).subscribe((companyName) => this.companyName = companyName);
    this.globalStore.project$.pipe(takeUntil(this.destroy$)).subscribe((project) => this.project = project);
  }

  setSubtitle(selectedTab: string) {
    this.selectedTab = selectedTab;
  }

  getSubTitle(subtitle: string) {
    switch (subtitle) {
      case 'view': {
        this.setSubtitle('View')
        return
      }
      case 'progress': {
        this.setSubtitle('Project Progress')
        return
      }
      case 'tasks': {
        this.setSubtitle('Tasks')
        return
      }
      case 'users': {
        this.setSubtitle('Users')
        return
      }
      case 'activity': {
        this.setSubtitle('Activity')
        return
      }
      case 'documents': {
        this.setSubtitle('Documents')
        return
      }
      case 'drawings': {
        this.setSubtitle('Drawings')
        return
      }
      case 'materials': {
        this.setSubtitle('Materials')
        return
      }
      case 'settings': {
        this.setSubtitle('Settings')
        return
      }
    }
  }

  scrollToTab(id: number) {
    if(window.innerWidth < 1540){
      const tabItemElement = this.tabItems.find((_, idx) => idx === id)?.nativeElement;
      if (tabItemElement && this.scrollContainer) {
        const scrollContainerElement = this.scrollContainer.nativeElement;
        const itemPosition = tabItemElement.offsetLeft;
        const containerWidth = scrollContainerElement.clientWidth;
        const itemWidth = tabItemElement.clientWidth;

        // Calculate the scroll position to center the clicked item in the view
        const scrollToPosition = itemPosition - (containerWidth / 2) + (itemWidth / 2);

        scrollContainerElement.scrollTo({
          left: scrollToPosition,
          behavior: 'smooth'
        });
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected readonly UserRole = UserRole;
}
