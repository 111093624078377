<div class="history-container">
  <p *ngIf="(areaFormStore.history$ | async).length === 0" class="no-changes-placeholder">There have been no status changes yet</p>
  <ul *ngIf="(areaFormStore.history$ | async).length > 0" class="history-items">
    <li *ngFor="let item of (areaFormStore.history$ | async)">
      <div class="top-line">
        <div class="initiator">
          <p class="initiator">{{item.initiator.id === (globalStore.currentUser$ | async).id ? 'You' : item.initiator.name}}</p>
          <span>changed
            <ng-container [ngSwitch]="item.type">
              <ng-container *ngSwitchCase="'STATUS_PROGRESS'">status progress</ng-container>
              <ng-container *ngSwitchCase="'STATUS'">status</ng-container>
              <ng-container *ngSwitchCase="'WEIGHT'">weight</ng-container>
          </ng-container>
            </span>
        </div>
        <p class="timestamp">{{item.timestamp | date: 'MMM d, y h:mm a'}}</p>
      </div>
      <div class="changes">
        <p>
          <ng-container [ngSwitch]="item.type">
            <ng-container *ngSwitchCase="'STATUS_PROGRESS'">{{item.oldValue}}%</ng-container>
            <ng-container *ngSwitchCase="'STATUS'">{{item.oldValue}}</ng-container>
            <ng-container *ngSwitchCase="'WEIGHT'">{{item.oldValue | titlecase}}</ng-container>
          </ng-container>
        </p>
        <svg width="16" height="16"><use spriteVersion iconName="arrow-greater"></use></svg>
        <p>
          <ng-container [ngSwitch]="item.type">
            <ng-container *ngSwitchCase="'STATUS_PROGRESS'">{{item.newValue}}%</ng-container>
            <ng-container *ngSwitchCase="'STATUS'">{{item.newValue}}</ng-container>
            <ng-container *ngSwitchCase="'WEIGHT'">{{item.newValue | titlecase}}</ng-container>
          </ng-container>
        </p>
      </div>
    </li>
  </ul>
</div>
