import {Component, OnDestroy} from '@angular/core';
import {Router} from "@angular/router";
import {NotificationService} from "../../../../../_services/notification.service";
import {ProjectsService} from "../../../../../_services/projects.service";
import {User, UserRole} from "../../../../../_models/user";
import {GlobalStore} from "../../../../../global.store";
import {Project} from "../../../../../_models/project";
import {Subject, Subscription, take, takeUntil} from "rxjs";

@Component({
  selector: 'app-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.scss']
})
export class ProjectSettingsComponent implements OnDestroy {
  projectEditFormOpened = false;
  isOpenConfirmDeletingModal: boolean = false;
  currentUser: User;
  deletingBtnDisabled = false;

  companyId: number;
  project: Project;

  private readonly destroy$ = new Subject<void>()

  constructor(private projectService: ProjectsService,
              private notifService: NotificationService,
              private router: Router,
              readonly globalStore: GlobalStore) {
    this.globalStore.currentUser$.pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe((user: User) => this.currentUser = user);
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe(companyId => this.companyId = companyId);
    this.globalStore.projectId$.pipe(takeUntil(this.destroy$)).subscribe(projectId => {
      if (!projectId) return;
      this.projectService.getProjectById(projectId).subscribe((data) => {
        this.project = data;
        this.globalStore.updateProject(data);
      });
    });
  }

  deleteProject() {
    this.deletingBtnDisabled = true;
    this.projectService.deleteProjects([this.project.id]).subscribe(() => {
        this.globalStore.deleteProjectsFromCompany([this.project.id]);
        this.notifService.successNotification("Changes have been saved")
        this.deletingBtnDisabled = false;
        this.isOpenConfirmDeletingModal = false;
        this.router.navigateByUrl(`/system/company/${this.companyId}`)
    }, error => this.deletingBtnDisabled = false)
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly UserRole = UserRole;
}




