import {Pipe, PipeTransform} from "@angular/core";
import {Room} from "../_models/room";

@Pipe({
  name: 'roomName'
})
export class RoomNamePipe implements PipeTransform {
  transform(room: Room): string {
    if (room.uniqNumber > 1) return `${room.name} #${room.uniqNumber}`;
    else return room.name;
  }
}
