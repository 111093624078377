import {inject} from '@angular/core';
import {AuthService} from "../_services/auth.service";
import {Router} from "@angular/router";

export const userDataFilledGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const currentUser =  authService.getCurrentUser();
  if (!currentUser.name || !currentUser.phone) return router.parseUrl('/fill-info');
  else return true;
};
