import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {Company, CompanyType} from "../../../../_models/company";
import {NgSelectComponent} from "@ng-select/ng-select";
import {CompaniesService} from "../../../../_services/companies.service";
import {companyTypeByEnum, SortType} from 'src/app/config/constants';
import {Page} from "../../../../_models/page";
import * as americanStates from "../../../../../assets/data/usa_states_cities.json";
import {UserRole} from "../../../../_models/user";
import {NotificationService} from "../../../../_services/notification.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {GlobalStore} from "../../../../global.store";
import {getFilenameFromDisposition} from "../../../../_util/utils";
import {saveAs} from "file-saver";

const fadeIn = trigger('fadeIn', [
  state('void', style({ opacity: 0 })),
  transition(':enter, :leave', [animate(300)]), // 300 мілісекунд для прикладу, ви можете змінити це значення
]);
@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
  animations: [fadeIn]
})
export class CompaniesComponent implements OnInit {
  @ViewChildren(NgSelectComponent) ngSelectComponent: QueryList<NgSelectComponent>;
  @ViewChild('citySelector') citySelectComponent: NgSelectComponent;
  company = new Company();
  companiesPage: Page<Company>;
  companyFormOpened = false;
  isMultiSelectAll = false;
  isLoading = false;

  states: string[] = Object.keys(americanStates).filter(state => state !== 'default')
  cities: string[] = [];

  selectedCompanyType: CompanyType;
  selectedState: string;
  selectedCity: string;
  sorting: any = {};
  query: string;

  pageNumber = 0;
  pageSizeOnDesktop = 20;
  pageSizeOnMobile: number;

  multiSelectEnabled = false;
  multiSelectedCompanies: number[] = [];
  selectedCompany: Company;
  deletingPopupOpened = false;
  deletingBtnDisabled = false;

  showDropDownTop = false;
  isMobile = this.globalStore.isMobile$;
  isIOS = this.globalStore.isIOS$;

  constructor(private companyService: CompaniesService,
              private notifService: NotificationService,
              readonly globalStore: GlobalStore) {
  }

  ngOnInit() {
    this.getCompanyList();
  }


  getCompanyList() {
    this.isLoading = true;
    this.multiSelectedCompanies = [];
    let sortingField;
    let sortingDirection;

    if (Object.keys(this.sorting).length > 0) {
      sortingField = Object.keys(this.sorting)[0];
      sortingDirection = this.sorting[Object.keys(this.sorting)[0]];
    }

    this.companyService.getCompanyListByParams(this.selectedCompanyType, this.selectedState, this.selectedCity, this.query, sortingField, sortingDirection, this.pageNumber, this.pageSizeOnDesktop).subscribe((data) => {
      if (data.totalPages > 0 && this.pageNumber + 1 > data.totalPages) {
        this.pageNumber = data.totalPages - 1;
        this.getCompanyList();
      } else {
        this.companiesPage = data;
        this.isLoading = false;
      }
    });
  }

  export() {
    let sortingField;
    let sortingDirection;

    if (Object.keys(this.sorting).length > 0) {
      sortingField = Object.keys(this.sorting)[0];
      sortingDirection = this.sorting[Object.keys(this.sorting)[0]];
    }

    this.companyService.export(this.selectedCompanyType, this.selectedState, this.selectedCity, this.query, sortingField, sortingDirection).subscribe((response) => {
      const contentDisposition = response.headers.get('Content-Disposition');
      const filename = getFilenameFromDisposition(contentDisposition);

      saveAs(response.body, filename);
    })
  }

  changeShowNumber(event: any) {
    this.pageSizeOnDesktop = event;
    this.pageNumber = 0;
    this.pageSizeOnMobile = event
    this.getCompanyList();
  }
  changeCurrentPage(event: any) {
    this.pageNumber = event;
    this.getCompanyList();
    this.isMultiSelectAll = false;
  }

  toggleCheckAll() {
    this.multiSelectedCompanies = [];
    if(this.isMultiSelectAll) {
      this.isMultiSelectAll = false;
    }
    else {
      this.companiesPage.content.map(company => this.multiSelectedCompanies.push(company.id))
      this.isMultiSelectAll = true
    }
  }

  toggleCheck(companyId: number) {
    if (this.multiSelectedCompanies.includes(companyId)) this.multiSelectedCompanies.splice(this.multiSelectedCompanies.indexOf(companyId), 1);
    else this.multiSelectedCompanies.push(companyId);

    this.multiSelectEnabled = this.multiSelectedCompanies.length > 0;
  }

  deleteCompany() {
    this.deletingBtnDisabled = true;
    this.companyService.deleteCompany([this.selectedCompany.id]).subscribe(() => {
      this.getCompanyList();
      this.notifService.successNotification('Changes have been saved');
      this.deletingBtnDisabled = false;
    }, error => this.deletingBtnDisabled = false)
  }

  getCompanyById(companyId: number) {
    this.companyService.getCompanyById(companyId).subscribe((company) => this.selectedCompany = company);
  }

  deleteSelected() {
    this.deletingBtnDisabled = true;
    this.companyService.deleteCompany(this.multiSelectedCompanies).subscribe(() => {
      this.getCompanyList();
      this.deletingPopupOpened = false;
      this.notifService.successNotification('Changes have been saved');
      this.deletingBtnDisabled = false;
    }, error =>  this.deletingBtnDisabled = false)
  }

  clearFilters() {
    this.ngSelectComponent.forEach(e => e.handleClearClick());
  }

  onSortBy(sortBy: string) {
    if (this.sorting[sortBy] !== undefined) this.sorting[sortBy] = this.sorting[sortBy] === SortType.ASC ? SortType.DESC : SortType.ASC;
    else {
      this.sorting = {};
      this.sorting[sortBy] = SortType.DESC;
    }
    this.getCompanyList();
  }

  isNoFilteringEnabled() {
    return this.getFiltersActiveAmount() === 0 && (!this.query || this.query.length === 0);
  }

  getFiltersActiveAmount() {
    let result = 0;
    if (this.selectedCity) result++;
    if (this.selectedState) result++;
    if (this.selectedCompanyType) result++;

    return result;
  }

  stateChanged() {
    if (this.selectedCity) this.citySelectComponent.handleClearClick();
    else this.getCompanyList();

    this.cities = americanStates[this.selectedState as keyof typeof americanStates];
  }

  closeDeletingPopup() {
    this.deletingPopupOpened = false;
    setTimeout(this.selectedCompany = undefined, 250);
  }

  protected readonly SortType = SortType;
  protected readonly companyTypeByEnum = companyTypeByEnum;
  protected readonly UserRole = UserRole;
}
