import {User} from "./user";

export enum SubmittalStatus {
  SAMPLES_ORDERED = "SAMPLES_ORDERED",
  SUBMITTED = "SUBMITTED",
  APPROVED = "APPROVED",
  APPROVED_AS_NOTED = "APPROVED_AS_NOTED",
  REJECTED = "REJECTED",
  NOT_SUBMITTED = "NOT_SUBMITTED"
}

export enum OrderStatus {
  NOT_ORDERED = "NOT_ORDERED",
  PO_SUBMITTED = "PO_SUBMITTED",
  PARTIALLY_ORDERED = "PARTIALLY_ORDERED",
  ORDERED = "ORDERED",
  ARRIVED = "ARRIVED"
}

export class Material {
  id: number;
  version: number;
  tag: string;
  description: string;
  submittalFiles: FormFile[] = [];
  unit: MaterialUnit;
  supplier: string;
  area: string;
  submittalStatus: SubmittalStatus;
  orderStatus: OrderStatus = OrderStatus.NOT_ORDERED;
  eta: number;
  grossQty: number;
  orderQty: number;
  orderDate: number;
  paymentStatus: MaterialPaymentStatus;
  receivedQty: number;
  storedLocation: MaterialStoredLocation;
  notes: string;
  purchasingFiles: FormFile[] = [];
}

export class FormFile {
  id: number;
  name: string;
  sizeBytes: number;
  fileUrl: string;
  fileToSave: any;
  progress: number;
  timeLeft: number;
  imgSrc: string;
  owner: User = new User();
  timestamp: number;
}

export class MaterialUnit {
  id: number;
  name: string;
  isDeletable: boolean = true;
}

export class MaterialPaymentStatus {
  id: number;
  name: string;
  isDeletable: boolean = true;
}

export class MaterialStoredLocation {
  id: number;
  name: string;
  isDeletable: boolean = true;
}
