<ul class="area-progress-list-rooms">
  <li *ngFor="let room of unit.rooms; trackBy: trackByRoom" [class.room-mock]="room.id === 0"
    class="area-progress-list-rooms__item">
    <ul class="area-progress-list-areas">
      <li class="area-progress-list-areas__item main" *ngFor="let area of room.areas; trackBy: trackByArea"
          #areaItem
          [id]="area.id"
          (click)="area.id === 0 ? null : areaClick.emit({area, unit, room, floor: currentFloor})"
          (mouseover)="area.id === 0 ? null : areaMouseOver.emit(areaItem)"
          (mouseleave)="area.id === 0 ? null : areaMouseLeave.emit()"
          [attr.status]="area.status?.orderNumber"
          [attr.status-name]="area.status?.name"
          [ngStyle]="{'cursor': area.id !== 0 ? 'pointer' : null}"
          [class.area-mock]="area.id === 0">
        <span class="area-color-container" [attr.hasComments]="area.commentsAmount > 0"
          [ngStyle]="{'background-color': area.status?.orderNumber !== -3 ? area.status?.color : 'white', 'opacity': area.pp_opacity}"></span>
        <span *ngIf="area.id !== 0"
          class="area-color-container areaProgress-text"
          [ngStyle]="{'color': area.statusProgress >= 60 && area.status.orderNumber < 0 ? 'white' : null}">
          <span class="area-cube-inner-content-wrapper">
            <span *ngIf="area.status.orderNumber < 0 && designSettings.showPercentage && area.status.orderNumber !== -3">{{area.statusProgress}}<sup>%</sup></span>
            <span class="area-cube-icons-wrapper" [attr.isWhite]="addColorToAreaTriangle(area)"
                  [attr.orderNumber]="area.status.orderNumber"
                  [attr.color]="area.status.color" [attr.attachmentsAmount]="area.attachmentsAmount > 0">
              <svg class="small-attachment-icon" *ngIf="area.attachmentsAmount > 0 && designSettings.showAttachmentIcons">
                <use spriteVersion iconName="attachment-area-mini"></use>
              </svg>
              <svg class="small-description-icon" *ngIf="area.commentsAmount > 0 && designSettings.showCommentIcons">
                <use spriteVersion iconName="small-description-icon"></use>
              </svg>
            </span>
          </span>
        </span>

        <div *ngIf="areaItem.hasAttribute('showMini') && area.id !== 0 && !isNotDesktopScreen && designSettings.showPopUp"
             class="area-modal-mini">
          <div class="area-modal-mini-header">
            <span class="item-code-way">{{ area.publicId }}</span>
            <div class="attachments-wrapper">
              <p class="comments-wrapper">
                <svg>
                  <use spriteVersion iconName="comment"></use>
                </svg>
                <span>{{ area.commentsAmount }}</span>
              </p>
              <p class="files-wrapper">
                <svg>
                  <use spriteVersion iconName="attachment-area-mini"></use>
                </svg>
                <span>{{ area.attachmentsAmount }}</span>
              </p>
            </div>
          </div>
          <p class="area-modal-name">{{ area.name }}</p>
          <p class="area-weight" [attr.value]="area.areaWeight">{{ areaWeightByEnum.get(area.areaWeight) }}</p>
        </div>
      </li>
      <li class="area-progress-list-areas__item area-mock" *ngIf="room.areas.length === 0"></li>
    </ul>
  </li>
  <li *ngFor="let roomMock of [].constructor(amountMocksHorizontal)"
    class="area-progress-list-rooms__item room-mock">
    <ul class="area-progress-list-areas">
      <li class="area-progress-list-areas__item area-mock"></li>
    </ul>
  </li>
</ul>
