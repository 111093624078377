import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'phonePrettify'
})
export class PhonePrettifyPipe implements PipeTransform {
  transform(value: string, args?: any): string {
    if (value && value.length >= 7) return `(${value.substring(0, 3)}) ${value.substring(3, 6)}-${value.substring(6)}`;
    else return '-';
  }
}
