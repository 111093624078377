<div class="pagination-wrapper-with-show" id="paginationWrapper">
    <div class="show-wrapper">
        <div class="show-select-with-text-wrapper">
            <p class="show-text">Show</p>
            <ng-select class="custom select-without-absolute-last-option selectAmount"
                       id="selectAmountItems"
                       [items]="showAmountList"
                       [(ngModel)]="showAmount"
                       [clearable]="false"
                       notFoundText="No results matching"
                       [editableSearchTerm]="false"
                       (change)="changeShowNumber($event)"
                       [searchable]="false">
            </ng-select>
        </div>
        <p class="total-item-text">Total: {{paginationInfo?.totalElements}}</p>
    </div>

    <div class="pagination-wrapper" [attr.disabled]="isLoading">
        <button type="button" id="btnPrevPage" class="btn-previous" [disabled]="isLoading" *ngIf="!paginationInfo.first" (click)="goToPage(paginationInfo.number - 1, 'back')">
            <svg class="prev-page-arrow-icon">
                <use spriteVersion iconName="arrow-pagination"></use>
            </svg>
        </button>

        <ul class="pagination-list">
            <li class="pagination-list__item" [attr.current]="paginationInfo.number === 0" *ngIf="paginationInfo.number >= 0" (click)="goToPage(0, null)">1</li>
            <ng-template [ngIf]="(paginationInfo.number <= 3 && paginationInfo.totalPages !== 1) || (paginationInfo.totalPages <= 6 && paginationInfo.totalPages !== 1)">
                <li class="pagination-list__item" *ngIf="paginationInfo.totalPages >= 2" [attr.current]="paginationInfo.number === 1" (click)="goToPage(1, null)">2</li>
                <li class="pagination-list__item" *ngIf="paginationInfo.totalPages >= 3" [attr.current]="paginationInfo.number === 2" (click)="goToPage(2, null)">3</li>
                <li class="pagination-list__item" *ngIf="paginationInfo.totalPages >= 4" [attr.current]="paginationInfo.number === 3" (click)="goToPage(3, null)">4</li>
            </ng-template>
            <ng-template [ngIf]="paginationInfo.number <= 5 && paginationInfo.totalPages >= 5 && paginationInfo.totalPages <= 6">
                <li class="pagination-list__item" *ngIf="paginationInfo.totalPages >= 5" [attr.current]="paginationInfo.number === 4" (click)="goToPage(4, null)">5</li>
                <li class="pagination-list__item" *ngIf="paginationInfo.totalPages === 6" [attr.current]="paginationInfo.number === 5" (click)="goToPage(5, null)">6</li>
            </ng-template>

            <li *ngIf="paginationInfo.number > 3 && paginationInfo.totalPages > 6" class="pagination-list__item" (click)="goToBackThreePage()">...</li>
            <li *ngFor="let page of pageNumbers" class="pagination-list__item hidden" [attr.current]="paginationInfo.number === page - 1" [class.active]="page - 1 === paginationInfo.number && paginationInfo.number > 3 && paginationInfo.number < paginationInfo.totalPages-3 && paginationInfo.totalPages > 6" (click)="goToPage(page - 1, null)">{{ page }}</li>
            <li *ngIf="(paginationInfo.number < paginationInfo.totalPages - 3 && paginationInfo.totalPages >= 7) || (paginationInfo.number >= 4 && paginationInfo.number <= paginationInfo.totalPages - 3 && paginationInfo.totalPages === 7)" class="pagination-list__item" (click)="goToAheadThreePage()">...</li>
            <ng-template [ngIf]="paginationInfo.number >= paginationInfo.totalPages-3 && paginationInfo.totalPages >= 7 && paginationInfo.number !== 3">
                <li class="pagination-list__item" *ngIf="paginationInfo.totalPages-3 > 4" [attr.current]="paginationInfo.number === paginationInfo.totalPages-4" (click)="goToPage(paginationInfo.totalPages-4, 'back')">{{paginationInfo.totalPages-3}}</li>
                <li class="pagination-list__item" [attr.current]="paginationInfo.number === paginationInfo.totalPages-3" (click)="goToPage(paginationInfo.totalPages-3, 'back')">{{paginationInfo.totalPages-2}}</li>
                <li class="pagination-list__item" [attr.current]="paginationInfo.number === paginationInfo.totalPages-2" (click)="goToPage(paginationInfo.totalPages-2, 'back')">{{paginationInfo.totalPages-1}}</li>
            </ng-template>
            <li class="pagination-list__item" *ngIf="paginationInfo.number <= paginationInfo.totalPages && paginationInfo.totalPages >= 7" [attr.current]="paginationInfo.number === paginationInfo.totalPages - 1" (click)="goToPage(paginationInfo.totalPages - 1, 'forward')">{{paginationInfo.totalPages}}</li>
        </ul>

        <button type="button" id="btnNextPage" class="btn-next" *ngIf="!paginationInfo.last" [disabled]="isLoading" (click)="goToPage(paginationInfo.number + 1, 'forward')">
            <svg class="next-page-arrow-icon">
                <use spriteVersion iconName="arrow-pagination"></use>
            </svg>
        </button>
    </div>

  <div class="pagination-wrapper mobile">
    <button type="button" id="btnPrevPageMobile" class="btn-previous" *ngIf="!paginationInfo.first" (click)="goToPage(paginationInfo.number - 1, 'back')">
      <svg class="prev-page-arrow-icon">
        <use spriteVersion iconName="arrow-pagination"></use>
      </svg>
    </button>

    <ul class="pagination-list">
      <li *ngFor="let page of pageNumbers" class="pagination-list__item hidden" [attr.current]="paginationInfo.number === page - 1" [class.active]="page - 1 === paginationInfo.number" (click)="goToPage(page - 1, null)">{{ page }}</li>
    </ul>

    <button type="button" id="btnNextPageMobile" class="btn-next" *ngIf="!paginationInfo.last" (click)="goToPage(paginationInfo.number + 1, 'forward')">
      <svg class="next-page-arrow-icon">
        <use spriteVersion iconName="arrow-pagination"></use>
      </svg>
    </button>
  </div>
</div>
