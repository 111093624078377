<app-filter-fields btnText="Add Material" searchPlaceholder="Search by ID, Tag, or Supplier" [disabled]="materialsPage?.empty && isNoFilteringEnabled()" (openFormBtnClick)="materialFormOpened = true"
                   (inputChanged)="query = $event; getMaterialsList()"
                   [filtersAmount]="getFiltersActiveAmount()"
                   (deleteSelectedItem)="deletingPopupOpened = true"
                   (cancelSelectedItem)="multiSelectedMaterials = []; isMultiSelectAll = false"
                   [selectedItemsAmount]="multiSelectedMaterials.length"
                   (clearAllFilters)="clearFilters()" (export)="export()">

  <div class="filter-field-wrapper">
    <label class="filter-label">
      Filter by Submittal status:
    </label>
    <div class="ng-select-wrapper">
      <app-material-custom-selector-with-colored-options
        [statusesMap]="submittalStatusByEnum"
        [selectedOption]="{value: selectedSubmittalStatus || null, isPlaceholder: !selectedSubmittalStatus}"
        [isClearFieldBtn]="!!selectedSubmittalStatus"
        (changeEl)="changeStatusInFilters($event, 'submittalStatus'); getMaterialsList()">
      </app-material-custom-selector-with-colored-options>
    </div>
  </div>
  <div class="filter-field-wrapper">
    <label class="filter-label">
      Filter by Order Status:
    </label>
    <div class="ng-select-wrapper">
      <app-material-custom-selector-with-colored-options
        [statusesMap]="orderStatusByEnum"
        [selectedOption]="{value: selectedOrderStatus || null, isPlaceholder: !selectedOrderStatus}"
        [isClearFieldBtn]="!!selectedOrderStatus"
        (changeEl)="changeStatusInFilters($event, 'orderStatus'); getMaterialsList()">
      </app-material-custom-selector-with-colored-options>
    </div>
  </div>

  <div class="filter-field-wrapper">
    <label class="filter-label">
      Filter by Payment Status:
    </label>
    <div class="ng-select-wrapper">
      <app-material-custom-selector
        [elementList]="paymentStatusesList"
        [selectedOption]="selectedPaymentStatus"
        [isOptional]="true"
        [isDeletable]="false"
        [isClearFieldBtn]="!!selectedPaymentStatus"
        [id]="'paymentStatus'"
        [placeholder]="'Select Payment status'"
        [noOptionFind]="'No Payment status'"
        (changeEl)="changeStatusInFilters($event, 'paymentStatus'); getMaterialsList()"
        (close)="closeEditableSelectorInFilters()">
      </app-material-custom-selector>
    </div>
  </div>
  <button class="clear-all-filters-btn" (click)="clearFilters()">Clear all filters</button>
</app-filter-fields>
<ng-scrollbar *ngIf="materialsPage && !isLoading" externalViewport [attr.isIOS]="(isIOS | async)" [attr.pagination-showed]="this.materialsPage.totalElements > 20 && !(isMobile | async)" [autoHeightDisabled]="true" [track]="'all'" class="table-wrapper-with-dots">
  <div class="innerScrollBarWrapper">
    <div [attr.pagination-showed]="this.materialsPage.totalElements > 20" class="table-wrapper-with-scroll">
      <div class="table-materials" *ngIf="!materialsPage.empty; else emptyList" >
        <ul class="table-materials__header-row">
          <li class="table-materials__multiselected" *ngIf="multiSelectEnabled && multiSelectedMaterials.length > 0">
            <div class="checkbox-container">
              <label class="checkbox-label">
                <input type="checkbox" [checked]="isMultiSelectAll" (change)="toggleCheckAll()" [id]="'selectItemCheckbox'" class="visually-hidden"/>
                <span class="checkbox"></span>
              </label>
            </div>
          </li>
          <li class="table-materials__id-title fixed-left-column" [attr.isMultiselect]="multiSelectEnabled && multiSelectedMaterials.length > 0">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['id']">
              ID
              <button class="table-sort-icon-btn" (click)="onSortBy('id')" [style.opacity]="sorting['id'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['id'] === SortType.DESC" *ngIf="sorting['id']; else grayicon">
                 <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                     <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>
          <li class="table-materials__name-title fixed-left-column-name" [attr.isMultiselect]="multiSelectEnabled && multiSelectedMaterials.length > 0">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['tag']">
              Tag
              <button class="table-sort-icon-btn" (click)="onSortBy('tag')" [style.opacity]="sorting['tag'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['tag'] === SortType.DESC" *ngIf="sorting['tag']; else grayicon">
                   <use spriteVersion iconName="sort-icon-up"></use>
                </svg>
                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-materials__description fixed-left-column-description"
              [attr.isMultiselect]="multiSelectEnabled && multiSelectedMaterials.length > 0">
            Description
          </li>

          <li class="table-materials__colored-status">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['submittalStatus']">
              Submittal Status
              <button class="table-sort-icon-btn" (click)="onSortBy('submittalStatus')" [style.opacity]="sorting['submittalStatus'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['submittalStatus'] === SortType.DESC" *ngIf="sorting['submittalStatus']; else grayicon">
                  <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-materials__colored-status">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['orderStatus']">
              Order Status
              <button class="table-sort-icon-btn" (click)="onSortBy('orderStatus')" [style.opacity]="sorting['orderStatus'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['orderStatus'] === SortType.DESC" *ngIf="sorting['orderStatus']; else grayicon">
                  <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-materials__unit">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['unit.name']">
              Unit
              <button class="table-sort-icon-btn" (click)="onSortBy('unit.name')" [style.opacity]="sorting['unit.name'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['unit.name'] === SortType.DESC" *ngIf="sorting['unit.name']; else grayicon">
                  <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-materials__supplier">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['supplier']">
              Supplier
              <button class="table-sort-icon-btn" (click)="onSortBy('supplier')" [style.opacity]="sorting['supplier'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['supplier'] === SortType.DESC" *ngIf="sorting['supplier']; else grayicon">
                  <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-materials__area">Area</li>

          <li class="table-materials__eta-title">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['eta']">
              ETA
              <button class="table-sort-icon-btn" (click)="onSortBy('eta')" [style.opacity]="sorting['eta'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['eta'] === SortType.DESC" *ngIf="sorting['eta']; else grayicon">
                  <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-materials__grossQty">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['grossQty']">
              Gross Qty
              <button class="table-sort-icon-btn" (click)="onSortBy('grossQty')" [style.opacity]="sorting['grossQty'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['grossQty'] === SortType.DESC" *ngIf="sorting['grossQty']; else grayicon">
                  <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-materials__orderQty">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['orderQty']">
              Order Qty
              <button class="table-sort-icon-btn" (click)="onSortBy('orderQty')" [style.opacity]="sorting['orderQty'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['orderQty'] === SortType.DESC" *ngIf="sorting['orderQty']; else grayicon">
                  <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-materials__orderDate">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['orderDate']">
              Order Date
              <button class="table-sort-icon-btn" (click)="onSortBy('orderDate')" [style.opacity]="sorting['orderDate'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['orderDate'] === SortType.DESC" *ngIf="sorting['orderDate']; else grayicon">
                  <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-materials__paymentStatus">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['paymentStatus.name']">
              Payment Status
              <button class="table-sort-icon-btn" (click)="onSortBy('paymentStatus.name')" [style.opacity]="sorting['paymentStatus.name'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['paymentStatus.name'] === SortType.DESC" *ngIf="sorting['paymentStatus.name']; else grayicon">
                  <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-materials__receivedQty">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['receivedQty']">
              Received Qty
              <button class="table-sort-icon-btn" (click)="onSortBy('receivedQty')" [style.opacity]="sorting['receivedQty'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['receivedQty'] === SortType.DESC" *ngIf="sorting['receivedQty']; else grayicon">
                  <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-materials__storedLocation">Stored Location</li>
          <li class="table-materials__notes">Notes</li>
          <li class="table-materials__actions no-right-border fixed-column" *ngIf="currentUser.role !== UserRole.ROLE_EMPLOYEE"></li>
        </ul>

        <ul class="table-body-list">
          <li *ngFor="let material of materialsPage.content"
              [id]="material.id" class="table-materials__row"
              [attr.multiselected]="multiSelectedMaterials.includes(material.id)"
              [ngStyle]="multiSelectedMaterials.includes(material.id) ? {'background-color': '#F7F7F8'} : {}">
            <ul class="item-info-list">
              <li class="material-id multiselect-cell" *ngIf="multiSelectEnabled && multiSelectedMaterials.length > 0">
                <div class="checkbox-container">
                  <label class="checkbox-label">
                    <input type="checkbox" [checked]="multiSelectedMaterials.includes(material.id)"
                           (change)="toggleCheck(material.id)" id="selectItemCheckbox" class="visually-hidden"/>
                    <span class="checkbox"></span>
                  </label>
                </div>
              </li>
              <li class="material-id fixed-left-column"
                  stopClickPropagation
                  [attr.isClickable]="currentUser.role !== UserRole.ROLE_EMPLOYEE"
                  [attr.isMultiselect]="multiSelectEnabled && multiSelectedMaterials.length > 0"
                  (click)="currentUser.role !== UserRole.ROLE_EMPLOYEE ? openEditMaterialForm(material) : null"
                  [ngStyle]="multiSelectedMaterials.includes(material.id) ? {'background-color': '#F7F7F8'} : {}">
                  <span class="item-code-way">
                    <span class="list-name-wrapper">
                      {{material.id}}
                    </span>
                  </span>
              </li>
              <li class="material-name fixed-left-column-name"
                  stopClickPropagation
                  [attr.isClickable]="currentUser.role !== UserRole.ROLE_EMPLOYEE"
                  [attr.isMultiselect]="multiSelectEnabled && multiSelectedMaterials.length > 0"
                  (click)="currentUser.role !== UserRole.ROLE_EMPLOYEE ? openEditMaterialForm(material) : null"
                  [ngStyle]="multiSelectedMaterials.includes(material.id) ? {'background-color': '#F7F7F8'} : {}">
                  <span ellipsis>{{material.tag}}</span>

                  <div class="material-file-with-description-icon">
                    <svg class="attachment-icon" *ngIf="material?.submittalFiles.length !== 0 || material.purchasingFiles.length !== 0">
                      <use spriteVersion iconName="attachment"></use>
                    </svg>
                  </div>
              </li>

              <li class="material-description fixed-left-column-description" [attr.isMultiselect]="multiSelectEnabled && multiSelectedMaterials.length > 0"
                  [ngStyle]="multiSelectedMaterials.includes(material.id) ? {'background-color': '#F7F7F8'} : {}">
                <ng-container *ngIf="material.description">
                  <span ellipsis>{{material.description}}</span>
                </ng-container>
                <ng-container *ngIf="!material.description">-</ng-container>
              </li>

              <li class="material-colored-status-td" #materialSubmittalStatus
                  [enabled]="materialSubmittalStatus.hasAttribute('openMenu')"
                  (appOutsideClick)="materialSubmittalStatus.removeAttribute('openMenu')">
                <button class="material-status-btn"
                        [attr.isClickable]="currentUser.role !== UserRole.ROLE_EMPLOYEE"
                        [attr.isSubmittal]="'true'"
                        [attr.status]="Array.from(submittalStatusByEnum.keys()).indexOf(material.submittalStatus)"
                        (click)="materialSubmittalStatus.toggleAttribute('openMenu'); materialOrderStatusRef.nativeElement.removeAttribute('openMenu')">
                  {{submittalStatusByEnum.get(material.submittalStatus)}}
                  <svg
                    *ngIf="Array.from(submittalStatusByEnum.keys()).indexOf(material.submittalStatus) !== 3 && Array.from(submittalStatusByEnum.keys()).indexOf(material.submittalStatus) !== 5 && currentUser.role !== UserRole.ROLE_EMPLOYEE"
                       class="material-status-icon-dropdown">
                      <use spriteVersion iconName="arrow-task-status"></use>
                  </svg>
                  <svg *ngIf="(Array.from(submittalStatusByEnum.keys()).indexOf(material.submittalStatus) === 3 || Array.from(submittalStatusByEnum.keys()).indexOf(material.submittalStatus) === 5) && currentUser.role !== UserRole.ROLE_EMPLOYEE"
                       class="material-status-icon-dropdown">
                    <use spriteVersion iconName="materials-status-icon-dropdown"></use>
                  </svg>
                </button>
                <ul class="material-status-menu" #materialSubmittalStatusList
                    [@modalAnimation]
                    *ngIf="materialSubmittalStatus.hasAttribute('openMenu')"
                    (isLowerThenMiddleOfScreen)="materialSubmittalStatusList.style.top='-' + materialSubmittalStatusList.offsetHeight + 'px'">
                  <li class="material-status-menu__item"
                      *ngFor="let status of submittalStatusByEnum | keyvalue : originalOrder; let idx = index"
                      (click)="updateMaterialStatus(material, status.key, 'submittalStatus');
                      materialSubmittalStatus.removeAttribute('openMenu')">
                    <p class="material-status-menu__text"  [attr.isSubmittal]="'true'" [attr.status]="idx">{{status.value}}</p>
                    <span class="material-status-menu-selected" *ngIf="material.submittalStatus === status.key"></span>
                  </li>
                </ul>
              </li>

              <li class="material-colored-status-td" #materialOrderStatus
                  [enabled]="materialOrderStatus.hasAttribute('openMenu')"
                  (appOutsideClick)="materialOrderStatus.removeAttribute('openMenu')">
                <button class="material-status-btn"
                        [attr.isClickable]="currentUser.role !== UserRole.ROLE_EMPLOYEE"
                        [attr.status]="Array.from(orderStatusByEnum.keys()).indexOf(material.orderStatus)"
                        (click)="materialOrderStatus.toggleAttribute('openMenu'); materialSubmittalStatus.removeAttribute('openMenu')">
                  {{orderStatusByEnum.get(material.orderStatus)}}
                  <svg *ngIf="Array.from(orderStatusByEnum.keys()).indexOf(material.orderStatus) !== 4 && currentUser.role !== UserRole.ROLE_EMPLOYEE"
                       class="material-status-icon-dropdown">
                    <use spriteVersion iconName="arrow-task-status"></use>
                  </svg>
                  <svg *ngIf="Array.from(orderStatusByEnum.keys()).indexOf(material.orderStatus) === 4 && currentUser.role !== UserRole.ROLE_EMPLOYEE"
                       class="material-status-icon-dropdown">
                    <use spriteVersion iconName="materials-status-icon-dropdown"></use>
                  </svg>
                </button>
                <ul class="material-status-menu" #materialOrderStatusList
                    [@modalAnimation]
                    *ngIf="materialOrderStatus.hasAttribute('openMenu')"
                    (isLowerThenMiddleOfScreen)="materialOrderStatusList.style.top='-' + materialOrderStatusList.offsetHeight + 'px'">
                  <li class="material-status-menu__item" *ngFor="let status of orderStatusByEnum | keyvalue : originalOrder; let idx = index"
                      (click)="updateMaterialStatus(material, status.key, 'orderStatus');
                      materialOrderStatus.removeAttribute('openMenu')">
                    <p class="material-status-menu__text" [attr.status]="idx">{{status.value}}</p>
                    <span class="material-status-menu-selected" *ngIf="material.orderStatus === status.key"></span>
                  </li>
                </ul>
              </li>

              <li class="material-unit" [attr.isMultiselect]="multiSelectEnabled && multiSelectedMaterials.length > 0"
                  [ngStyle]="multiSelectedMaterials.includes(material.id) ? {'background-color': '#F7F7F8'} : {}">
                <div class="material-name__wrapper">
                  <div class="list-name-wrapper">
                    <span>{{material.unit ? material.unit.name : '-'}}</span>
                  </div>
                </div>
              </li>

              <li class="material-supplier" [attr.isMultiselect]="multiSelectEnabled && multiSelectedMaterials.length > 0"
                  [ngStyle]="multiSelectedMaterials.includes(material.id) ? {'background-color': '#F7F7F8'} : {}">
                  <span ellipsis>{{material.supplier}}</span>
              </li>

              <li class="material-area" [attr.isMultiselect]="multiSelectEnabled && multiSelectedMaterials.length > 0"
                  [ngStyle]="multiSelectedMaterials.includes(material.id) ? {'background-color': '#F7F7F8'} : {}">
                  <span ellipsis>{{material.area}}</span>
              </li>

              <li class="material-eta">
                <div class="date-box">
                  <ng-container *ngIf="material.eta">
                    <svg class="calendar-icon-table">
                      <use spriteVersion iconName="calendar"></use>
                    </svg>
                    {{material.eta | date: 'MM.dd.YY'}}
                  </ng-container>
                  <ng-container *ngIf="!material.eta">-</ng-container>
                </div>
              </li>

              <li class="material-grossQty">
                <ng-container *ngIf="material.grossQty">
                  {{material.grossQty}}
                </ng-container>
                <ng-container *ngIf="!material.grossQty">-</ng-container>
              </li>

              <li class="material-orderQty">
                <ng-container *ngIf="material.orderQty">
                  {{material.orderQty}}
                </ng-container>
                <ng-container *ngIf="!material.orderQty">-</ng-container>
              </li>

              <li class="material-orderDate">
                <div class="date-box">
                  <ng-container *ngIf="material.orderDate">
                    <svg class="calendar-icon-table">
                      <use spriteVersion iconName="calendar"></use>
                    </svg>
                    {{material.orderDate | date: 'MM.dd.YY'}}
                  </ng-container>
                  <ng-container *ngIf="!material.orderDate">-</ng-container>
                </div>
              </li>

              <li class="material-paymentStatus">
                <ng-container *ngIf="material.paymentStatus">
                  <span>{{material.paymentStatus.name}}</span>
                </ng-container>
                <ng-container *ngIf="!material.paymentStatus">-</ng-container>
              </li>

              <li class="material-receivedQty">
                <ng-container *ngIf="material.receivedQty">
                  {{material.receivedQty}}
                </ng-container>
                <ng-container *ngIf="!material.receivedQty">-</ng-container>
              </li>

              <li class="material-storedLocation">
                <ng-container *ngIf="material.storedLocation">
                  <span>{{material.storedLocation.name}}</span>
                </ng-container>
                <ng-container *ngIf="!material.storedLocation">-</ng-container>
              </li>

              <li class="material-notes">
                <ng-container *ngIf="material.notes">
                  <span ellipsis>{{material.notes}}</span>
                </ng-container>
                <ng-container *ngIf="!material.notes">-</ng-container>
              </li>

              <li tabindex="1" class="dots-actions fixed-column" id="dotsMenu" #taskDots
                  *ngIf="currentUser.role !== UserRole.ROLE_EMPLOYEE"
                  (click)="taskDots.toggleAttribute('editMenuOpen')"
                  [enabled]="taskDots.hasAttribute('editMenuOpen')"
                  (appOutsideClick)="taskDots.removeAttribute('editMenuOpen')">
                <ul class="editMenuList" #editMenuList
                    [attr.editMenuListOffsetHeight]="editMenuList.offsetHeight"
                    (isLowerThenMiddleOfScreen)="editMenuList.style.top='-' + editMenuList.offsetHeight + 'px'"
                    [@modalAnimation] *ngIf="taskDots.hasAttribute('editMenuOpen')">
                  <li class="editMenuList__item" id="selectItem" (click)="toggleCheck(material.id)">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="select-item-icon"></use>
                    </svg>
                    Select
                  </li>
                  <li class="editMenuList__item" id="editItem" (click)="selectedMaterial = material; materialFormOpened = true">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="edit-icon"></use>
                    </svg>
                    Edit
                  </li>
                  <li class="editMenuList__item" id="deleteItem" (click)="selectedMaterial = material; deletingPopupOpened = true">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="trash-delete-icon"></use>
                    </svg>
                    Delete
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <app-pagination *ngIf="this.materialsPage.totalElements > 20 && (isMobile | async)"
                          [isLoading]="!materialsPage"
                          [paginationInfo]="this.materialsPage"
                          [showAmountForMobile]="pageSizeOnMobile"
                          (onChangeShowNumber)="changeShowNumber($event)"
                          (changePage)="changeCurrentPage($event)">
          </app-pagination>
        </ul>
      </div>
    </div>

  </div>
</ng-scrollbar>

<div *ngIf="!materialsPage || isLoading" class="table-wrapper-with-dots loading">
  <app-loader></app-loader>
</div>


<app-pagination *ngIf="this.materialsPage?.totalElements > 20 && !(isMobile | async)"
                [showAmountForDesk]="pageSizeOnDesktop"
                [isLoading]="!materialsPage"
                [paginationInfo]="this.materialsPage"
                (onChangeShowNumber)="changeShowNumber($event)"
                (changePage)="changeCurrentPage($event)">
</app-pagination>


<ng-template #emptyList>
  <div class="empty-list-wrapper">
    <p class="empty-list-text" *ngIf="isNoFilteringEnabled() && materialsPage.empty; else notFound">The list of materials is empty.</p>
    <ng-template #notFound>
      <p class="empty-list-text">No Materials found</p>
    </ng-template>
    <img class="empty-list-icon" src="assets/images/empty-list.png" alt="empty-list">
  </div>
</ng-template>

<div class="backdrop manual" [attr.show]="deletingPopupOpened">
  <div [enabled]="deletingPopupOpened" (appOutsideClick)="closeDeletingPopup()" class="modal-narrow">
    <button type="button" id="btnCloseModal" class="close-modal-btn close-modal-deleting" (click)="closeDeletingPopup()">
      <svg class="close-modal-icon">
        <use spriteVersion iconName="modal-close"></use>
      </svg>
    </button>

    <div class="confirmDeleting-wrapper">
      <p class="confirmDeleting-title" *ngIf="selectedMaterial">Delete the Material “{{selectedMaterial.tag}}”?</p>
      <p class="confirmDeleting-title" *ngIf="!selectedMaterial">Delete All Selected Materials?</p>
    </div>

    <div class="form-btn-wrapper">
      <button type="button" id="btnCancel" class="btn-cancel" (click)="closeDeletingPopup()">Cancel</button>
      <button type="submit" id="btnDeleteTask" class="btn-delete" [disabled]="deletingBtnDisabled" (click)="selectedMaterial ? deleteMaterial(selectedMaterial.id) : deleteSelected(); closeDeletingPopup();">
        <svg class="btn-delete__icon">
          <use spriteVersion iconName="trash-delete-icon"></use>
        </svg>
        Delete {{selectedMaterial ?  'Material':  'Materials'}}
      </button>
    </div>
  </div>
</div>

<app-project-material-form *ngIf="materialFormOpened" (close)="materialFormOpened = false; selectedMaterial = undefined" (created)="materialFormOpened = false; selectedMaterial = undefined; getMaterialsList()" [material]="selectedMaterial"></app-project-material-form>
