<div class="backdrop">
    <div class="modal">
        <form class="form-invite form-change-password" #form="ngForm" firstInputAutofocus scrollToFirstInvalidInput [formGroup]="userChangePasswordForm" (ngSubmit)="submitForm()">
            <button type="button" id="btnClose" class="close-modal-btn" (click)="close.emit()">
                <svg class="close-modal-icon">
                    <use spriteVersion iconName="modal-close"></use>
                </svg>
            </button>
            <div class="form-inner-wrapper">
              <p class="form-modal-title">Change Password</p>

              <div class="main-fields-wrapper">
                <p class="main-error" *ngIf="userChangePasswordForm.invalid && form.submitted" id="mainError">
                    <svg class="error-icon">
                        <use spriteVersion iconName="error-icon"></use>
                    </svg>
                    {{userChangePasswordForm.getError('error') || 'All fields are required'}}
                </p>

                <div class="field-wrapper">
                  <label class="form-label">Current Password</label>
                  <div class="input-with-error-wrapper">
                    <span class="btn-show-with-input-wrapper">
                      <input formControlName="currentPassword" placeholder="Enter Current Password" autocomplete="current-password" formInput="PASSWORD" [type]="isShowCurrentPassword ? 'text' : 'password'" id="currentPassword" class="form-input"/>
                      <button class="btn-show" (click)="showCurrentPassword()" type="button">
                        <svg class="show-password-icon" [class.black-icon]="userChangePasswordForm.controls['currentPassword'].value !== ''">
                          <use [attr.href]="'assets/sprite.svg#' + (isShowCurrentPassword ? 'hide-password' : 'show-password')"></use>
                        </svg>
                      </button>
                    </span>
                    <span class="error-message" id="currentPasswordError" *ngIf="userChangePasswordForm.controls['currentPassword'].invalid && form.submitted">{{userChangePasswordForm.controls['currentPassword'].getError('error')}}</span>
                  </div>
                </div>

                <div class="field-wrapper">
                  <label class="form-label">New Password</label>
                  <div class="input-with-error-wrapper">
                    <span class="btn-show-with-input-wrapper">
                      <input formControlName="newPassword" autocomplete="new-password" placeholder="Enter New Password" formInput="PASSWORD"
                             [type]="isShowPassword ? 'text' : 'password'" id="newPassword" class="form-input"/>
                      <button class="btn-show" (click)="showPassword()" type="button">
                        <svg class="show-password-icon" [class.black-icon]="userChangePasswordForm.controls['newPassword'].value !== ''">
                          <use [attr.href]="'assets/sprite.svg#' + (isShowPassword ? 'hide-password' : 'show-password')"></use>
                        </svg>
                      </button>
                    </span>
                    <span class="error-message" id="newPasswordError" *ngIf="userChangePasswordForm.controls['newPassword'].invalid && form.submitted">{{userChangePasswordForm.controls['newPassword'].getError('error')}}</span>
                  </div>
                </div>

                <div class="field-wrapper">
                  <label class="form-label">Confirm Password</label>
                  <div class="input-with-error-wrapper">
                    <span class="btn-show-with-input-wrapper">
                      <input formControlName="confirmPassword" autocomplete="new-password" placeholder="Confirm Password" formInput="PASSWORD"
                             [type]="isShowConfirmPassword ? 'text' : 'password'" id="confirmPassword" class="form-input"/>
                      <button class="btn-show" (click)="showConfirmPassword()" type="button">
                        <svg class="show-password-icon" [class.black-icon]="userChangePasswordForm.controls['confirmPassword'].value !== ''">
                          <use [attr.href]="'assets/sprite.svg#' + (isShowConfirmPassword ? 'hide-password' : 'show-password')"></use>
                        </svg>
                      </button>
                    </span>
                    <span class="error-message" id="confirmPasswordErrorRequired" *ngIf="userChangePasswordForm.controls['confirmPassword'].invalid && form.submitted">{{userChangePasswordForm.controls['confirmPassword'].getError('error')}}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-btn-wrapper">
                <button type="button" class="btn-cancel" id="btnCancel" (click)="close.emit()">Cancel</button>
                <button type="submit" loader="white" [disabled]="submitBtnDisabled" [showLoader]="submitBtnDisabled" class="btn-invite" id="btnSave">Save</button>
            </div>
        </form>
    </div>
</div>

