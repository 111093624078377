<app-sub-header></app-sub-header>

<div class="system-error__container">
    <div class="system-error__wrapper">
        <svg class="system-error__building-svg">
            <use spriteVersion iconName="building-system-error"></use>
        </svg>
        <h2 class="system-error__title">That page doesn’t exist</h2>
        <p class="system-error__desc">The page you were looking for appears to have been moved, deleted, does not exist or you don’t have access to it.</p>
        <a class="system-error__link" id="linkToHomepage" routerLink="">Home Page</a>
    </div>
</div>

