import {Component, OnDestroy, OnInit} from '@angular/core';
import {TasksService} from "../../../../../_services/tasks.service";
import {Page} from "../../../../../_models/page";
import {Task, TaskPriority} from "../../../../../_models/task";
import {User} from "../../../../../_models/user";
import {SortType, taskStatusByEnum} from "../../../../../config/constants";
import {GlobalStore} from "../../../../../global.store";
import {Subject, Subscription, take, takeUntil} from "rxjs";
import {DashboardStore} from "../dashboard.store";

@Component({
  selector: 'app-dashboard-tasks-table',
  templateUrl: './dashboard-tasks-table.component.html',
  styleUrls: ['./dashboard-tasks-table.component.scss']
})
export class DashboardTasksTableComponent implements OnInit, OnDestroy {
  sorting: any = {};
  tasksPage: Page<Task> = new Page<Task>();
  isLoadingTasks = false;
  page = 0;
  pageSize = 10;
  isIOS = this.globalStore.isIOS$;
  currentUser: User;

  companyId: number;

  private readonly destroy$ = new Subject<void>()

  constructor(private taskService: TasksService,
              readonly globalStore: GlobalStore,
              readonly dashboardStore: DashboardStore) {
    this.globalStore.currentUser$.pipe(take(1)).subscribe((user) => this.currentUser = user);
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => this.companyId = companyId);
  }

  ngOnInit() {
    this.getTasksList()
  }

  getTasksList() {
    let sortingField;
    let sortingDirection;

    if (Object.keys(this.sorting).length > 0) {
      sortingField = Object.keys(this.sorting)[0];
      sortingDirection = this.sorting[Object.keys(this.sorting)[0]];
    }
    this.isLoadingTasks = true;

    this.taskService.getTasksListByParams(this.companyId, null, true, false, this.currentUser.id, null, null, sortingField, sortingDirection, this.page, this.pageSize).subscribe(data => {
      if (data.totalPages > 0 && this.page + 1 > data.totalPages) {
        this.page = data.totalPages - 1;
        this.getTasksList();
      } else {
        if (this.page === 0) this.tasksPage = data;
        else this.tasksPage = {
          ...data,
          content: [...this.tasksPage.content, ...data.content]
        };
        this.dashboardStore.updateTasksTotalAmount(data.totalElements);
        this.isLoadingTasks = false;
      }
    });
  }


  onSortBy(sortBy: string) {
    this.page = 0;
    if (this.sorting[sortBy] !== undefined) this.sorting[sortBy] = this.sorting[sortBy] === SortType.ASC ? SortType.DESC : SortType.ASC;
    else {
      this.sorting = {};
      this.sorting[sortBy] = SortType.DESC;
    }
    this.getTasksList()
  }

  getNextPage() {
    if (this.tasksPage.last) return;

    this.page += 1;
    this.getTasksList()
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly SortType = SortType;
  protected readonly taskStatusByEnum = taskStatusByEnum;
  protected readonly TaskPriority = TaskPriority;
}
