import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-loader-profile',
  templateUrl: './loader-profile.component.html',
  styleUrls: ['./loader-profile.component.scss']
})
export class LoaderProfileComponent {
  isShort: boolean
  amountRendering = 7
  @Input() set isProjects(value: boolean) {
    if(value === true) {
      this.isShort = true
      this.amountRendering = 3;
    }

  }

}
