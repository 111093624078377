import {
  Component,
  ElementRef,
  Input, OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {User, UserRole} from "../../../../_models/user";
import {NotificationService} from "../../../../_services/notification.service";
import {Notification} from "../../../../_models/notification";
import {Router} from "@angular/router";
import {GlobalStore} from "../../../../global.store";
import {Subject, Subscription, takeUntil} from "rxjs";
import {AuthService} from "../../../../_services/auth.service";

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('notificationContainer') notificationContainer: ElementRef
  @Input() title: string[];

  isSelectUnreadNotif: boolean = false;

  userImg!: string;
  isOpenMenu: boolean = false;
  isNotifSettingsOpen = false;
  isOpenNotifications = false;
  user: User;
  filteredNotificationsList: Notification[] = [];
  isDashboard = false;

  private readonly destroy$ = new Subject<void>()
  constructor(readonly globalStore: GlobalStore,
              public notificationService: NotificationService,
              public router: Router,
              public authService: AuthService) {
    this.globalStore.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => this.user = user);
    this.isDashboard = this.router.routerState.snapshot.url.includes('dashboard')
  }

  ngOnInit() {
    this.getUnreadNotificationsCount()
  }

  openSidebar() {
    document.querySelector('.sidebar-wrapper').setAttribute('isOpenMenu', 'true')
  }
  openProfileDropDown() {
    this.isOpenMenu = !this.isOpenMenu;
  }

  markAsReadNotif(){
    this.notificationService.notificationsList =  this.notificationService.notificationsList.map(notification => {
      if(notification.wasRead === true) {
        notification.delivered = true
        return notification
      }
      return notification
    });

    this.getUnreadNotificationsCount()
    this.filterNotifications()
  }

  notifVisible(notif: Notification, notifIndex: number){
    if (!notif.delivered) {
      this.notificationService.notificationDelivered(notif.id, this.user.id)
      this.notificationService.notificationsList.find(notification => notification.id === notif.id).wasRead = true;
    }
    if (this.notificationService.notificationsList.length - 3 == notifIndex) {
      this.notificationService.getNotifications(this.notificationService.notificationsList[this.notificationService.notificationsList.length - 1].id).subscribe((data) => {
        this.notificationService.notificationsList.push(...data)
      })
    }
  }


  getUnreadNotificationsCount() {
    this.notificationService.getCountUnreadNotifications().subscribe((data) => {
      this.notificationService.unreadNotifCount = data
    })
  }

  filterNotifications() {
    if(this.isSelectUnreadNotif) this.filteredNotificationsList = this.notificationService.notificationsList.filter(notif => notif.delivered === false)
    else this.filteredNotificationsList = this.notificationService.notificationsList;
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly UserRole = UserRole;
}
