<app-filter-fields btnText="Add Document" searchPlaceholder="Search by ID, Name" [disabled]="documentsPage?.empty && isNoFilteringEnabled()" (openFormBtnClick)="documentFormOpened = true"
                   (inputChanged)="query = $event; getDocumentsList()" [filtersAmount]="getFiltersActiveAmount()"
                   (deleteSelectedItem)="deletingPopupOpened = true" (cancelSelectedItem)="multiSelectedDocuments = []; isMultiSelectAll = false" [selectedItemsAmount]="multiSelectedDocuments.length" (clearAllFilters)="clearFilters()">

  <div class="filter-field-wrapper">
    <label class="filter-label">
      Type:
    </label>
    <div class="ng-select-wrapper">
      <app-custom-select
        [elementMap]="documentTypeByEnum"
        [selectedOption]="selectedDocumentType"
        [isClearFieldBtn]="!!selectedDocumentType"
        [id]="'documentType'"
        [placeholder]="'Select Document Type'"
        (changeEl)="changeStatusInFilters($event); getDocumentsList()">
      </app-custom-select>
    </div>
  </div>
  <button class="clear-all-filters-btn" (click)="clearFilters()">Clear all filters</button>
</app-filter-fields>

<ng-scrollbar *ngIf="documentsPage && !isLoading" externalViewport [attr.isIOS]="(isIOS | async)" [attr.pagination-showed]="this.documentsPage.totalElements > 20 && !(isMobile | async)" [autoHeightDisabled]="true" [track]="'all'" class="table-wrapper-with-dots">
  <div class="innerScrollBarWrapper">
    <div [attr.pagination-showed]="this.documentsPage.totalElements > 20" class="table-wrapper-with-scroll">
      <div class="table-document" *ngIf="!documentsPage.empty; else emptyList" >
        <ul class="table-document__header-row">
          <li class="table-document__multiselected" *ngIf="multiSelectEnabled && multiSelectedDocuments.length > 0">
            <div class="checkbox-container">
              <label class="checkbox-label">
                <input type="checkbox" [checked]="isMultiSelectAll" (change)="toggleCheckAll()" [id]="'selectItemCheckbox'" class="visually-hidden"/>
                <span class="checkbox"></span>
              </label>
            </div>
          </li>
          <li class="table-document__id-title fixed-left-column" [attr.isMultiselect]="multiSelectEnabled && multiSelectedDocuments.length > 0">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['id']">
              ID
              <button class="table-sort-icon-btn" (click)="onSortBy('id')" [style.opacity]="sorting['id'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['id'] === SortType.DESC" *ngIf="sorting['id']; else grayicon">
                 <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                     <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>
          <li class="table-document__name-title fixed-left-column-name" [attr.isMultiselect]="multiSelectEnabled && multiSelectedDocuments.length > 0">
            <span class="table-title-wrapper">
              Name
            </span>
          </li>

          <li class="table-document__due-date-title">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['createdAt']">
            Date Added
              <button class="table-sort-icon-btn" (click)="onSortBy('createdAt')" [style.opacity]="sorting['createdAt'] ? 1 : ''">
                <svg class="table-sort" [attr.rotate]="sorting['createdAt'] === SortType.DESC" *ngIf="sorting['createdAt']; else grayicon">
                  <use spriteVersion iconName="sort-icon-up"></use>
                </svg>

                <ng-template #grayicon>
                  <svg class="table-sort">
                    <use spriteVersion iconName="sort-icon-default"></use>
                  </svg>
                </ng-template>
              </button>
            </span>
          </li>

          <li class="table-document__actions no-right-border fixed-column"></li>
        </ul>

        <ul class="table-body-list">
          <li *ngFor="let document of documentsPage.content" [id]="document.id" class="table-document__row" [attr.multiselected]="multiSelectedDocuments.includes(document.id)" [ngStyle]="multiSelectedDocuments.includes(document.id) ? {'background-color': '#F7F7F8'} : {}">
            <ul class="item-info-list">
              <li class="document-id multiselect-cell" *ngIf="multiSelectEnabled && multiSelectedDocuments.length > 0">
                <div class="checkbox-container">
                  <label class="checkbox-label">
                    <input type="checkbox" [checked]="multiSelectedDocuments.includes(document.id)" (change)="toggleCheck(document.id)" id="selectItemCheckbox" class="visually-hidden"/>
                    <span class="checkbox"></span>
                  </label>
                </div>
              </li>
              <li class="document-id fixed-left-column" [attr.isLink]="!!document.link" [attr.isMultiselect]="multiSelectEnabled && multiSelectedDocuments.length > 0" (click)="document.link ? '' : openPreviewDocument(document); $event.stopPropagation()"
                  [ngStyle]="multiSelectedDocuments.includes(document.id) ? {'background-color': '#F7F7F8'} : {}">
                  <span class="item-code-way" *ngIf="!document.link">
                    <span class="list-name-wrapper">
                      {{document.id}}
                    </span>
                  </span>
              </li>
              <li class="document-name fixed-left-column-name" [attr.isMultiselect]="multiSelectEnabled && multiSelectedDocuments.length > 0" (click)="document.link ? openLink(document.link, document.name) : openPreviewDocument(document); $event.stopPropagation()"
                  [ngStyle]="multiSelectedDocuments.includes(document.id) ? {'background-color': '#F7F7F8'} : {}">
                <div class="inner-document-name-with-image-wrapper">
                  <img class="document-image-preview" *ngIf="!document.name && isImageFormat(document.file)" [src]="document.file.fileUrl" alt="file-img">
                  <span *ngIf="!document.name && !isImageFormat(document.file)" class="document-icon-wrapper">
                    <svg width="24" height="24" class="document-icon">
                      <use appDocumentsTypeIcon [documentFile]="document.file"></use>
                    </svg>
                  </span>
                  <span *ngIf="document.name" class="document-icon-wrapper">
                    <svg class="document-icon">
                      <use spriteVersion iconName="new-link-icon"></use>
                    </svg>
                  </span>
                  <div class="document-name__wrapper">
                    <div class="list-name-wrapper">
                      <ng-template [nestedEllipsis]>
                        <span class="ellipsis-text">{{document.name || document.file?.name}}</span>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </li>

              <li class="document-dueDate">
                <div class="date-box">
                  {{getTimeCreatedAtDocument(document.createdAt)}}
                </div>
              </li>
              <li tabindex="1" class="dots-actions fixed-column" id="dotsMenu" #taskDots [attr.isDotsMenuAvailable]="accessToDotsMenu(document)" (click)="taskDots.toggleAttribute('editMenuOpen'); $event.stopPropagation()" [enabled]="taskDots.hasAttribute('editMenuOpen')" (appOutsideClick)="taskDots.removeAttribute('editMenuOpen')">
                <ul  class="editMenuList" #editMenuList [attr.editMenuListOffsetHeight]="editMenuList.offsetHeight" (isLowerThenMiddleOfScreen)="editMenuList.style.top='-' + editMenuList.offsetHeight + 'px'" [@modalAnimation] *ngIf="taskDots.hasAttribute('editMenuOpen')">
                  <li *ngIf="currentUser.role !== UserRole.ROLE_EMPLOYEE" class="editMenuList__item" id="selectItem" (click)="toggleCheck(document.id)">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="select-item-icon"></use>
                    </svg>
                    Select
                  </li>
                  <li *ngIf="document.name" class="editMenuList__item" id="editItem" (click)="documentFormOpened = true; selectedDocument = document">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="edit-icon"></use>
                    </svg>
                    Edit
                  </li>

                  <li *ngIf="!document.name" class="editMenuList__item" id="downloadItem" (click)="downloadFile(document.file.fileUrl, document.file?.name)">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="download"></use>
                    </svg>
                    Download
                  </li>
                  <li *ngIf="currentUser.role !== UserRole.ROLE_EMPLOYEE" class="editMenuList__item" id="deleteItem" (click)="selectedDocument = document; deletingPopupOpened = true">
                    <svg class="editMenu-icon">
                      <use spriteVersion iconName="trash-delete-icon"></use>
                    </svg>
                    Delete
                  </li>
                </ul>
              </li>

            </ul>
          </li>

          <app-pagination *ngIf="this.documentsPage.totalElements > 20 && (isMobile | async)" [isLoading]="!documentsPage" [paginationInfo]="this.documentsPage" [showAmountForMobile]="pageSizeOnMobile" (onChangeShowNumber)="changeShowNumber($event)" (changePage)="changeCurrentPage($event)"></app-pagination>
        </ul>
      </div>
    </div>

  </div>
</ng-scrollbar>

<div *ngIf="!documentsPage || isLoading" class="table-wrapper-with-dots loading">
  <app-loader></app-loader>
</div>


<app-pagination *ngIf="this.documentsPage?.totalElements > 20 && !(isMobile | async)" [showAmountForDesk]="pageSizeOnDesktop" [isLoading]="!documentsPage" [paginationInfo]="this.documentsPage" (onChangeShowNumber)="changeShowNumber($event)" (changePage)="changeCurrentPage($event)"></app-pagination>


<ng-template #emptyList>
  <div class="empty-list-wrapper">
    <p class="empty-list-text" *ngIf="isNoFilteringEnabled() && !hideCompleted && documentsPage.empty; else notFound">The list of documents is empty.</p>
    <ng-template #notFound>
      <p class="empty-list-text">No File found</p>
    </ng-template>
    <img class="empty-list-icon" src="assets/images/empty-list.png" alt="empty-list">
  </div>
</ng-template>

<div class="backdrop manual" [attr.show]="deletingPopupOpened">
  <div [enabled]="deletingPopupOpened" (appOutsideClick)="closeDeletingPopup()" class="modal-narrow">
    <button type="button" id="btnCloseModal" class="close-modal-btn close-modal-deleting" (click)="closeDeletingPopup()">
      <svg class="close-modal-icon">
        <use spriteVersion iconName="modal-close"></use>
      </svg>
    </button>

    <div class="confirmDeleting-wrapper">
      <p class="confirmDeleting-title" *ngIf="selectedDocument">Delete the {{selectedDocument.type === DocumentType.FILE ? 'Document' : 'Link'}} “{{selectedDocument.name || selectedDocument.file.name}}”?</p>
      <p class="confirmDeleting-title" *ngIf="!selectedDocument">Delete All Selected Documents?</p>
    </div>

    <div class="form-btn-wrapper">
      <button type="button" id="btnCancel" class="btn-cancel" (click)="closeDeletingPopup()">Cancel</button>
      <button type="submit" id="btnDeleteTask" class="btn-delete" [disabled]="deletingBtnDisabled" (click)="selectedDocument ? deleteDocument(selectedDocument.id) : deleteSelected(); closeDeletingPopup();">
        <svg class="btn-delete__icon">
          <use spriteVersion iconName="trash-delete-icon"></use>
        </svg>
        Delete {{selectedDocument && selectedDocument.type === DocumentType.FILE  ? 'Document' : selectedDocument?.type === DocumentType.LINK ? 'Link' : 'Documents'}}
      </button>
    </div>
  </div>
</div>

<app-document-form *ngIf="documentFormOpened" [document]="selectedDocument" (close)="documentFormOpened = false; selectedDocument = null" (created)="documentFormOpened = false; selectedDocument = null; getDocumentsList()"></app-document-form>
<app-file-preview *ngIf="selectedDocumentForPreview" [document]="selectedDocumentForPreview" (close)="selectedDocumentForPreview = null"></app-file-preview>

<!--<ngx-doc-viewer-->
<!--  *ngIf="!!selectedDocumentForPreview"-->
<!--  [url]="'https://pdfobject.com/pdf/sample.pdf'"-->
<!--  [viewer]="'pdf'"-->
<!--  [googleCheckContentLoaded]="true"-->
<!--  style="width:100%;height:600px;"-->
<!--&gt;-->
<!--</ngx-doc-viewer>-->
