import {Component, OnDestroy} from '@angular/core';
import {User, UserRole} from "../../../../_models/user";
import {GlobalStore} from "../../../../global.store";
import {DashboardStore} from "./dashboard.store";
import {Subject, Subscription, takeUntil} from "rxjs";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DashboardStore]
})
export class DashboardComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>()
  actualDate: Date = new Date();

  currentUser: User;

  constructor(readonly globalStore: GlobalStore,
              readonly dashboardStore: DashboardStore) {
    this.globalStore.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((user: User) => this.currentUser = user);
  }

  getPartOfTheDay() {
    const hours = this.actualDate.getHours();

    if (hours >= 5 && hours < 12) {
      return 'Good Morning';
    } else if (hours >= 12 && hours < 17) {
      return 'Good Afternoon';
    } else if (hours >= 17 && hours < 21) {
      return 'Good Evening';
    } else {
      return 'Good Night';
    }
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly UserRole = UserRole;
}
