import {Component, OnInit} from '@angular/core';
import {VersionService} from "./_services/getVersion.service";
import {NavigationEnd, Router} from "@angular/router";
import {VersionFromBackendService} from "./_services/version-from-backend.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'colorjob-ui';
  version = '';
  versionFromBack: string;
  isAuthPage = false;
  showBothVersions = false;


  constructor(private versionService: VersionService,
               private route: Router,
              private versionFromBackService: VersionFromBackendService) {
  }

  ngOnInit(): void {
    this.getVersionFromBack()
    this.route.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isAuthPage = event.urlAfterRedirects.includes('login') || event.urlAfterRedirects.includes('forgot-password');
      }
    });

  }

  getVersionFromBack() {
    this.versionFromBackService.getVersion().subscribe(data => {
      this.versionFromBack = data;
      this.getVersionFromFront()
    })
  }

  getVersionFromFront() {
    this.versionService.getVersion().subscribe(data => {
      this.version = data;
      if(this.version !== this.versionFromBack) {
        this.showBothVersions = true;
      }
    });
  }
}

