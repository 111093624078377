import {Directive, ElementRef, Input} from "@angular/core";
import {FORM_ERRORS} from "../_util/validators";

@Directive({
  selector: '[formInput]'
})
export class FormInputDirective {

  @Input('formInput') set inputType(value: "PASSWORD" | "EMAIL" | "NAME" | "LINK_NAME" | "CUSTOM_PLAN_MAX_USERS" | 'FLOOR_UNIT_NAME' | 'ROOM_AREA_NAME' | 'ADDRESS' | 'ZIP_CODE' | 'PROJECT_CODE' |  'PROJECT_NUMBER' | 'TASK_DESCRIPTION') {
    if (value === 'PASSWORD') this.el.nativeElement.addEventListener('keydown.space', (event: any) => event.preventDefault());

    this.el.nativeElement.setAttribute('maxLength', FORM_ERRORS[value].MAX_LENGTH_VALUE);
  }

  constructor(private el: ElementRef) {}
}
