import {Component, EventEmitter, Input, Output} from '@angular/core';
import {User, UserRole} from "../../../../../../_models/user";
import {NotificationService} from "../../../../../../_services/notification.service";
import {modalAnimation} from "../../../../../../config/constants";
import {ProjectsService} from "../../../../../../_services/projects.service";
import {GlobalStore} from "../../../../../../global.store";
import {Subject, takeUntil} from "rxjs";
import {ProjectProgressUserSettings} from "../../../../../../_models/projectProgressUserSettings";
import {ProjectProgressStore} from "../project-progress.store";

@Component({
  selector: 'app-design-settings',
  templateUrl: './design-settings.component.html',
  styleUrls: ['./design-settings.component.scss'],
  animations: [modalAnimation],
})
export class DesignSettingsComponent {
  private readonly destroy$ = new Subject<void>();
  private _designSettings: ProjectProgressUserSettings;
  @Input() currentUser: User;
  @Input()
  set designSettings (value: ProjectProgressUserSettings) {
    if(value) this._designSettings = value
  }
  @Output() close: EventEmitter<any> = new EventEmitter<any>()

  get designSettings() {
    return this._designSettings;
  }

  submitBtnDisabled = false;
  projectId: number;

  constructor(private projectService: ProjectsService,
              private projectProgressStore: ProjectProgressStore,
              public globalStore: GlobalStore,
              private notifService: NotificationService) {
    this.globalStore.projectId$.pipe(takeUntil(this.destroy$)).subscribe(data => this.projectId = data);
  }

  switchNotifications(value: string) {
    switch (value) {
      case 'percentage': {
        this.designSettings.showPercentage = !this.designSettings.showPercentage;
        break
      }
      case 'commentIcons': {
        this.designSettings.showCommentIcons = !this.designSettings.showCommentIcons;
        break
      }
      case 'attachmentIcons': {
        this.designSettings.showAttachmentIcons = !this.designSettings.showAttachmentIcons
        break
      }
      case 'popup': {
        this.designSettings.showPopUp = !this.designSettings.showPopUp;
        break
      }
    }
  }

  submit() {
    this.projectService.updateProjectProgressSettings(this.projectId, this.designSettings).subscribe(data => {
      this.notifService.successNotification('Changes have been saved')
      this.projectProgressStore.loadDesignSettings()
      console.log('Then to do')
      this.close.emit()
    })
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly UserRole = UserRole;
}
