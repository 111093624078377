<div class="ng-select-wrapper">
  <div class="material-unit-btn-wrapper" #materialSelect
       [enabled]="materialSelect.hasAttribute('openMenu')"
       (appOutsideClick)="materialSelect.removeAttribute('openMenu'); closeSelect();">
    <button class="material__unit-btn"
           [id]="id"
           type="button"
           [attr.placeholder]="!selectedOption"
           (click)="materialSelect.toggleAttribute('openMenu')">
      <span>{{selectedOption ? elementMap?.get(selectedOption)?.toString() : placeholder}}</span>
      <svg class="material__unit-icon-dropdown"
           *ngIf="!isClearFieldBtn"
           (click)="toggleDropDownMenu(materialSelect)">
        <use spriteVersion iconName="arrow-select-area-status"></use>
      </svg>
      <svg class="clear-field-btn-icon"
           *ngIf="isClearFieldBtn"
           (click)="clearField()">
        <use spriteVersion iconName="close-icon"></use>
      </svg>
    </button>

    <div class="material__unit-menu-wrapper">
      <ul class="material__unit-menu">
        <li class="material__unit-menu__item"
            *ngFor="let option of elementMap | keyvalue: originalOrder; let idx = index"
            (click)="changeEl.emit(option.key); materialSelect.removeAttribute('openMenu'); closeSelect();">
          <p class="material__unit-menu__text" [attr.selectedOption]="idx === documentTypeFromKeysList.indexOf(selectedOption)">{{option.value}}</p>
        </li>
      </ul>
    </div>
  </div>
</div>
