import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from "@angular/core";

@Directive({
  selector: '[fileDropArea]'
})
export class FileDropAreaDirective {
  @Output() filesDropped: EventEmitter<FileList> = new EventEmitter;

  @Input()
  set inputElement(value: HTMLElement) {
    value.addEventListener('change', (e: any) => this.filesDropped.emit(e.target.files));
  }

  constructor(private el: ElementRef) {}

  @HostListener('dragover', ['$event'])
  @HostListener('dragenter', ['$event'])
  preventDefault(event: Event) {
    event.preventDefault();
  }

  @HostListener('drop', ['$event']) onDrop(event: any) {
    event.preventDefault();

    this.filesDropped.emit(event.dataTransfer.files);
  }
}
