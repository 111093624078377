<ul class="floors-list">
  <ng-container *ngFor="let floor of (projectProgressStore.floors$) | async; trackBy: trackByFloor">
    <li [style.display]="floorsFilter.length == 0 || floorsFilter.includes(floor.id) ? 'flex' : 'none'" class="floors-list__item">
      <p class="floor-cell-name" [title]="floor.name">
        {{floor.name}}</p>
      <app-units-list
        [floor]="floor"
        [amountMocksHorizontal]="amountMocksHorizontal"
        [isNotDesktopScreen]="isNotDesktopScreen"
        [designSettings]="designSettings"
        (areaClick)="areaClick.emit($event)"
        (areaMouseOver)="areaMouseOver.emit($event)"
        (areaMouseLeave)="areaMouseLeave.emit($event)"
      >
      </app-units-list>
    </li>
  </ng-container>
  <li class="floors-list__item floor-mock" *ngFor="let floor of [].constructor(amountMocksVertical)">
    <p class="floor-cell-name"></p>
    <ul class="units-list">
      <li class="units-list__item unit-mock">
        <p class="unit-cell-name"></p>
      </li>
    </ul>
  </li>
</ul>
