<div class="container">
  <div class="login-wrapper">
    <svg class="logo">
      <use spriteVersion  iconName="colorjob-logo"></use>
    </svg>
    <h1 class="auth-title">Welcome to Color Job</h1>

    <form [formGroup]="loginForm" #loginFormEl="ngForm" class="form-login" (ngSubmit)="onSubmit()">
      <p class="main-error" *ngIf="error && submitted" id="mainError">
        <svg class="error-icon">
          <use spriteVersion iconName="error-icon"></use>
        </svg>
        Invalid email or password
      </p>
      <div class="form__field-wrapper">
        <label class="form-label" for="email">Email
          <input formControlName="email" placeholder="Email" autocomplete="email" formInput="EMAIL" type="email" id="email" class="form-input"/>
        </label>
        <span class="error-message" id="emailError" *ngIf="loginFormEl.submitted && loginForm.controls['email'].invalid">{{loginForm.controls['email'].getError('error')}}</span>
      </div>

      <div class="form__field-wrapper">
        <label class="form-label">Password
          <input formControlName="password" autocomplete="password" placeholder="Password" [type]="isShowPassword ? 'text' : 'password'" formInput="PASSWORD" id="password" class="form-input"/>
          <button class="btn-show" (click)="isShowPassword = !isShowPassword;" type="button" id="btnShowPassword">
            <svg class="show-password-icon" [class.black-icon]="loginForm.controls['password'].value !== ''">
              <use spriteVersion [iconName]="isShowPassword ? 'hide-password' : 'show-password'"></use>
            </svg>
          </button>
        </label>
        <span class="error-message" id="passwordError" *ngIf="loginFormEl.submitted && loginForm.controls['password'].invalid">{{loginForm.controls['password'].getError('error')}}</span>
      </div>

      <div class="checkbox-container">
        <label class="checkbox-label">
          <input type="checkbox" formControlName="remember_me" id="rememberMe" class="visually-hidden"/>
          <span class="checkbox"></span>
          <span class="checkbox-text" id="rememberMeError" >Save for future authorizations</span>
        </label>
      </div>
      <button loader="white" [showLoader]="showLoader" class="btn-login" type="submit" id="btn-submit">Log in</button>

      <a class="link" routerLink="/forgot-password" id="linkForgotPassword">Forgot Password?</a>
    </form>
  </div>
</div>
