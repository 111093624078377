import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'fileSizePrettify'
})
export class FileSizePrettifyPipe implements PipeTransform {
  transform(value: number, args?: any): string {
    if (value >= 104857.6) {  // 0.1MB в байтах
      const fileSizeMegabytes = value / 1048576;
      return fileSizeMegabytes.toFixed(2) + "MB";
    } else {
      const fileSizeKilobytes = value / 1024;
      return fileSizeKilobytes.toFixed(2) + "KB";
    }
  }
}
