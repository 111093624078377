import {UserRole} from "../_models/user";
import {CompanyType} from "../_models/company";
import {TaskPriority, TaskStatus} from "../_models/task";
import {KeyValue} from "@angular/common";
import {AreaStatus} from "../_models/area";
import {moveItemInArray} from "@angular/cdk/drag-drop";
import {OrderStatus, SubmittalStatus} from "../_models/material";
import {DocumentType} from "../_models/document";

export function convertUserRoleToEnum(userRole: any): UserRole {
    return UserRole[userRole as unknown as keyof typeof UserRole];
}

export function convertCompanyTypeToEnum(companyType: any): CompanyType {
  return CompanyType[companyType as unknown as keyof typeof CompanyType];
}

export function convertTaskStatusToEnum(taskStatus: any): TaskStatus {
    return TaskStatus[taskStatus as unknown as keyof typeof TaskStatus];
}

export function convertTaskPriorityToEnum(taskPriority: any): TaskPriority {
    return TaskPriority[taskPriority as unknown as keyof typeof TaskPriority];
}

export function convertAreaStatusToEnum(areaStatus: any): AreaStatus {
  return AreaStatus[areaStatus as unknown as keyof typeof AreaStatus];
}

export function convertIdToNumber(input: any): number {
  if (!Number.isNaN(Number.parseInt(input))) return Number.parseInt(input);
  else return 0;
}

export function convertDocumentTypeToEnum(documentType: any): DocumentType {
  return DocumentType[documentType as unknown as keyof typeof DocumentType];
}

export function convertMaterialOrderStatusToEnum(materialOrderStatus: any): OrderStatus {
  return OrderStatus[materialOrderStatus as unknown as keyof typeof OrderStatus];
}

export function convertMaterialSubmittalStatusToEnum(materialSubmittalStatus: any): SubmittalStatus {
  return SubmittalStatus[materialSubmittalStatus as unknown as keyof typeof SubmittalStatus];
}

export const originalOrder = (a: KeyValue<any,any>, b: KeyValue<any,any>): any => {
    return 0;
}

export function waitForElm(selector: string) {
  return new Promise(resolve => {
    if (document.querySelector(selector)) return resolve(document.querySelector(selector));

    const observer = new MutationObserver(mutations => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        resolve(document.querySelector(selector));
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}

export function moveItemInArrayCopyAndUpdateVersion(newOrder: number, oldOrder: number, array: any[]) {
  const updatedArray = [...array];

  moveItemInArray(updatedArray, oldOrder, newOrder);

  for (let i = Math.min(oldOrder, newOrder); i <= Math.max(oldOrder, newOrder); i++) {
    if (updatedArray[i].orderNumber !== i) updatedArray[i].version++;
  }

  return updatedArray;
}

export function getFilenameFromDisposition(contentDisposition: string | null): string {
  let filename = 'download.xlsx';
  if (contentDisposition) {
    const matches = /filename\*=(?:UTF-8'')?([^;]+)|filename="?([^"]+)"?/.exec(contentDisposition);
    if (matches) {
      filename = decodeURIComponent(matches[1] || matches[2]).replace(/['"]/g, '');
    }
  }
  return filename;
}

