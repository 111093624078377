<div class="ng-select-wrapper">
  <div class="material-unit-btn-wrapper" #materialSelect
       [enabled]="materialSelect.hasAttribute('openMenu')"
       (appOutsideClick)="materialSelect.removeAttribute('openMenu'); closeSelect();">
    <input class="form-input-modal"
           [id]="id"
           formInput="NAME"
           [placeholder]="placeholder"
           (input)="searchEl($event)"
           #input
           [value]="inputValue || null"
           (click)="materialSelect.setAttribute('openMenu', 'true')">
    <svg class="material__unit-icon-dropdown"
         *ngIf="!isClearFieldBtn"
         (click)="toggleDropDownMenu(materialSelect)">
      <use spriteVersion iconName="arrow-select-area-status"></use>
    </svg>
    <svg class="clear-field-btn-icon"
         *ngIf="isClearFieldBtn"
         (click)="clearField()">
      <use spriteVersion iconName="close-icon"></use>
    </svg>

    <div class="material__unit-menu-wrapper">
      <ul class="material__unit-menu">
        <li class="material__unit-menu__item"
            *ngFor="let option of filteredElementList"
            (click)="changeEl.emit(option.id); onChange(option.name); materialSelect.removeAttribute('openMenu'); closeSelect();">
          <p class="material__unit-menu__text" [attr.selectedOption]="option?.id === selectedOption?.id">{{option?.name}}</p>
          <svg class="material__unit-icon-delete" *ngIf="isDeletable && option.deletable"
               stopClickPropagation
               (click)="onDeleteOption(option.id)">
            <use spriteVersion iconName="trash-delete-icon"></use>
          </svg>
        </li>

        <li class="material__unit-menu__item" *ngIf="filteredElementList.length === 0">
          <span class="material__unit-menu__text" [attr.noUnitText]="filteredElementList.length === 0">{{ noOptionFind }}</span>
        </li>
      </ul>
      <div class="material-unit-menu-addNew" *ngIf="isDeletable">
        <p class="material__unit-menu__text" (click)="isAddFormOpen = true; materialSelect.removeAttribute('openMenu')">Add New</p>
      </div>
    </div>
  </div>


  <!--            Form create Unit-->
  <form class="form-create-instead-btn"
        firstInputAutofocus
        stopClickPropagation
        #ngFormAdd="ngForm"
        *ngIf="isAddFormOpen"
        (appOutsideClick)="isAddFormOpen = false"
        [enabled]="isAddFormOpen"
        (ngSubmit)="createNew.emit({resetBtn, createForm}); updateSelectedOption()"
        [formGroup]="createForm">
    <input type="text" class="input-create-item" formControlName="name" id="createOptionInputDesk"
           [formInput]="isLongerName ? 'NAME': 'FLOOR_UNIT_NAME'">
    <p class="small-error-text-field" id="unitNameErrorDesk"
       *ngIf="ngFormAdd.submitted && createForm.controls['name'].invalid">{{ createForm.controls['name'].getError('error') }}</p>
    <div class="form-create-btn-wrapper">
      <button type="submit" id="createUnitBtnDesk" class="create-item-btn">Save</button>
      <button type="reset" id="canselBtnCreateUnitDesk" #resetBtn class="cansel-create-btn"
              (click)="this.isAddFormOpen = false; resetBtn.click()">
        <svg class="close-icon-form">
          <use spriteVersion iconName="close-icon"></use>
        </svg>
      </button>
    </div>
  </form>
</div>
