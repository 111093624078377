<div class="filters-wrapper" (appOutsideClick)="closeSelector()" [enabled]="isSelectorOpened">
    <div class="btn-vs-form-wrapper">
        <button id="filter-floors" class="filters-btn" [disabled]="(floors$ | async).length === 0" (click)="toggleDropdown()"
                [attr.amountFilters]="floorFilterCopyForModify.length === 0 ? 0 : ''">
            <svg class="filters-icon">
                <use spriteVersion iconName="sort-icon"></use>
            </svg>
            {{floorFilterCopyForModify.length > 0 ? 'Floors: ' + floorFilterCopyForModify.length : 'Floors'}}
            <svg class="filters-icon close-icon-forCheck" *ngIf="floorFilterCopyForModify.length > 0" (click)="clearAllFilters()" stopClickPropagation>
              <use spriteVersion iconName="close-icon"></use>
            </svg>
        </button>
        <div class="filters-list-wrapper" [attr.visible]="isSelectorOpened">
            <ul class="filters-list">
                <li class="filters-list__item" *ngFor="let floor of floors$ | async; index as i">
                    <div class="checkbox-container">
                        <label class="checkbox-label">
                            <input type="checkbox" [checked]="floorFilterCopyForModify.includes(floor.id)" (change)="toggleCheck(floor.id)" [id]="'selectItemCheckbox-' + i" class="visually-hidden"/>
                            <span class="checkbox"></span>
                        </label>
                    </div>
                    {{floor.name}}
                </li>
            </ul>

            <button type="submit" class="btn-filters" (click)="setFilters()">Apply</button>
        </div>
    </div>
</div>
