import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {apiUrl} from "../../environments/environment";
import {Floor} from "../_models/floor";

@Injectable({
  providedIn: 'root'
})
export class FloorsService {

  constructor(private http: HttpClient) { }


  getFloorsShortList(projectId: number) {
    return this.http.get<Floor[]>(`${apiUrl}/floor/short`, {params: {projectId: projectId}, withCredentials: true})
  }

  getFloorById(floorId: number) {
    return this.http.get<Floor>(`${apiUrl}/floor/${floorId}`, {withCredentials: true})
  }

  createFloor(projectId: number, floorName: string | null, duplicateFloorId: number | null) {
    let params = new HttpParams()
    params =  params.append('projectId', projectId)
    if (floorName) params =  params.append('floorName', floorName)
    if (duplicateFloorId) params = params.append('duplicateFloorId', duplicateFloorId)
    return this.http.post<Floor>(`${apiUrl}/floor`, null,  {params: params, withCredentials: true})
  }

  updateFloorNameById(floorId: number, floorName: string, version: number) {
    let params = new HttpParams().append('newName', floorName).append('version', version);
    return this.http.put(`${apiUrl}/floor/${floorId}/name`, null, {params: params, withCredentials: true})
  }

  updateFloorOrderById(newOrder: number, floorId: number) {
    return this.http.put(`${apiUrl}/floor/${floorId}/order?newOrder=${newOrder}`, null, {withCredentials: true})
  }

  deleteFloorById(floorId: number) {
    let params = new HttpParams();
    params = params.append('floorId', floorId);
    return this.http.delete(`${apiUrl}/floor`, {params: params, withCredentials: true})
  }


}
