import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormFile} from "../../../../../../_models/material";
import {Document} from "../../../../../../_models/document";
import {Drawing} from "../../../../../../_models/drawing";
import {DatePipe} from "@angular/common";
import {TimePrettifyPipe} from "../../../../../../_pipes/timePrettify.pipe";
import {DocumentsService} from "../../../../../../_services/documents.service";
import {modalAnimation} from "../../../../../../config/constants";

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
  animations: [modalAnimation]
})
export class FilePreviewComponent {
  @Output() close: EventEmitter<any> = new EventEmitter<any>()
  viewerType: 'google' | 'office' | 'pdf' = 'google';
  fileUrlForViewer: string;
  isNoPreview = false;
  isLoading = false;

  isImage = false;

  currentTime = Date.now();

  private _document: Document | Drawing;
  private _file: FormFile;
  @Input()
  set document(value: Document | Drawing) {
    if(value?.file?.name) {
      this._document = {...value}
      this.checkFileType(value.file)
    }
  }

  get document() {
    return this._document
  }

  @Input()
  set file(value: FormFile) {
    if(value?.name) {
      this._file = {...value}
      this.checkFileType(value)
    }
  }

  get file() {
    return this._file
  }

  constructor(private date: DatePipe,
              private timePrettify: TimePrettifyPipe,
              private documentService: DocumentsService) {
  }


  checkFileType(documentFile: FormFile) {
    this.isLoading = true;
    const fileName = documentFile?.name
    this.fileUrlForViewer = documentFile.fileUrl
    if (fileName.endsWith('.pdf')) {
      this.viewerType = 'google';
    }
    else if (fileName.endsWith('.doc') || fileName.endsWith('.docx') || fileName.endsWith('.xls') || fileName.endsWith('.xlsx')
        || fileName.endsWith('.ppt') || fileName.endsWith('.pptx')) {
      this.viewerType = 'google';
    } else if (fileName.endsWith('.png') || fileName.endsWith('.jpg') || fileName.endsWith('.jpeg')){
      this.isImage = true;
      this.isLoading = false;
    } else {
      this.isLoading = false;
      this.isNoPreview = true
    }
  }

  getTimeCreatedAtDocument(createdAt: number) {
    let diffTime = this.currentTime - createdAt;
    let seconds = Math.floor(diffTime/1000);
    let minutes = Math.floor(seconds/60);
    let hours = Math.floor(minutes/60);
    let days = Math.floor(hours/24);
    if (days >= 2) return this.date.transform(createdAt, 'MMM d, y h:mm a')
    else return this.timePrettify.transform(createdAt)
  }

  downloadFile() {
    let url: string;
    let filename: string;
    if(this.document) {
      url = this.document.file.fileUrl
      filename = this.document.file.name
    } else if(this.file) {
      url = this.file.fileUrl
      filename = this.file.name
    }
    this.documentService.downloadFile(url).subscribe((blob) => {
      const urlBlob = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = urlBlob;
      link.download = filename;

      link.click();

      URL.revokeObjectURL(urlBlob);
    });
  }

}
