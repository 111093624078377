<div class="backdrop">
  <div class="modal">
    <form class="form-invite" #form="ngForm" *ngIf="userInvitationForm" autofocus firstInputAutofocus scrollToFirstInvalidInput [formGroup]="userInvitationForm" (ngSubmit)="submitForm()">
      <button type="button" id="btnClose" class="close-modal-btn" (click)="close.emit()">
        <svg class="close-modal-icon">
          <use spriteVersion iconName="modal-close"></use>
        </svg>
      </button>
      <div class="form-inner-wrapper">
        <p class="form-modal-title invite-user-title">
          Invite new user
          <span *ngIf="userCompany?.companyPlan.planName" class="usersAmount-info">Available invitations: {{userCompany.companyPlan.maxUsers - userCompany.usersCount}}/{{userCompany.companyPlan.maxUsers}}</span>
        </p>

        <div class="main-fields-wrapper">
          <p class="main-error" *ngIf="userInvitationForm.invalid && form.submitted" id="mainError">
            <svg class="error-icon">
              <use spriteVersion iconName="error-icon"></use>
            </svg>
            All fields are required
          </p>

          <div class="field-wrapper">
            <label class="form-label"><span class="label-text">Email</span> </label>
            <span class="input-with-error-wrapper">
              <input class="form-input-modal" formControlName="email" id="userEmail" placeholder="Email">
              <p class="small-error-text-field" id="userEmailError" *ngIf="userInvitationForm.controls['email'].invalid && form.submitted">{{userInvitationForm.controls['email'].getError('error')}}</p>
            </span>
          </div>
          <div class="field-wrapper" *ngIf="currentUser.role === UserRole.ROLE_SUPERADMIN ">
            <label class="form-label"><span class="label-text">Company</span></label>
            <div class="ng-select-wrapper ng-select-wrapper--wider">
              <ng-select bindLabel="name"
                         id="userCompany"
                         bindValue="id"
                         formControlName="companyId"
                         [clearable]="false"
                         notFoundText="No results matching"
                         [editableSearchTerm]="false"
                         [searchable]="false"
                         placeholder="Company Name">
                <ng-option *ngFor="let company of companiesList" [value]="company.id">{{company.name}}</ng-option>
              </ng-select>
            </div>
            <p class="small-error-text-field" id="userCompanyError" *ngIf="userInvitationForm.controls['companyId'].invalid && form.submitted">This field is required</p>
          </div>

          <div class="field-wrapper">
            <label class="form-label"><span class="label-text">Role</span></label>
            <div class="ng-select-wrapper ng-select-wrapper--wider">
              <ng-select bindLabel="value"
                         id="userRole"
                         bindValue="key"
                         formControlName="role"
                         [clearable]="false"
                         (open)="addAttrsToFilterByRoleDropdownOptions()"
                         notFoundText="No results matching"
                         [editableSearchTerm]="false"
                         [searchable]="false"
                         placeholder="Select role">
                <ng-container *ngIf="(currentUser.role === UserRole.ROLE_SUPERADMIN || currentUser.role === UserRole.ROLE_ADMIN) && !projectId">
                  <ng-option *ngFor="let userRole of userRoleStringForSelector | keyvalue" [value]="userRole.key.toString()">{{userRole.value}}</ng-option>
                </ng-container>
                <ng-container *ngIf="(currentUser.role === UserRole.ROLE_SUPERADMIN || currentUser.role === UserRole.ROLE_ADMIN) && projectId ">
                  <ng-option [value]="UserRole.ROLE_PROJECT_MANAGER.toString()">{{userRoleStringForSelector.get(UserRole.ROLE_PROJECT_MANAGER)}}</ng-option>
                  <ng-option [value]="UserRole.ROLE_EMPLOYEE.toString()">{{userRoleStringForSelector.get(UserRole.ROLE_EMPLOYEE)}}</ng-option>
                </ng-container>
                <ng-container *ngIf="currentUser.role === UserRole.ROLE_PROJECT_MANAGER">
                  <ng-option [value]="UserRole.ROLE_EMPLOYEE.toString()">{{userRoleStringForSelector.get(UserRole.ROLE_EMPLOYEE)}}</ng-option>
                </ng-container>
              </ng-select>
            </div>
          </div>

          <div class="field-wrapper project-list" *ngIf="inviteUser.role === UserRole.ROLE_EMPLOYEE || inviteUser.role === UserRole.ROLE_PROJECT_MANAGER || projectId">
            <label class="form-label project-optional-label"> Select a Project <span *ngIf="!projectId" class="project-optional-text">(Optional)</span> </label>
            <div class="ng-select-wrapper ng-select-wrapper--wider">
              <ng-select #projectsSelector
                         id="selectProject"
                         formControlName="projectIds"
                         [clearable]="false"
                         notFoundText="No results matching"
                         [hideSelected]="true"
                         [multiple]="true"
                         placeholder="Select Project"
                         [attr.item-not-clearable]="globalStore.projectName$ | async">
                <ng-option *ngFor="let project of userCompany?.projects" [value]="project.id">{{project.name}}</ng-option>
                <ng-template [ngIf]="userCompany?.projects.length !== 0" ng-footer-tmp><a [attr.hasUsers]="userCompany?.projects.length !== 0 && userCompany?.projects.length !== projectsSelector.selectedValues.length" (click)="addAllProjects($event)">Add All Projects</a></ng-template>
                <ng-option *ngIf="userCompany?.projects.length === projectsSelector.selectedValues.length" value="no-results" disabled>{{projectsSelector.notFoundText}}</ng-option>
              </ng-select>
            </div>
          </div>

          <div class="wrapper-info">
            <p class="info-room-text" *ngIf="userCompany && userCompany?.usersCount === userCompany?.companyPlan.maxUsers">
              <svg class="exclamaition-icon">
                <use spriteVersion iconName="exclamation-circle"></use>
              </svg>
              <span>
                Upgrade your plan to add more users licenses
              </span>
            </p>
            <p class="info-room-text">
              <svg class="exclamaition-icon">
                <use spriteVersion iconName="exclamation-circle"></use>
              </svg>
              <span>
                Here you can add a new user to your system
              </span>
            </p>
          </div>
        </div>
      </div>

      <div class="form-btn-wrapper">
        <button type="button" class="btn-cancel" id="btnCancel" (click)="close.emit()">Cancel</button>
        <button type="submit" class="btn-invite" id="btnInvite" [disabled]="userCompany?.usersCount === userCompany?.companyPlan.maxUsers || submitBtnDisabled" loader="white" [showLoader]="submitBtnDisabled">Send an invite</button>
      </div>
    </form>
  </div>
</div>
