import { Component } from '@angular/core';
import {UsersService} from "../../../../_services/users.service";
import {Router} from "@angular/router";
import {GlobalStore} from "../../../../global.store";
import {take} from "rxjs";

@Component({
  selector: 'app-home-redirect',
  templateUrl: './home-redirect.component.html'
})
export class HomeRedirectComponent {
  constructor(readonly globalStore: GlobalStore,
              private userService: UsersService,
              private router: Router) {
    this.globalStore.currentUser$.pipe(take(1)).subscribe((user) => {
      this.router.navigateByUrl(this.userService.getUserHomePageUrl(user));
    })
  }
}
