<div class="tables-wrapper">
  <div class="table-with-animation-wrapper">
    <div class="animated-background animated-background-top">
      <div class="background-masker btn-divide-left-start"></div>
      <div class="background-masker btn-divide-left-1"></div>
      <div class="background-masker background-masker--with-before btn-divide-left-2"></div>

      <div class="background-masker background-masker--with-before btn-divide-left-3"></div>
      <div class="background-masker background-masker--with-before btn-divide-left-4"></div>
      <div class="background-masker background-masker--with-before btn-divide-left-5"></div>
      <div class="background-masker background-masker--with-before btn-divide-left-6"></div>
      <div class="background-masker background-masker--with-before btn-divide-left-7"></div>
      <div class="background-masker background-masker--with-before btn-divide-left-8"></div>
      <div class="background-masker background-masker--with-before btn-divide-left-9"></div>
      <div class="background-masker background-masker--with-before btn-divide-left-10"></div>
      <div class="background-masker background-masker--with-before btn-divide-left-11"></div>
      <div class="background-masker background-masker--with-before btn-divide-left-12"></div>
      <div class="background-masker background-masker--with-before btn-divide-left-13"></div>
      <div class="background-masker background-masker--with-before btn-divide-left-14"></div>
      <div class="background-masker background-masker--with-before btn-divide-left-15"></div>
      <div class="background-masker background-masker--with-before btn-divide-left-16"></div>
    </div>

  </div>

  <div class="table-with-animation-wrapper table-with-animation-wrapper-left">
    <div class="animated-background animated-background-left" *ngFor="let i of [].constructor(30)">
      <div class="background-masker btn-divide-left-fixed-start"></div>
      <div class="background-masker btn-divide-left-fixed-1"></div>
    </div>
  </div>
</div>
