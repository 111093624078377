import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {Page} from "../../../../_models/page";

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  private _paginationInfo: Page<any>;
  @Input() isLoading = false;
  @Input() set showAmountForMobile(value: number) {
    if(value) this.showAmount = value
    else this.showAmount = this.showAmountList[0];
  }
  @Input() set showAmountForDesk(value: number) {
    if(value) this.showAmount = value
    else this.showAmount = this.showAmountList[0];
  }
  @Output() changePage: EventEmitter<number> = new EventEmitter<number>();
  @Output() onChangeShowNumber: EventEmitter<number> = new EventEmitter<number>();

  pageNumbers: number[] = []

  showAmountList: number[] = [20, 50, 100]
  showAmount: number

  get paginationInfo(): Page<any> {
    return this._paginationInfo;
  }

  @Input()
  set paginationInfo(value: Page<any>) {
    this._paginationInfo = value;
    this.pageNumbers = [];
    for (let i = 1; i <= this._paginationInfo.totalPages; i++) this.pageNumbers.push(i);
  }

  constructor() {
  }

  changeShowNumber(event: any) {
    this.onChangeShowNumber.emit(event)
  }

  goToPage(page: number, direction: 'back' | 'forward' | null) {
    if((direction === 'forward' && this._paginationInfo.last) || (direction === 'back' && this._paginationInfo.first)) return;
    this.changePage.emit(page);
    this.gotoTop();
  }

  goToBackThreePage() {
    if (this._paginationInfo.number >= this._paginationInfo.totalPages - 3) this.goToPage(this._paginationInfo.totalPages === 7 ? 2 : this._paginationInfo.totalPages-6, 'back');
    else this.goToPage(this._paginationInfo.number - 3, 'back');
  }

  goToAheadThreePage() {
    if (this._paginationInfo.number <= 4) this.goToPage(7, null)
    else this.goToPage(this._paginationInfo.number + 3, 'forward')
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  protected readonly Number = Number;
  protected readonly focus = focus;
}
