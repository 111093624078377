import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {Room} from "../../../../../../_models/room";
import {Area, AreaStatusDefaultOrder} from "../../../../../../_models/area";
import {Unit} from "../../../../../../_models/unit";
import {RoomsService} from "../../../../../../_services/rooms.service";
import {NotificationService} from "../../../../../../_services/notification.service";
import {Floor} from "../../../../../../_models/floor";
import {SelectedArea} from "../../types";
import {UserRole} from "../../../../../../_models/user";
import {AreasService} from "../../../../../../_services/areas.service";
import {PopupOpened, ProjectViewStore} from "../project-view.store";
import {GlobalStore} from "../../../../../../global.store";


@Component({
  selector: 'app-room-block',
  templateUrl: './room-block.component.html',
  styleUrls: ['./room-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoomBlockComponent implements AfterViewInit {
  @Input() room: Room = new Room();
  @Input() unit: Unit;
  @Input() currentFloor: Floor;
  @Input() isCdkDragDisabled = false;

  isRolledUp = false;

  @Output() rollUpRoom: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('roomAreasList') roomAreasList: ElementRef;
  @ViewChild('roomItem') roomItem: ElementRef;

  constructor(readonly projectViewStore: ProjectViewStore,
              readonly globalStore: GlobalStore,
              private roomsService: RoomsService,
              private notifService: NotificationService,
              private areaService: AreasService) {
  }

  ngAfterViewInit() {
    this.updateRoomAreasListHeight()
  }

  updateRoomAreasListHeight() {
    if (this.isRolledUp) this.roomAreasList.nativeElement.style.maxHeight = '0px';
    else this.roomAreasList.nativeElement.style.maxHeight = this.roomAreasList.nativeElement.scrollHeight + 'px';
  }

  onRollUpRoom() {
    this.isRolledUp = !this.isRolledUp;
    this.roomAreasList.nativeElement.toggleAttribute('rollUp');
    this.roomItem.nativeElement.toggleAttribute('rollUp');
    this.updateRoomAreasListHeight();
    this.rollUpRoom.emit();
  }

  onDeleteRoom() {
    this.roomsService.deleteRoom(this.room.id).subscribe(() => {
      this.projectViewStore.deleteRoom({unitId: this.unit.id, roomId: this.room.id});
      this.projectViewStore.loadProjectStatuses();
      this.notifService.successNotification('Changes have been saved');
      this.globalStore.loadProjectProgress()
    })
  }

  onEditRoom(templateName: string) {
    this.projectViewStore.updateSelectedObject({obj: {room: this.room, unitId: this.unit.id, unitName: this.unit.name}});

    if (templateName) this.projectViewStore.updatePopupOpened(PopupOpened.CREATE_EDIT_ROOM_CREATED_BASED_ON_TEMPLATE);
    else this.projectViewStore.updatePopupOpened(PopupOpened.ROOM_EDIT);
  }

  setAreaOpacity(area: Area) {
    return area.status.orderNumber === AreaStatusDefaultOrder.IN_PROGRESS && area.statusProgress >= 10 ? area.statusProgress/100 : area.status.orderNumber === AreaStatusDefaultOrder.IN_PROGRESS && area.statusProgress === 0 ? 0.1 : 1
  }

  openAreaModal(area: Area) {
    this.areaService.getAreaById(area.id).subscribe((data) => {
      data.attachmentsAmount = data.files.length
      data.commentsAmount = data.comments.length
      this.projectViewStore.updateArea(data);
      let areaObj = {
        area: data,
        floorId: this.currentFloor.id,
        floorName: this.currentFloor.name,
        unitId: this.unit.id,
        unitName: this.unit.name,
        roomId: this.room.id,
        roomName: this.room.name}
      this.projectViewStore.updateSelectedObject({obj: areaObj, isSelectedArea: true})
      this.projectViewStore.updatePopupOpened(PopupOpened.AREA_VIEW)
    })
  }


  protected readonly UserRole = UserRole;
}
