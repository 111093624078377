<div class="add-files-wrapper">
  <label class="add-files-label">Files:</label>
  <ng-scrollbar #ngScrollBar class="files-scroll-wrapper" [attr.isPaddingBottom]="'true'" [attr.hasAttacments]="getSumFileList().length > 0" [track]="'horizontal'" [autoHeightDisabled]="true">
    <div class="add-files-field-box" (click)="fileInput.click()" fileDropArea [inputElement]="fileInput"  (filesDropped)="toggleStatusLoadingFile($event)">
      <p class="add-files-field-box-text">
        <svg class="add-files-icon">
          <use spriteVersion iconName="add-file-area-details"></use>
        </svg>
        {{getSumFileList().length > 0 ? '' : 'Add file' }}
        <input type="file" accept="audio/*,video/*,image/*,application/*" #fileInput class="input-file" id="fileInputMobile">
      </p>
    </div>

    <ul class="files-list">
      <li class="file-item" *ngFor="let file of getSumFileList()">
        <ng-template [ngIf]="file.id">
          <span class="file-link" (click)="fileToPreview.emit(file)">
            <div class="file-item-inner-wrapper">
              <img [src]="file.fileUrl" class="file-img" *ngIf="file.fileUrl && isFileCanBePreviewed(file.name)">
              <span *ngIf="!isFileCanBePreviewed(file.name)" class="document-icon-wrapper">
                <svg width="32" height="32" class="document-icon">
                  <use appDocumentsTypeIcon [documentFile]="file"></use>
                </svg>
              </span>
              <div class="fileName-with-loader">
                <p class="fileName-with-timeLeft">{{file.name | sliceText: 40}} <span *ngIf="file.progress < 100" class="timeLeft">{{file.progress + '% / ' + file.timeLeft + 's'}}</span></p>
                <p *ngIf="file.progress < 100"  class="loadingBar" id="loaderFile" [style.background]="'linear-gradient(to right, #FF4E00 0%, #FF4E00 ' + file.progress + '%, #F0F2F6 ' + file.progress + '%), #FF4E00 100%'"></p>
                <p *ngIf="file.progress === 100" class="fileSize" id="fileSize">{{file.sizeBytes | fileSizePrettify}}</p>
              </div>
            </div>
          </span>
          <button *ngIf="currentUser.role === UserRole.ROLE_SUPERADMIN || currentUser.id === file.owner.id" (click)="removeFile(file)" class="delete-file-btn" >
            <svg class="delete-icon" >
              <use spriteVersion iconName="trash-delete-icon"></use>
            </svg>
          </button>
        </ng-template>
      </li>
    </ul>
  </ng-scrollbar>
  <p class="small-error-text-field" id="uploadFileErrorDropArea" *ngIf="errorUploadFileSize || errorUploadFilesAmount">{{errorUploadFileSize ? 'Error: Unable to upload. The file size must not exceed ' + 25 + 'MB.' : 'Error: Unable to upload. The number of files must not exceed 4.'}}</p>
</div>
