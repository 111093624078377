
<ng-scrollbar [style.height]="companiesPage.content.length > 0 && companiesPage.content.length < 4 ? companiesPage.content.length*48 + 46 + 'px' : companiesPage.content.length === 0 ? '134px' : '218px'" [attr.isIOS]="(isIOS | async)" [autoHeightDisabled]="true" [track]="'all'" class="table-wrapper-with-dots">
  <div class="innerScrollBarWrapper">
    <div class="table-wrapper-with-scroll">
      <ul class="table-companies" *ngIf="!companiesPage.empty; else emptyList">
        <ul class="table-companies__header-row">
          <li class="table-companies__id-title fixed-left-column">
              <span class="table-title-wrapper" [attr.shouldBlack]="sorting['id']">
                ID
                <button class="table-sort-icon-btn" (click)="onSortBy('id')" [style.opacity]="sorting['id'] ? 1 : ''">
                  <svg class="table-sort" [attr.rotate]="sorting['id'] === SortType.DESC" *ngIf="sorting['id']; else grayicon">
                    <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                      <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>
          <li class="table-companies__name-title fixed-left-column-name">
              <span class="table-title-wrapper"  [attr.shouldBlack]="sorting['name']">
                Company Name
                <button class="table-sort-icon-btn" (click)="onSortBy('name')" [style.opacity]="sorting['name'] ? 1 : ''">
                  <svg class="table-sort" [attr.rotate]="sorting['name'] === SortType.DESC" *ngIf="sorting['name']; else grayicon">
                    <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>
                  <ng-template #grayicon>
                    <svg class="table-sort">
                      <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>
          <li class="table-companies__state-title">State</li>
          <li class="table-companies__email-title">Projects</li>
          <li class="table-companies__plan-title">
              <span class="table-title-wrapper" [attr.shouldBlack]="sorting['companyPlan']">
                Plan
                <button class="table-sort-icon-btn" (click)="onSortBy('companyPlan')" [style.opacity]="sorting['companyPlan'] ? 1 : ''">
                  <svg class="table-sort" [attr.rotate]="sorting['companyPlan'] === SortType.DESC" *ngIf="sorting['companyPlan']; else grayicon">
                     <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                       <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>
          <li class="table-companies__user-title">
              <span class="table-title-wrapper" [attr.shouldBlack]="sorting['usersCount']">
                Users
                <button class="table-sort-icon-btn" (click)="onSortBy('usersCount')" [style.opacity]="sorting['usersCount'] ? 1 : ''">
                  <svg class="table-sort" [attr.rotate]="sorting['usersCount'] === SortType.DESC" *ngIf="sorting['usersCount']; else grayicon">
                     <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                       <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>
          <li class="table-companies__actions no-right-border"></li>
        </ul>

        <ul class="table-body-list">
          <li *ngFor="let company of companiesPage.content; index as i" [id]="company.id" class="table-companies__row">
            <p *ngIf="i == companiesPage.content.length - 2" (visible)="getNextPage()"></p>
            <ul class="item-info-list">
              <li class="company-id fixed-left-column" [routerLink]="['/system/company', company.id]">
                <span class="item-code-way">
                    {{company.id}}
                </span>
              </li>
              <li class="company-name fixed-left-column-name" [routerLink]="['/system/company', company.id]">
                <div class="company-name__wrapper">
                  <span class="list-name-wrapper" [title]="company.name">
                    {{company.name}}
                  </span>
                </div>
              </li>
              <li class="company-state-item">{{company.state}}</li>
              <li class="company-email-item">{{company.projectsCount}}</li>
              <li class="company-plan-item">{{company.companyPlan.planName}}</li>
              <li class="company-users-item">{{company.usersCount}}/{{company.companyPlan.maxUsers}}</li>
              <li class="dots-actions"></li>
            </ul>
          </li>
        </ul>
      </ul>

    </div>
  </div>
</ng-scrollbar>

<app-loader [isDashboardListLoading]="true" *ngIf="isLoadingCompanies"></app-loader>

<ng-template #emptyList>
  <div class="empty-list-wrapper">
    <p class="empty-list-text" *ngIf="companiesPage.empty; else notFound">The list of companies is empty.</p>
    <ng-template #notFound>
      <p class="empty-list-text">No Companies found</p>
    </ng-template>
    <img class="empty-list-icon" src="assets/images/empty-list.png" alt="empty-list">
  </div>
</ng-template>

