import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AreaFormStore} from "../area-form.store";
import {SelectedArea} from "../../../types";
import {GlobalStore} from "../../../../../../../global.store";

@Component({
  selector: 'app-history-area',
  templateUrl: './history-area.component.html',
  styleUrls: ['./history-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryAreaComponent {
  @Input() selectedArea: SelectedArea;

  constructor(readonly globalStore: GlobalStore,
              public areaFormStore: AreaFormStore) {
  }
}
