import {AfterViewInit, Directive, ElementRef} from "@angular/core";
import {GlobalStore} from "../global.store";

@Directive({
  selector: 'form[firstInputAutofocus]'
})
export class FirstInputAutofocusOnMobileDirective implements AfterViewInit{
  isNotDesktop: boolean
  constructor(private el: ElementRef, private globalStore: GlobalStore) {
    this.globalStore.isNotDesktop$.subscribe(data => this.isNotDesktop = data)
  }

  ngAfterViewInit() {
    if(this.isNotDesktop) {
      this.addFocus()
    } else {
      setTimeout(() => {
        this.addFocus()
      }, 300)
    }
  }

  addFocus() {
    let firstInput = (this.el.nativeElement as HTMLElement).querySelector('input');
    if (firstInput) firstInput.focus();
  }
}
