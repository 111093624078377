export class Page<T> {
  content: T[] = [];
  last:boolean;
  totalPages : number;
  totalElements: number;
  size: number;
  number: number;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}
