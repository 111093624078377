import {Directive, ElementRef, HostListener} from "@angular/core";

@Directive({
  selector: '[stopClickPropagation]'
})
export class StopClickPropagationDirective {
  constructor(private el: ElementRef) {}

  @HostListener('mousedown', ['$event'])
  @HostListener('click', ['$event'])
  onClick(event: Event) {
    event.stopPropagation();
  }
}
