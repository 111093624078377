import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Company} from "../../../../../_models/company";
import {Project} from "../../../../../_models/project";
import {UsersService} from "../../../../../_services/users.service";
import {NotificationService} from "../../../../../_services/notification.service";
import {User, UserRole} from "../../../../../_models/user";
import {CompaniesService} from "../../../../../_services/companies.service";
import {ProjectsService} from "../../../../../_services/projects.service";

import {modalAnimation, phoneMask, userRoleStringForSelector} from 'src/app/config/constants';
import {convertUserRoleToEnum} from "../../../../../_util/utils";
import {emailValidatorByPattern, minLengthValidator, requiredValidator} from "../../../../../_util/validators";
import {HttpErrorResponse} from "@angular/common/http";
import {GlobalStore} from "../../../../../global.store";
import {switchMap} from "rxjs";

@Component({
  selector: 'app-user-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.scss'],
  animations: [modalAnimation]
})
export class UserProfileFormComponent {
  @HostBinding('@modalAnimation') modalAnimation: any;
  @Input() currentUser: User;
  @Output() created: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  private _user = new User();

  companiesList: Company[] = [];
  projectList: Project[] = [];
  userProfileForm: FormGroup;
  userOldName: string;

  submitBtnDisabled = false;

  get user(): User {
    return this._user;
  }

  @Input()
  set user(value: User) {
    if (value) {
      this._user = {...value};
      this._user.projectIds = this._user.projects.map(e => e.id);
      this.userOldName = value.name;

      this.fillForm();
      if (value.role !== UserRole.ROLE_SUPERADMIN) {
        if (this.currentUser.role === UserRole.ROLE_SUPERADMIN) this.companyService.getShortCompanyList().subscribe((data) => this.companiesList = data);
        if (this.currentUser.role !== UserRole.ROLE_EMPLOYEE) this.projectService.getProjectShortList(this._user.company.id).subscribe((data) => this.projectList = data);
      }
      this.subscribeToFormChanges();
    }
  }

  constructor(readonly globalStore: GlobalStore,
              private fb: FormBuilder,
              private userService: UsersService,
              private notifService: NotificationService,
              private companyService: CompaniesService,
              private projectService: ProjectsService) {
    this.fillForm();
  }

  subscribeToFormChanges() {
    this.userProfileForm.valueChanges.subscribe(() => {
      this._user.name = this.userProfileForm.controls['name'].value;
      this._user.countryCode = this.userProfileForm.controls['countryCode'].value;
      this._user.phone = this.userProfileForm.controls['phone'].value;
      this._user.email = this.userProfileForm.controls['email'].value;
      if (this.user.role !== UserRole.ROLE_SUPERADMIN) {
        this._user.role = convertUserRoleToEnum(this.userProfileForm.controls['role'].value);
        this._user.projectIds = this.userProfileForm.controls['projectIds'].value;
      }
    })

    this.userProfileForm.controls['companyId'].valueChanges
      .pipe(
        switchMap(value =>  this.projectService.getProjectShortList(this.userProfileForm.controls['companyId'].value))
    ).subscribe((data) => this.projectList = data);


  }

  fillForm() {
    this.userProfileForm = this.fb.group({
      id: [{value: this.user?.id, disabled: true}],
      name: [this.user?.name, [minLengthValidator('NAME'), requiredValidator()]],
      countryCode: [this.user?.countryCode],
      phone: [this.user?.phone, [minLengthValidator('PHONE'), requiredValidator()]],
      email: [this.user?.email, [emailValidatorByPattern(), requiredValidator()]],
      companyId: [{value: this.user?.company?.id, disabled: true}],
      role: [{
        value: this.user?.role,
        disabled: (this.currentUser?.role !== UserRole.ROLE_SUPERADMIN) && this.currentUser?.id === this._user?.id
      }, Validators.required],
      projectIds: [{value: this._user?.projectIds, disabled: this.currentUser?.role === UserRole.ROLE_PROJECT_MANAGER}]
    })
  }

  addAllProjects(event: any) {
    event.preventDefault()
    let projectIds = this.projectList.map(project => project.id)
    this.userProfileForm.controls['projectIds'].setValue(projectIds)
  }

  changeCountryCode(countryCode: string) {
    this.userProfileForm.controls['countryCode'].setValue(countryCode)
  }

  submitForm() {
    if (this.userProfileForm.invalid) return;
    this.submitBtnDisabled = true;

    this.userService.updateUserInfo(this._user).subscribe((data) => {
      if (this.user.id === this.currentUser.id) this.globalStore.updateCurrentUser(data);

      this._user.version = data.version;

      if ((this._user.role === UserRole.ROLE_EMPLOYEE || this._user.role === UserRole.ROLE_PROJECT_MANAGER) && (this.user.projectIds.length !== this.user.projects.length || !this.user.projectIds.every(e => this.user.projects.map(a => a.id).includes(e)) || !this.user.projects.every(e => this.user.projectIds.includes(e.id)))) {
        this.userService.setUserProjects(this.user.projectIds, this.user.id, this._user.version).subscribe(() => {
          this.created.emit();
          this.notifService.successNotification("Changes have been saved")
        }, error => this.submitBtnDisabled = false);
      } else {
        this.created.emit();
        this.notifService.successNotification("Changes have been saved")
      }
    }, (error: HttpErrorResponse) => {
      this.submitBtnDisabled = false;
      if (error.error.error === 'Phone already in use') this.userProfileForm.controls['phone'].setErrors({error: 'This phone number already in use'})
      else if (error.error.error === 'Email already in use') this.userProfileForm.controls['email'].setErrors({error: 'This Email already in use'})
    });
  }


  protected readonly phoneMask = phoneMask;
  protected readonly UserRole = UserRole;
  protected readonly userRoleStringForSelector = userRoleStringForSelector;
}





