<li class="floor-item"
    [title]="floor.name"
    [id]="'floor-' + floor.id"
    [class.active]="floor?.id === currentFloor?.id"
    [class.disabled]="(projectViewStore.floorsInLoading$ | async)"
    (click)="changeFloor.emit(floor.id)">
  {{ floor.name }}
  <div *ngIf="(projectViewStore.floorsInLoading$ | async) && floor?.id === currentFloor?.id" class="loader-spin">
    <svg>
      <use spriteVersion iconName="loader-icon"></use>
    </svg>
  </div>
  <div *ngIf="currentFloor?.id === floor.id && (globalStore.currentUser$ | async).role !== UserRole.ROLE_EMPLOYEE && !(projectViewStore.floorsInLoading$ | async)"
       (appOutsideClick)="isFloorMenuOpen = false; projectViewStore.updateIsCdkDragDisabled(false)"
       [enabled]="isFloorMenuOpen"
       class="floor-dots-container">
    <span class="floor-menu-dots"
          id="menuListFloorOpen"
          (click)="isFloorMenuOpen = !isFloorMenuOpen; projectViewStore.updateIsCdkDragDisabled(!isCdkDragDisabled)">
    </span>
    <ul class="editMenuList"
        *ngIf="isFloorMenuOpen"
        [@modalAnimation]
        (isRighterThenMiddleOfScreen)="isRighterThenMiddleOfScreen = $event"
        [ngStyle]="{right: !isRighterThenMiddleOfScreen ? 'unset' : '0', left: !isRighterThenMiddleOfScreen ? '0' : 'unset'}">
      <li class="editMenuList__item" id="editFloorBtn"
          (click)="isFloorEditFormOpen = true;
          this.editFloorForm.controls['name'].setValue(floor.name); isFloorMenuOpen = false">
        <svg class="editMenu-icon">
          <use spriteVersion iconName="edit-icon"></use>
        </svg>
        Edit
      </li>
      <li class="editMenuList__item"
          id="deleteFloorBtn"
          [attr.disabled]="floorsShortList.length === 1"
          (click)="deletingFloor(floor)">
        <svg class="editMenu-icon">
          <use spriteVersion iconName="trash-delete-icon"></use>
        </svg>
        Delete
      </li>
    </ul>
  </div>


  <form class="form-edit-floor"
        *ngIf="isFloorEditFormOpen"
        stopClickPropagation
        firstInputAutofocus
        #editFloorNgForm="ngForm"
        (ngSubmit)="updateFloorAndRemoveOpenedAttr(floor)"
        [formGroup]="editFloorForm"
        (appOutsideClick)="isFloorEditFormOpen = false">
    <input type="text" class="input-create-item"
           autofocus
           [id]="'floor-name-input-' + floor.id" formControlName="name"
           formInput="FLOOR_UNIT_NAME">
    <p class="small-error-text-field" id="floorNameError"
       *ngIf="editFloorNgForm.submitted && editFloorForm.controls['name'].invalid">
      {{ editFloorForm.controls['name'].getError('error') }}
    </p>
    <div class="form-create-btn-wrapper">
      <button type="submit" id="saveEditFloorBtn" class="create-item-btn">Save</button>
      <button type="reset" id="canselBtnEditFloor" class="cansel-create-btn"
              (click)="isFloorEditFormOpen = false; projectViewStore.updateIsCdkDragDisabled(false)">
        <svg class="close-icon-form">
          <use spriteVersion iconName="close-icon"></use>
        </svg>
      </button>
    </div>
  </form>
</li>
