import { Component } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {UsersService} from "../../../_services/users.service";
import {NotificationService} from "../../../_services/notification.service";
import {ActivatedRoute, Router} from "@angular/router";
import {matchValidator, minLengthValidator, requiredValidator} from "../../../_util/validators";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-create-new-password',
  templateUrl: './create-new-password.component.html',
  styleUrls: ['../auth.scss', './create-new-password.component.scss']
})
export class CreateNewPasswordComponent {
  newPasswordForm: FormGroup = new FormGroup({
    newPassword: new FormControl('', [matchValidator('confirmPassword', true), minLengthValidator('PASSWORD'), requiredValidator()]),
    confirmPassword: new FormControl('', [matchValidator('newPassword'), minLengthValidator('PASSWORD'), requiredValidator()]),
  })
  isShowPassword: boolean = false
  isShowConfirmPassword: boolean = false
  verifyCode: string;
  showLoader = false;

  constructor(private userService: UsersService,
              private notifService: NotificationService,
              private route: ActivatedRoute,
              private router: Router) {
    this.verifyCode = this.route.snapshot.queryParamMap.get('verifyCode');
  }

  showPassword() {
    this.isShowPassword = !this.isShowPassword;
  }

  showConfirmPassword() {
    this.isShowConfirmPassword = !this.isShowConfirmPassword;
  }


  onSubmit() {
    if (this.newPasswordForm.invalid) return;
    this.showLoader = true;
    this.newPasswordForm.setErrors(null);

    this.userService.createNewPassword(this.verifyCode, this.newPasswordForm.get('confirmPassword')?.value).subscribe(() => {
      this.router.navigate(['/login']);
      this.notifService.successNotification("Password has been changed");
    }, (error: HttpErrorResponse) => {
      this.showLoader = false;
      if (error.status === 400){
        if (error.error.error === 'Bad code') this.newPasswordForm.setErrors({error: 'Invalid verification code'});
        else if (error.error.error === 'Same password') this.newPasswordForm.setErrors({error: 'New password should be different from current one'});
      }
    });
  }
}
