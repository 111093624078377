<div class="table-with-animation-wrapper" [attr.isDashboardListLoading]="isDashboardListLoading">
  <div class="animated-background">
    <div class="background-masker btn-divide-left-start"></div>
    <div class="background-masker btn-divide-left-1"></div>
    <div class="background-masker btn-divide-left-2"></div>
    <div class="background-masker btn-divide-left-3"></div>
    <div class="background-masker btn-divide-left-4"></div>
    <div class="background-masker btn-divide-left-5"></div>
    <div class="background-masker btn-divide-left-6"></div>
    <div class="background-masker btn-divide-left-7"></div>
    <div class="background-masker btn-divide-left-end"></div>
  </div>

  <div class="animated-background" *ngFor="let i of [].constructor(amountRowArr)">
    <div class="background-masker btn-divide-left-start"></div>
    <div class="background-masker btn-divide-left-1"></div>
    <div class="background-masker btn-divide-left-2"></div>
    <div class="background-masker btn-divide-left-3"></div>
    <div class="background-masker btn-divide-left-4"></div>
    <div class="background-masker btn-divide-left-5"></div>
    <div class="background-masker btn-divide-left-6"></div>
    <div class="background-masker btn-divide-left-7"></div>
    <div class="background-masker btn-divide-left-end"></div>
  </div>
</div>
