import {
  Component,
  EventEmitter,
  HostBinding,
  Input, OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, NgForm, Validators} from "@angular/forms";
import {Project} from "../../../../../_models/project";
import * as americanStates from "../../../../../../assets/data/usa_states_cities.json";
import {Company} from "../../../../../_models/company";
import {CompaniesService} from "../../../../../_services/companies.service";
import {ProjectsService} from "../../../../../_services/projects.service";
import {NotificationService} from "../../../../../_services/notification.service";
import {modalAnimation} from 'src/app/config/constants';

import {NgSelectComponent} from "@ng-select/ng-select";
import {filter, map, Observable, of, startWith, Subject, Subscription, switchMap, takeUntil, tap} from "rxjs";
import {
  minLengthValidator,
  requiredValidator,
  startDateEndDateValidator
} from "../../../../../_util/validators";
import {HttpErrorResponse} from "@angular/common/http";
import {UserRole} from "../../../../../_models/user";
import {GlobalStore} from "../../../../../global.store";


@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss'],
  animations: [modalAnimation]
})
export class ProjectFormComponent implements OnDestroy {
  private _project = new Project();
  projectOld: Project;
  companies: Company[] = [];
  states: string[] = Object.keys(americanStates).filter(state => state !== 'default')
  submitBtnDisabled = false;
  projectForm: FormGroup;
  companyClients: string[] = [];
  filteredCompanyClients: Observable<string[]>;
  projectCodeError = false;

  @Output() created: EventEmitter<Project> = new EventEmitter<Project>();
  @Output() updated: EventEmitter<Project> = new EventEmitter<Project>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('form') form: NgForm

  isMobile = this.globalStore.isMobile$;
  companyId: number;
  projectId: number;

  private readonly destroy$ = new Subject<void>()

  get project(): Project {
    return this._project;
  }

  @Input()
  set project(value: Project) {
    if (value) {
      this._project = JSON.parse(JSON.stringify(value));
      this.projectOld = JSON.parse(JSON.stringify(value));
      this.fillProjectForm();
    }
  }

  constructor(private fb: FormBuilder,
              private companyService: CompaniesService,
              private projectService: ProjectsService,
              private notifService: NotificationService,
              readonly globalStore: GlobalStore) {
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => {
      this.companyId = companyId;
      this.fillProjectForm();
    });
    this.globalStore.projectId$
      .pipe(
        tap((projectId) => {
          this.projectId = projectId;
        }),
        switchMap(() => {
          if (!this.project.id && !this.companyId) {
            return this.companyService.getShortCompanyList().pipe(
              tap((data) => this.companies = data)
            );
          } else if (this.companyId) {
            return this.companyService.getCompanyClientNames(this.companyId).pipe(
              tap((data) => this.companyClients = data)
            );
          } else {
            return of(null);
          }
        }),
        takeUntil(this.destroy$)
      ).subscribe();
  }

  subscribeToFormChanges() {
    this.projectForm.valueChanges.subscribe(() => {
      this._project.name = this.projectForm.controls['name'].value;
      this._project.publicKey = this.projectForm.controls['projectCode'].value
      this._project.address1 = this.projectForm.controls['address1'].value;
      this._project.address2 = this.projectForm.controls['address2'].value;
      this._project.state = this.projectForm.controls['state'].value;
      this._project.city = this.projectForm.controls['city'].value;
      this._project.zipCode = this.projectForm.controls['zipCode'].value;
      this._project.number = this.projectForm.controls['number'].value;
      this._project.clientName = this.projectForm.controls['clientName'].value;
      this._project.startDate = this.projectForm.controls['startDate'].value;
      this._project.endDate = this.projectForm.controls['endDate'].value;
    })

    if(!this.project.id){
      this.projectForm.controls['name'].valueChanges
        .pipe(
          filter(value => !!value),
          switchMap(value =>
            this.projectService.createProjectKey(value)
          )
        ).subscribe(data => {
          this.projectForm.controls['projectCode'].setValue(data.result);
        });

      this.projectForm.controls['projectCode'].valueChanges
        .pipe(
          tap(value => {
            if (!value || value.length === 0) {
              this.projectForm.controls['projectCode'].setErrors({ error: "Project code cannot be empty" });
            } else {
              this.projectForm.controls['projectCode'].setErrors(null);
            }
          }),
          switchMap(value =>
            this.projectService.validateProjectKey(value.toUpperCase())
          )
        ).subscribe(data => {
          this.projectCodeError = data.error !== "";
          if (this.projectCodeError) {
            this.projectForm.controls['projectCode'].setErrors({ error: data.error });
          } else {
            this.projectForm.controls['projectCode'].setErrors(null);
          }
        });
    }

    this.projectForm.controls['projectCode'].valueChanges.subscribe(value => {
      const currentControlValue = this.projectForm.controls['projectCode'].value;
      const uppercaseValue = value.toUpperCase();
      if (currentControlValue !== uppercaseValue) {
        this.projectForm.controls['projectCode'].setValue(uppercaseValue, { emitEvent: false });
      }
    })


    this.projectForm.controls['companyId'].valueChanges.pipe(
      switchMap(value => this.companyService.getCompanyClientNames(Number(value)))
    ).subscribe((data) => this.companyClients = data)

    this.projectForm.controls['clientName'].valueChanges.subscribe((value) => {
      if (value && value.length > 0) this.projectForm.controls['clientName'].setValidators([minLengthValidator('NAME')]);
      else this.projectForm.controls['clientName'].clearValidators();

      this.projectForm.controls['clientName'].updateValueAndValidity({onlySelf: true, emitEvent: false});
    })

    this.filteredCompanyClients = this.projectForm.controls['clientName'].valueChanges.pipe(startWith(''), map(value => this._filter(value || '')));
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.companyClients.filter(option => option.toLowerCase().includes(filterValue));
  }

  fillProjectForm() {
    this.projectForm = this.fb.group({
      id: [{value: this._project.id, disabled: true}],
      name: [this._project.name, [minLengthValidator('NAME'), requiredValidator()]],
      projectCode: [{value: this._project.publicKey || '', disabled: this._project.id}, [minLengthValidator('NAME'), requiredValidator()]],
      number: [this._project.number, [requiredValidator()]],
      companyId: [this.companyId || this._project.company.id || null, requiredValidator()],
      address1: [this._project.address1, [minLengthValidator('ADDRESS'), requiredValidator()]],
      address2: [this._project.address2],
      state: [this._project.state, Validators.required],
      city: [this._project.city, [requiredValidator()]],
      zipCode: [this._project.zipCode, [minLengthValidator('ZIP_CODE'), requiredValidator()]],
      clientName: [this._project.clientName, [minLengthValidator('NAME'), requiredValidator()]],
      startDate: [this._project.startDate, startDateEndDateValidator('endDate', true)],
      endDate: [this._project.endDate, startDateEndDateValidator('startDate')]
    })
    this.subscribeToFormChanges();
  }

  getFormControl(name: string) {
    return this.projectForm.controls[name] as FormControl;
  }

  submitForm() {
    if (this.projectForm.invalid) return
    this.submitBtnDisabled = true;

    if (!this.project.id && this.companyId) {
      this.projectService.createProject(this.companyId, this._project).subscribe((createdProject) => {
        this.created.emit(createdProject);
        this.notifService.successNotification("Changes have been saved");
      }, (error: HttpErrorResponse) => {
        this.submitBtnDisabled = false;
        if (error.status === 400) {
          if (error.error.error === 'Name already in use') this.projectForm.controls['name'].setErrors({error: 'Project with such name already exists'})
          if (error.error.error === 'Number already in use') this.projectForm.controls['number'].setErrors({error: 'Project with such number already exists'})
        }
      });
      return
    }

    if (!this.project.id) {
      this.projectService.createProject(this.projectForm.controls['companyId'].value, this._project).subscribe((createdProject) => {
        this.created.emit(createdProject);
        this.notifService.successNotification("Changes have been saved");
      }, (error: HttpErrorResponse) => {
        this.submitBtnDisabled = false;
        if (error.status === 400) {
          if (error.error.error === 'Name already in use') this.projectForm.controls['name'].setErrors({error: 'Project with such name already exists'})
          if (error.error.error === 'Number already in use') this.projectForm.controls['number'].setErrors({error: 'Project with such number already exists'})
        }
      });
    } else {
      this.projectService.updateProject(this._project).subscribe((data) => {
        this.updated.emit(data);
        this.notifService.successNotification("Changes have been saved");
      }, (error: HttpErrorResponse) => {
        this.submitBtnDisabled = false;
        if (error.status === 400) {
          if (error.error.error === 'Name already in use') this.projectForm.controls['name'].setErrors({error: 'Project with such name already exists'})
          if (error.error.error === 'Number already in use') this.projectForm.controls['number'].setErrors({error: 'Project with such number already exists'})
        }
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly UserRole = UserRole;
}
