import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {apiUrl} from "../../environments/environment";
import {map, Observable} from "rxjs";
import {InviteUser, User, UserLocation, UserRole} from "../_models/user";
import {convertUserRoleToEnum} from "../_util/utils";
import {Page} from "../_models/page";

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }

  getUsersListByParams(companyId: number, userRole: UserRole, query: string, sortField: string, sortDirection: string, page: number, pageSize: number):Observable<Page<User>> {
    let params = new HttpParams();
    if (companyId) params = params.set('companyId', companyId);
    if (userRole) params = params.set('userRole', userRole);
    if (query && query.length > 0) params = params.set('query', query);
    if (sortField) params = params.set('sortField', sortField);
    if (sortDirection) params = params.set('sortDirection', sortDirection);
    if (page) params = params.set('page', page);
    if (pageSize) params = params.set('pageSize', pageSize);

    return this.http.get<Page<User>>(`${apiUrl}/user/list`, {params: params, withCredentials: true}).pipe(map(usersPage => {
      usersPage.content.forEach(e => e.role = convertUserRoleToEnum(e.role));
      return usersPage;
    }))
  }

  export(companyId: number, projectId: number, userRole: UserRole, query: string, sortField: string, sortDirection: string) {
    let params = new HttpParams();
    if (companyId) params = params.set('companyId', companyId);
    if (projectId) params = params.set('projectId', projectId);
    if (userRole) params = params.set('userRole', userRole);
    if (query && query.length > 0) params = params.set('query', query);
    if (sortField) params = params.set('sortField', sortField);
    if (sortDirection) params = params.set('sortDirection', sortDirection);

    return this.http.get(`${apiUrl}/user/export`, {
      params: params,
      withCredentials: true,
      responseType: 'blob',
      observe: 'response'
    });
  }

  inviteUser(user: InviteUser) {
    return this.http.post(`${apiUrl}/user/invite`, user, {withCredentials: true})
  }

  getCompanyUsersNotInProject(projectId: number): Observable<User[]> {
    return this.http.get<User[]>(`${apiUrl}/project/${projectId}/employee/notOnProject`, {withCredentials: true}).pipe(map(users => {
      users.forEach(e => e.role = convertUserRoleToEnum(e.role));
      return users;
    }))
  }

  getConfirmedEmployeesOnProject(projectId: number): Observable<User[]> {
    return this.http.get<User[]>(`${apiUrl}/project/${projectId}/employee/confirmed`, {withCredentials: true}).pipe(map(users => {
      users.forEach(e => e.role = convertUserRoleToEnum(e.role));
      return users;
    }))
  }

  getConfirmedEmployeesOnProjectShort(projectId: number): Observable<User[]> {
    return this.http.get<User[]>(`${apiUrl}/project/${projectId}/employee/confirmed/short`, {withCredentials: true});
  }

  getUserListByCompanyIdAndParams(companyId: number, userRole: UserRole, query: string, sortField: string, sortDirection: string, page: number, pageSize: number): Observable<Page<User>> {
    let params = new HttpParams();
    if (userRole) params = params.set('userRole', userRole);
    if (query && query.length > 0) params = params.set('query', query);
    if (sortField) params = params.set('sortField', sortField);
    if (sortDirection) params = params.set('sortDirection', sortDirection);
    if (page) params = params.set('page', page);
    if (pageSize) params = params.set('pageSize', pageSize);

    return this.http.get<Page<User>>(`${apiUrl}/company/${companyId}/user`, {params: params, withCredentials: true}).pipe(map(usersPage => {
      usersPage.content.forEach(e => e.role = convertUserRoleToEnum(e.role));
      return usersPage;
    }))
  }

  getCompanyUsersInProjectByParams(projectId: number, userRole: UserRole, query: string, sortField: string, sortDirection: string, page: number, pageSize: number): Observable<Page<User>> {
    let params = new HttpParams();
    if (userRole) params = params.set('userRole', userRole);
    if (query && query.length > 0) params = params.set('query', query);
    if (sortField) params = params.set('sortField', sortField);
    if (sortDirection) params = params.set('sortDirection', sortDirection);
    if (page) params = params.set('page', page);
    if (pageSize) params = params.set('pageSize', pageSize);

    return this.http.get<Page<User>>(`${apiUrl}/project/${projectId}/employee`, {params: params, withCredentials: true}).pipe(map(usersPage => {
      usersPage.content.forEach(e => e.role = convertUserRoleToEnum(e.role));
      return usersPage;
    }))
  }

  getUserById(userId: number): Observable<User> {
    return this.http.get<User>(`${apiUrl}/user/${userId}`, {withCredentials: true}).pipe(map(user => {
      user.role = convertUserRoleToEnum(user.role);
      return user;
    }))
  }

  getCurrentUser(): Observable<User> {
    return this.http.get<User>(`${apiUrl}/user/me`, {withCredentials: true}).pipe(map(user => {
      user.role = convertUserRoleToEnum(user.role);
      return user;
    }))
  }

  updateUserInfo(user: User): Observable<User> {
    return this.http.put<User>(`${apiUrl}/user`, user, {withCredentials: true}).pipe(map(response => {
      response.role = convertUserRoleToEnum(response.role);
      return response;
    }))
  }

  updateUserPassword(passwordInfo: any) {
    return this.http.put(`${apiUrl}/user/password`, null, {params:
          {oldPassword: passwordInfo.oldPassword, newPassword: passwordInfo.newPassword }, withCredentials: true})
  }

  resetPasswordSendEmail(email: string) {
    return this.http.post(`${apiUrl}/resetPassword`, null, {params: {email: email, SkipNotFoundNotification: 'true'}, withCredentials: true})
  }

  createNewPassword(code: string, password: string) {
    return this.http.post(`${apiUrl}/resetPassword/changePassword`, null, {params: {code: code, password: password}, withCredentials: true})
  }

  deleteUser(userIds: number[]) {
    let params = new HttpParams();
    userIds.forEach(e => params = params.append('userIds', e));

    return this.http.delete(`${apiUrl}/user`,{
      params: params,
      observe: "response",
      withCredentials: true})
  }



  getUserHomePageUrl(user: User): string {
    switch (user.role) {
      case UserRole.ROLE_SUPERADMIN: return "/system/dashboard";
      default: return `/system/company/${user.company.id}/dashboard`
    }
  }

  getUserNotFoundUrl(user: User): string {
    switch (user.role) {
      case UserRole.ROLE_SUPERADMIN: return "/system/not-found";
      default: return `/system/company/${user.company.id}/not-found`
    }
  }

  setUserProjects(projectIds: number[], employeeId: number, version: number) {
    return this.http.put(`${apiUrl}/user/projects`, employeeId, {params: {projectIds: projectIds, version: version}, observe: "response", withCredentials: true})
  }

  //get user location

  getUserIp() {
    return this.http.get<{ip: string}>('https://api.ipify.org?format=json')
  }

  getUserLocationByIp(userIp: string) {
    return this.http.get<UserLocation>(`https://api.iplocation.net/?ip=${userIp}`)
  }
}
