import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormFile} from "../../../../../../../../_models/material";
import {User, UserRole} from "../../../../../../../../_models/user";
import {GlobalStore} from "../../../../../../../../global.store";
import {Subject, takeUntil} from "rxjs";
import {SelectedArea} from "../../../../types";
import {AreasService} from "../../../../../../../../_services/areas.service";
import {NotificationService} from "../../../../../../../../_services/notification.service";
import {NgScrollbar} from "ngx-scrollbar";

@Component({
  selector: 'app-area-add-files',
  templateUrl: './area-add-files.component.html',
  styleUrls: ['./area-add-files.component.scss']
})
export class AreaAddFilesComponent {
  @ViewChild('fileInput') fileInputRef: ElementRef;
  @ViewChild('ngScrollBar') ngScrollBar: NgScrollbar;
  @Input() selectedArea: SelectedArea;
  @Output() updateAttachments: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileToPreview: EventEmitter<any> = new EventEmitter<any>()

  private readonly destroy$ = new Subject<void>();

  filesList: FormFile[] = [];
  errorUploadFileSize = false;
  errorUploadFilesAmount = false;
  imageFormatsToRenderImage: string[] = ['jpeg', 'png', 'svg', 'webp', 'jpg'];
  bitePerMb = 1048576;
  currentUser: User;

  constructor(private globalStore: GlobalStore, private areaService: AreasService, private notifService: NotificationService) {
    this.globalStore.currentUser$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.currentUser = data
    })
  }



  toggleStatusLoadingFile(files: any) {
    for (let file of files) {
      if (file.size > 25 * this.bitePerMb) {
        this.errorUploadFileSize = true;
        return
      }

      this.errorUploadFileSize = false;

      if (this.filesList.length === 4) {
        this.errorUploadFilesAmount = true;
        return
      }
      this.errorUploadFilesAmount = false;
      const newFile = new FormFile()
      newFile.name = file.name;
      newFile.sizeBytes = file.size;
      newFile.fileToSave = file;
      newFile.owner.id = this.currentUser.id;
      newFile.timestamp = new Date().getTime();
      this.filesList.push(newFile);

      if (files.length !== 0) {
        const reader = new FileReader();

        reader.onloadstart = (e) => newFile.timeLeft = Math.floor(e.timeStamp / 1000);
        reader.onprogress = (e) => {
          if (e.lengthComputable) newFile.progress = Math.floor((e.loaded / e.total) * 100);
        };

        reader.onload = (e) => {
          const binaryString = Array.from(new Uint8Array(e.target.result as ArrayBuffer)).map(byte => String.fromCharCode(byte)).join('');
          if(file.name.includes('png') || file.name.includes('jpg') || file.name.includes('jpeg')) newFile.imgSrc = 'data:image/png;base64,' + btoa(binaryString);
        }

        reader.readAsArrayBuffer(file);
      }
    }

    this.sendFiles(this.filesList)
  }

  isFileCanBePreviewed(fileName: string) {
    let result = false;
    this.imageFormatsToRenderImage.forEach(e => {
      if (fileName.endsWith(e)) {
        result = true;
        return;
      }
    })
    return result;
  }

  removeFile(file: FormFile) {
    let objIdInFilesToSend = this.filesList.indexOf(file);

    if (objIdInFilesToSend !== -1) {
      this.filesList.splice(objIdInFilesToSend, 1);
    } else this.selectedArea.area.files.splice(this.selectedArea.area.files.indexOf(file), 1);
    this.fileInputRef.nativeElement.value = '';

    this.areaService.deleteAreaFile(this.selectedArea.area.id, file.id).subscribe(() => {
      this.selectedArea.area.attachmentsAmount = this.selectedArea.area.files.length
      this.notifService.successNotification('Changes have been saved');
      this.ngScrollBar.update()
      this.updateAttachments.emit(this.selectedArea.area.files.length)
    })
  }

  getSumFileList(): FormFile[] {
    return [...this.selectedArea.area.files, ...this.filesList];
  }

  sendFiles(files: FormFile[]) {
    const fd = new FormData();
    // @ts-ignore
    for (const file of files) fd.append('files', file.fileToSave)

    this.areaService.updateAreaFiles(this.selectedArea.area.id, fd).subscribe((data) => {
      this.selectedArea.area.files = [...this.selectedArea.area.files, ...data]
      console.log('this.selectedArea.area.attachmentsAmount in create 1', this.selectedArea.area.attachmentsAmount)
      this.selectedArea.area.attachmentsAmount = this.selectedArea.area.files.length
      console.log('this.selectedArea.area.attachmentsAmount in create 2', this.selectedArea.area.attachmentsAmount)
      this.notifService.successNotification('Changes have been saved')
      this.ngScrollBar.update()
      this.updateAttachments.emit(this.selectedArea.area.files.length)
      this.filesList = [];
    })
  }

  protected readonly UserRole = UserRole;
}
