<div class="settings-container">
    <div class="settings-wrapper" *ngIf="project">
        <div class="settings-wrapper__header">
            <p class="settings-wrapper__title">About Project</p>
            <button *ngIf="currentUser.role !== UserRole.ROLE_PROJECT_MANAGER && currentUser.role !== UserRole.ROLE_EMPLOYEE" type="button" class="settings-wrapper__btn-edit" (click)="projectEditFormOpened = true">
                <svg class="edit-icon">
                    <use spriteVersion iconName="edit-icon"></use>
                </svg>
                Edit
            </button>
        </div>

        <div class="table-details-wrapper">

            <ul class="table-details">
                <li class="table-details-info-row">
                  <p class="table-details__title">ID</p>
                  <p class="table-details__info">{{project.publicId}}</p>
                </li>
                <li class="table-details-info-row">
                  <p class="table-details__title">Project Name</p>
                  <p class="table-details__info">{{project.name}}</p>
                </li>
                <li class="table-details-info-row">
                  <p class="table-details__title">Project #</p>
                  <p class="table-details__info">{{project.number}}</p>
                </li>
                <li class="table-details-info-row">
                  <p class="table-details__title">Code</p>
                  <p class="table-details__info">{{project.publicKey || '-'}}</p>
                </li>
                <li class="table-details-info-row">
                  <p class="table-details__title">Company Name</p>
                  <p class="table-details__info">{{project.company.name}}</p>
                </li>

                <li class="table-details-info-row">
                  <p class="table-details__title">Address</p>
                  <p class="table-details__info">{{project.address1}}, {{project.address2}}</p>
                </li>
                <li class="table-details-info-row">
                  <p class="table-details__title">City</p>
                  <p class="table-details__info">{{project.city}}</p>
                </li>
                <li class="table-details-info-row">
                  <p class="table-details__title">ZIP Code</p>
                  <p class="table-details__info">{{project.zipCode}}</p>
                </li>
                <li class="table-details-info-row">
                  <p class="table-details__title">State</p>
                  <p class="table-details__info">{{project.state}}</p>
                </li>

                <li class="table-details-info-row">
                  <p class="table-details__title">Client</p>
                  <p class="table-details__info">{{project.clientName || '-'}}</p>
                </li>
                <li class="table-details-info-row">
                  <p class="table-details__title">Start Date</p>
                  <p class="table-details__info">{{(project.startDate | dateFormatter) || '-'}}</p>
                </li>
                <li class="table-details-info-row">
                  <p class="table-details__title">End Date</p>
                  <p class="table-details__info">{{(project.endDate | dateFormatter) || '-'}}</p>
                </li>

            </ul>
        </div>

    </div>


  <div class="settings-wrapper" *ngIf="!project">
    <div class="settings-wrapper__header">
      <p class="settings-wrapper__title">About Project</p>
    </div>
    <div class="table-details-wrapper">
      <app-loader-profile></app-loader-profile>
    </div>
  </div>

    <button *ngIf="currentUser.role !== UserRole.ROLE_PROJECT_MANAGER && currentUser.role !== UserRole.ROLE_EMPLOYEE" [disabled]="deletingBtnDisabled" type="button" id="btnDeleteProjectOpenModal" class="btn-delete" (click)="isOpenConfirmDeletingModal = true">
        <svg class="btn-delete__icon">
            <use spriteVersion iconName="trash-delete-icon"></use>
        </svg>
        Delete Project
    </button>
</div>

<div class="backdrop manual" [attr.show]="isOpenConfirmDeletingModal">
    <div [enabled]="isOpenConfirmDeletingModal" (appOutsideClick)="isOpenConfirmDeletingModal = false" class="modal-narrow">
        <button type="button" id="btnCloseModal" class="close-modal-btn close-modal-deleting" (click)="isOpenConfirmDeletingModal = false">
            <svg class="close-modal-icon">
                <use spriteVersion iconName="modal-close"></use>
            </svg>
        </button>

        <div class="confirmDeleting-wrapper">
            <p class="confirmDeleting-title">Delete the Project “{{project?.name}}”?</p>
            <p class="confirmDeleting-text">
                You really want to delete this project? All information about the project, its plan and access to it will no longer be available
            </p>

        </div>

        <div class="form-btn-wrapper">
            <button type="button" id="btnCancel" class="btn-cancel" (click)="isOpenConfirmDeletingModal = false">Cancel</button>
            <button type="submit" id="btnDeleteProject" class="btn-delete" [disabled]="deletingBtnDisabled" (click)="deleteProject()">
                <svg class="btn-delete__icon">
                    <use spriteVersion iconName="trash-delete-icon"></use>
                </svg>
                Delete Project
            </button>
        </div>
    </div>
</div>
<app-project-form *ngIf="projectEditFormOpened" (close)="projectEditFormOpened = false" (updated)="projectEditFormOpened = false; globalStore.updateProject($event); project = $event" [project]="project"></app-project-form>


