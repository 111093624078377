import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {MaterialStoredLocation, MaterialUnit} from "../_models/material";
import {apiUrl} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MaterialUnitService {

  constructor(private http: HttpClient) { }

  getAllMaterialUnits(projectId: number):Observable<MaterialUnit[]> {
    return this.http.get<MaterialUnit[]>(`${apiUrl}/material/units`, {params: {projectId: projectId},  withCredentials: true})
  }

  createMaterialUnit(projectId: number, name: string) {
    return this.http.post(`${apiUrl}/material/units`, name,{params: {projectId: projectId}, withCredentials: true})
  }

  deleteMaterialUnit(materialUnitId: number) {
    let params = new HttpParams();
    if (materialUnitId) params = params.set('id', materialUnitId);

    return this.http.delete(`${apiUrl}/material/units`, {
      params: params,
      observe: "response",
      withCredentials: true
    })
  }
}
