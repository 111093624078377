<ng-scrollbar [style.height]="activityPage.content.length > 0 && activityPage.content.length < 4 ? activityPage.content.length*46 + 46 + 'px' : activityPage.content.length === 0 ? '134px' : '218px'" [attr.isIOS]="(isIOS | async)" [autoHeightDisabled]="true" [track]="'all'" class="table-wrapper-with-dots">
  <div class="innerScrollBarWrapper">
    <div class="table-wrapper-with-scroll">
      <ul class="table-activity" *ngIf="!activityPage.empty; else emptyList">
        <li class="activity-item dashboard" *ngFor="let activity of activityPage.content; let i = index">
          <p *ngIf="i == activityPage.content.length - 2" (visible)="getNextPage()"></p>
          <p class="activity-id-wrapper">
            <a class="activity-id" *ngIf="activity.userHasAccess" (click)="router.navigateByUrl('/system' + activity.relatedToLink)">{{activity.relatedToId}}</a>
            <span class="activity-id" *ngIf="!activity.userHasAccess">{{activity.relatedToId}}</span>
          </p>
          <ng-template [nestedEllipsis]>
            <span class="activity-text" [innerHTML]="sanitizeHtmlText(activity.text)"></span>
          </ng-template>
          <p class="activity-project-name">{{activity.project.name}}</p>
          <p class="activity-createdAt">{{getTimeCreatedAtActivity(activity.createdAt)}}</p>
        </li>
      </ul>
    </div>
  </div>
</ng-scrollbar>

<app-loader [isDashboardListLoading]="true" *ngIf="isLoading"></app-loader>

<ng-template #emptyList>
  <div class="empty-list-wrapper">
    <p class="empty-list-text" *ngIf="activityPage.empty; else notFound">The list of activities is empty.</p>
    <ng-template #notFound>
      <p class="empty-list-text">No Activities found</p>
    </ng-template>
    <img class="empty-list-icon" src="assets/images/empty-list.png" alt="empty-list">
  </div>
</ng-template>
