import {Component, OnDestroy} from '@angular/core';
import {UsersService} from "../../../../_services/users.service";
import {User, UserRole} from "../../../../_models/user";
import {NotificationService} from "../../../../_services/notification.service";
import {ActivatedRoute, Router} from "@angular/router";
import {roleDescByEnum} from "../../../../config/constants";
import {Project} from "../../../../_models/project";
import {GlobalStore} from "../../../../global.store";
import {Subject, take} from "rxjs";
import {AuthService} from "../../../../_services/auth.service";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>()
  currentUser: User;
  userIdFromUrl: number;
  userInfo: User;
  userImg!: string;
  notificationSettingsOpened = false;
  userEditFormOpened = false;
  userChangePasswordFormOpened = false;
  isOpenConfirmDeletingModal: boolean = false;
  deletingBtnDisabled = false;
  isLoading = true;


  constructor(readonly globalStore: GlobalStore,
              private authService: AuthService,
              private userService: UsersService,
              private notifService: NotificationService,
              private route: ActivatedRoute,
              private router: Router) {
    this.userIdFromUrl = Number(this.route.snapshot.paramMap.get('userId'));
    this.globalStore.currentUser$.pipe(take(1)).subscribe((user: User) => {
      this.currentUser = user;
      if (this.currentUser.role !== UserRole.ROLE_SUPERADMIN && this.userIdFromUrl === 0) this.notificationSettingsOpened = this.route.snapshot.queryParams['notificationSettings'] !== undefined;
      this.router.navigate([], { queryParams: {} });
      this.getUserInfo();
    });
  }

  getUserInfo() {
    this.userService.getUserById(this.userIdFromUrl !== 0 ? this.userIdFromUrl : this.currentUser.id).subscribe((user) => {
      this.isLoading = true;
      this.userInfo = user
      this.isLoading = false;
    }, error => this.isLoading = false);
  }

  findUserProject(projectId: number) {
    return this.currentUser.projects.find(project => project.id === projectId)
  }

  isShowDeleteBtn() {
    return (this.currentUser && this.currentUser.role === UserRole.ROLE_ADMIN && this.userInfo && this.userInfo.role !== UserRole.ROLE_ADMIN && this.userIdFromUrl) || (!this.userIdFromUrl && this.currentUser && this.currentUser.role !== UserRole.ROLE_SUPERADMIN)
  || (this.userIdFromUrl && this.currentUser && this.currentUser.role === UserRole.ROLE_PROJECT_MANAGER && this.currentUser.id === this.userIdFromUrl)
  }

  deleteUser() {
    this.deletingBtnDisabled = true;
    this.userService.deleteUser([this.userInfo.id]).subscribe(() => {
        if(this.userInfo.id === this.currentUser.id) {
          this.authService.logout();
          return
        }
        if(this.currentUser.role === UserRole.ROLE_SUPERADMIN ) {
          this.notifService.successNotification("Changes have been saved")
          this.router.navigateByUrl('/system/users')
          return;
        }
        if(this.currentUser.role === UserRole.ROLE_ADMIN ) {
          this.notifService.successNotification("Changes have been saved")
          this.router.navigateByUrl(`/system/company/${this.currentUser.company.id}/users`)
        }
      }, () => {
        this.isOpenConfirmDeletingModal = false;
        this.deletingBtnDisabled = false;
      })
  }

  isShowEditBtn() {
    return this.currentUser?.role !== UserRole.ROLE_PROJECT_MANAGER || this.userInfo?.id === this.currentUser?.id;
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly UserRole = UserRole;
  protected readonly getRoleDescByEnum = roleDescByEnum;
  protected readonly User = User;
  protected readonly Project = Project;
}
