import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Room} from "../_models/room";
import {apiUrl} from "../../environments/environment";
import {Document, DocumentType} from "../_models/document";
import {Project} from "../_models/project";
import {Observable} from "rxjs";
import {Page} from "../_models/page";

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(private http: HttpClient) {}

  getDocumentsList(projectId: number, query: string, type: DocumentType, sortField: string, sortDirection: string, page: number, pageSize: number): Observable<Page<Document>>  {
    let params = new HttpParams()
    if (type) params = params.set('type', type);
    if (query && query.length > 0) params = params.set('query', query);
    if (sortField) params = params.set('sortField', sortField);
    if (sortDirection) params = params.set('sortDirection', sortDirection);
    if (page) params = params.set('page', page);
    if (pageSize) params = params.set('pageSize', pageSize);

    return this.http.get<Page<Document>>(`${apiUrl}/project/${projectId}/document/list`, {params: params, withCredentials: true})
  }

  createDocument(projectId: number, name: string, link: string, files: FormData, filesLength: number): Observable<Document> {
    let params = new HttpParams()
    if (name) params = params.set('name', name);
    if (link) params = params.set('link', link);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    if(filesLength > 0) return this.http.post<Document>(`${apiUrl}/project/${projectId}/document`,files, {headers: headers, withCredentials: true})
    else return this.http.post<Document>(`${apiUrl}/project/${projectId}/document`,null, {params: params, withCredentials: true})
  }

  updateDocument(projectId: number, documentId: number, name: string) {
    let params = new HttpParams()
    if(documentId) params = params.set('documentId', documentId);
    if(name) params = params.set('name', name);
    return this.http.put(`${apiUrl}/project/${projectId}/document`, null, {params: params, observe: "response", withCredentials: true})
  }

  deleteDocuments(projectId: number, documentIds: number[]) {
    let params = new HttpParams();
    documentIds.forEach(e => params = params.append('ids', e));
    return this.http.delete(`${apiUrl}/project/${projectId}/document`, {params: params, withCredentials: true})
  }

  downloadFile(url: string) {
    return this.http.get(url, { responseType: 'blob' })
  }
}
