import {Directive, ElementRef, Input} from "@angular/core";
import {default as  hashes} from 'src/hashes.json';

@Directive({
  selector: '[spriteVersion]'
})
export class SpriteVersionDirective {

  @Input()
  set iconName(value: string) {
    (this.el.nativeElement as HTMLElement).setAttribute('href', '/assets/sprite.svg?v=' + hashes.sprite + '#' + value);
  }

  constructor(private el: ElementRef) {
  }
}
