<div class="backdrop" [attr.showBackground]="isTemplateEditInRoomFormOpened">
  <div class="modal">
    <form class="form-invite" #form="ngForm" firstInputAutofocus scrollToFirstInvalidInput appAutoTrim *ngIf="roomForm" [formGroup]="roomForm" (ngSubmit)="submitForm()">
      <button type="button" id="btnClose" class="close-modal-btn" (click)="close.emit()">
        <svg class="close-modal-icon">
          <use spriteVersion iconName="modal-close"></use>
        </svg>
      </button>
      <p class="form-modal-way-wrapper" *ngIf="isAnyTypeEditRoom() || isTemplate">
        <ng-container *ngIf="(globalStore.currentUser$ | async).role === UserRole.ROLE_SUPERADMIN">
          <span class="form-modal-way">{{(globalStore.company$ | async).name}}</span>
          /
        </ng-container>
        <span class="form-modal-way">{{(globalStore.project$ | async).name}}</span>
         /
        <ng-container *ngIf="isShowBreadcrumb">
          <span class="form-modal-way">{{(projectViewStore.currentFloor$ | async).name}}</span>
          /
          <span class="form-modal-way">{{unit.name}}</span>
          /
        </ng-container>
          <span class="form-modal-way">{{initialRoomName}}</span>
        <ng-container  *ngIf="isShowBreadcrumb">
            /
          <span class="form-modal-way item-code-way">{{selectedRoomCopy.publicId}}</span>
        </ng-container>
      </p>
      <div class="form-inner-wrapper">
        <div class="form-modal-title-wrapper" [attr.paddingTop]="!!isAnyTypeEditRoom() || !!isTemplate">
          <p class="form-modal-title">{{isAnyTypeEditRoom() ? 'Edit Room' : isTemplate ? 'Edit Template' : 'Add Room'}}</p>
        </div>

        <div class="main-fields-wrapper">
          <p class="main-error" *ngIf="roomForm.invalid && form.submitted" id="mainError">
            <svg class="error-icon">
              <use spriteVersion iconName="error-icon"></use>
            </svg>
            All fields are required
          </p>

          <div class="room-name-w-subtitle-field-wrapper">
            <div class="field-wrapper field-room-name-wrapper" [attr.isTemplate]="isTemplate" [attr.isSelectedTemplate]="this.isTemplate && selectedRoomCopy.id ? 'true' : 'false'">
              <label class="form-label">{{selectedRoomCopy.id && isTemplate ? 'Template ' : 'Room '}}Name</label>
              <input [ngClass]="isTemplate ? 'form-input-modal form-input-modal--template' : 'form-input-modal'" formControlName="name" formInput="ROOM_AREA_NAME" id="roomName" placeholder="Room Name">
              <p class="small-error-text-field" id="roomNameError" *ngIf="roomForm.controls['name'].invalid && form.submitted">{{roomForm.controls['name'].getError('error')}}</p>
            </div>
            <div class="field-wrapper" *ngIf="!isTemplate">
              <label class="form-label">Subtitle <span class="project-optional-text">(Optional)</span></label>
              <input class="form-input-modal" formControlName="subtitle" formInput="ROOM_AREA_NAME" id="roomSubtitle" placeholder="Subtitle">
              <p class="small-error-text-field" id="roomSubtitleError" *ngIf="roomForm.controls['subtitle'].invalid && form.submitted">{{roomForm.controls['subtitle'].getError('error')}}</p>
            </div>
          </div>
          <ng-template [ngIf]="!createRoomBasedOnTemplate">
            <div class="field-wrapper">
              <label class="form-label" *ngIf="selectedRoomCopy.areas.length > 0">Areas <span class="project-optional-text">(Optional)</span></label>
              <ul class="areasFieldList" cdkDropList cdkDropListOrientation="vertical" (cdkDropListDropped)="dropAreasField($event)">
                <li tabindex="1" *ngFor="let areaForm of selectedRoomCopy.areas; let i = index">
                  <div class="area-form-row" cdkDrag [cdkDragDisabled]="disabledDrag" cdkDragPreviewContainer="parent" cdkDragPreviewClass="area-form-row-previewDrop" >
                      <div class="area-form-dragIcon-with-input-wrapper">
                        <svg class="drag-icon" *ngIf="selectedRoomCopy.areas.length > 1">
                          <use spriteVersion iconName="drag-icon"></use>
                        </svg>
                        <input class="form-input-modal" #inputAreaField [id]="i" placeholder="Area Name" formInput="ROOM_AREA_NAME" [style.border-color]="areaForm.errorName ? '#E41616' : ''" (focus)="disabledDrag = true" (blur)="disabledDrag = false"  [value]="areaForm.name" (input)="onInputAreaName($event, i); inputAreaField.setAttribute('invalid', String(inputAreaField.value.length < FORM_ERRORS.ROOM_AREA_NAME.MIN_LENGTH.VALUE))">
                      </div>
                      <p class="small-error-text-field" id="floorNameError" [style.padding-left]="selectedRoomCopy.areas.length > 1 ? '25px' : '0'" *ngIf="inputAreaField.getAttribute('invalid') === 'true' && inputAreaField.value !== ''">{{FORM_ERRORS.ROOM_AREA_NAME.MIN_LENGTH.MESSAGE}}</p>
                      <p class="small-error-text-field" [ngClass]="selectedRoomCopy.areas.length > 1 ? 'areaHasError' : '' " id="statusErrorName" *ngIf="areaForm.errorName && form.submitted">{{'Area name ' + areaForm.name + ' already in use'}}</p>
                      <button type="button" id="deleteAreaField" class="delete-area-field__btn" (click)="deleteArea(i)" *ngIf="selectedRoomCopy.areas.length > 1">
                        <svg class="delete-area-field__icon">
                          <use spriteVersion iconName="trash-delete-icon"></use>
                        </svg>
                      </button>
                  </div>
                </li>
              </ul>

              <button type="button" class="add-area-field-btn" (click)="addEmptyArea()" id="addAreaField">
                <svg class="add-icon">
                  <use spriteVersion iconName="close-icon"></use>
                </svg>
                Add Area
              </button>
            </div>

            <div class="checkbox-container" *ngIf="!isTemplate">
              <label class="checkbox-label">
                <input type="checkbox" id="updateTemplate" class="visually-hidden" [(ngModel)]="isCreateTemplate" (ngModelChange)="updateTemplateChanged()" [ngModelOptions]="{standalone: true}"/>
                <span class="checkbox"></span>
                <span class="checkbox-text" id="rememberMeError" >Save as a template</span>
              </label>
            </div>
          </ng-template>

          <div class="template-fields-wrapper" *ngIf="isCreateTemplate || createRoomBasedOnTemplate">
            <div class="field-wrapper field-wrapper-template-name">
              <label class="form-label">
                <span class="label-text">Template Name</span>
                <span class="switchBtnEditTemplate" (click)="openEditTemplateFromEditRoom()" *ngIf="createRoomBasedOnTemplate">Edit Template</span>
              </label>
              <ng-template [ngIf]="!createRoomBasedOnTemplate">
                <input class="form-input-modal" formControlName="templateName" formInput="NAME" id="templateName" placeholder="Enter new template name">
                <p class="small-error-text-field" id="templateNameError" *ngIf="roomForm.controls['templateName'].invalid && form.submitted">{{roomForm.controls['templateName'].getError('error')}}</p>
              </ng-template>
              <div class="ng-select-wrapper ng-select-wrapper--wider" *ngIf="createRoomBasedOnTemplate">
                <ng-select class="custom select-without-absolute-last-option"
                           id="templateSelect"
                           bindLabel="name"
                           bindValue="id"
                           [items]="roomTemplatesShortList"
                           formControlName="templateName"
                           [clearable]="false"
                           notFoundText="No results matching"
                           [editableSearchTerm]="true"
                           [searchable]="true"
                           (change)="selectTemplate($event)"
                           placeholder="Select template">
                </ng-select>
              </div>
            </div>

            <ng-template [ngIf]="createRoomBasedOnTemplate">

              <label class="form-label form-label-template-areas" *ngIf="template?.areas.length > 0">Areas</label>
              <ul class="areasFieldList templateAreaList">
                <li tabindex="1" *ngFor="let areaFormTemplate of template?.areas; let i = index">
                  <div class="area-form-row">
                    <div class="area-form-dragIcon-with-input-wrapper">
                      <input class="form-input-modal form-input-modal--disabled" [disabled]="!isDetached" [id]="i" [value]="areaFormTemplate.name">
                    </div>
                  </div>
                </li>
              </ul>
              <div class="detach-btn-with-info-wrapper">
                <button type="button" class="detach-btn" (click)="detachRoom()">
                  <svg class="detach-icon">
                    <use spriteVersion iconName="link-broken"></use>
                  </svg>
                  Detach
                </button>

                <p class="info-room-text" [style.padding]="0" >
                  <svg class="exclamaition-icon" [ngStyle]="{width: '20px'}">
                    <use spriteVersion iconName="exclamation-circle"></use>
                  </svg>
                  <span>By clicking "Detach" it will remove this room from being a template</span>
                </p>
              </div>
            </ng-template>
          </div>

          <p class="info-room-text" *ngIf="!createRoomBasedOnTemplate">
            <svg class="exclamaition-icon" [ngStyle]="{width: '20px'}">
              <use spriteVersion iconName="exclamation-circle"></use>
            </svg>
            <span>
            {{selectedRoomCopy.id && isTemplate ? 'All previously created rooms that use this template will be automatically updated.' : 'You can choose to save this room as a template for future use, or save without creating a template'}}
            </span>
          </p>
        </div>
      </div>

      <div class="form-btn-wrapper">
        <button type="button" class="btn-cancel" id="btnCancel" (click)="close.emit()">Cancel</button>
        <button type="submit" class="btn-invite" id="btnInvite" [disabled]="submitBtnDisabled" loader="white" [showLoader]="submitBtnDisabled">{{selectedRoomCopy.id ? 'Save and Update' : 'Save'}}</button>
      </div>
    </form>
  </div>
</div>

<app-room-form *ngIf="isTemplateEditInRoomFormOpened" [popupOpened]="PopupOpened.TEMPLATE_EDIT" (updated)="isTemplateEditInRoomFormOpened = false; globalStore.loadProjectProgress(); getTemplateById(template.id)"
               (close)="isTemplateEditInRoomFormOpened = false"
               [selectedRoom]="{room: template, unitId: undefined, unitName: undefined}"></app-room-form>

