<div class="backdrop" *ngIf="designSettings">
  <div class="modal">
    <div class="design-settings-wrapper">
      <button type="button" id="btnClose" class="close-modal-btn" (click)="close.emit()">
        <svg class="close-modal-icon">
          <use spriteVersion iconName="modal-close"></use>
        </svg>
      </button>

      <div class="design-settings-inner-wrapper">
        <p class="form-modal-title">Design Settings</p>

        <ul class="design-switcher-list">
          <li class="design-switcher-item">
            <p class="design-text">
              <svg class="design-img">
                <use href="assets/icons/showPercentage.svg#showPercentage"></use>
              </svg>
              Show Percentage
            </p>
            <ng-toggle
              [value]="designSettings.showPercentage"
              [color]="{unchecked: '#D8D8D8', checked: '#37A702'}"
              (change)="switchNotifications('percentage')"
            ></ng-toggle>
          </li>
          <li class="design-switcher-item">
            <p class="design-text">
              <svg class="design-img">
                <use href="assets/icons/design-settings-2.svg#designSettings"></use>
              </svg>
              Show Comment Icons
            </p>

            <ng-toggle
              [value]="designSettings.showCommentIcons"
              [color]="{unchecked: '#D8D8D8', checked: '#37A702'}"
              (change)="switchNotifications('commentIcons')"
            ></ng-toggle>
          </li>

          <li class="design-switcher-item">
            <p class="design-text">
              <svg class="design-img">
                <use href="assets/icons/design-settings-3.svg#designSettings"></use>
              </svg>
              Show Attachment Icons
            </p>

            <ng-toggle
              [value]="designSettings.showAttachmentIcons"
              [color]="{unchecked: '#D8D8D8', checked: '#37A702'}"
              (change)="switchNotifications('attachmentIcons')"
            ></ng-toggle>
          </li>

          <li class="design-switcher-item" *ngIf="!(globalStore.isNotDesktop$ | async)">
            <p class="design-text">
              <svg class="design-img">
                <use href="assets/icons/design-settings-4.svg#designSettings"></use>
              </svg>
              Show POP UP
            </p>

            <ng-toggle
              [value]="designSettings.showPopUp"
              [color]="{unchecked: '#D8D8D8', checked: '#37A702'}"
              (change)="switchNotifications('popup')"
            ></ng-toggle>
          </li>
        </ul>

      </div>


      <div class="form-btn-wrapper">
        <button type="button" class="btn-cancel" id="btnCancel" (click)="close.emit()">Cancel</button>
        <button type="submit" loader="white" [disabled]="submitBtnDisabled" [showLoader]="submitBtnDisabled" (click)="submit()" class="btn-invite" id="btnSave">Save</button>
      </div>
    </div>
  </div>
</div>
