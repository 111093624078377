import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormatter'
})
export class DateFormatterPipe implements PipeTransform {
  transform(milliseconds: number): string {
    if (!milliseconds) return "";

    const date = new Date(milliseconds);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear().toString().slice(-2);
    return `${month.toString().padStart(2, '0')}.${day.toString().padStart(2, '0')}.${year}`;
  }

}
