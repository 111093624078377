import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {NotificationService} from "../../../../../../_services/notification.service";
import {User, UserRole} from "../../../../../../_models/user";

import {modalAnimation} from 'src/app/config/constants';

import {FORM_ERRORS, minLengthValidator, requiredValidator} from "../../../../../../_util/validators";
import {HttpErrorResponse} from "@angular/common/http";

import {Room} from "../../../../../../_models/room";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

import {Area} from "../../../../../../_models/area";
import {RoomsService} from "../../../../../../_services/rooms.service";
import {TemplatesService} from "../../../../../../_services/templates.service";
import {PopupOpened, ProjectViewStore} from "../project-view.store";
import {GlobalStore} from "../../../../../../global.store";
import {Floor} from "../../../../../../_models/floor";
import {SelectedRoom} from "../../types";

@Component({
  selector: 'app-room-form',
  templateUrl: './room-form.component.html',
  styleUrls: ['./room-form.component.scss'],
  animations: [modalAnimation]
})
export class RoomFormComponent {
  @HostBinding('@modalAnimation') modalAnimation: any;
  @ViewChildren('inputAreaField') inputAreaField: QueryList<ElementRef>;
  @Output() created:EventEmitter<any> = new EventEmitter<any>();
  @Output() updated:EventEmitter<any> = new EventEmitter<any>();
  @Output() close:EventEmitter<any> = new EventEmitter<any>();

  currentFloor: Floor;
  roomTemplatesShortList: Room[] = [];
  private _popupOpened: PopupOpened

  roomForm: FormGroup;
  submitBtnDisabled = false;
  selectedRoomCopy = new Room();
  disabledDrag = false;

  unit: { name: string; id: number };
  initialRoomName: string;
  initialRoomSubtitle: string;

  template: Room;
  selectedTemplateToCreateRoom = new Room();
  isCreateTemplate = false;
  newTemplateName: string;
  newTemplateId: number;
  isTemplate: boolean = false;
  isChangingTemplateName = false;
  createRoomBasedOnTemplate = false;
  isTemplateEditInRoomFormOpened: boolean

  isDetached = false;
  isShowBreadcrumb: boolean = false;
  isEditRoom = false;

  roomNameError = false;
  templateNameError = false;

  @Input()
  set selectedRoom(value: SelectedRoom) {
    if (value?.room) {
      this.selectedRoomCopy = JSON.parse(JSON.stringify(value.room));
      this.unit = {id: value.unitId, name: value.unitName}
      this.initialRoomName = value.room.name;
      this.initialRoomSubtitle = value.room.subtitle;
      this.projectViewStore.loadRoomTemplatesShortList()
      this.fillRoomForm();
      if (this.popupOpened !== PopupOpened.CREATE_EDIT_ROOM_CREATED_BASED_ON_TEMPLATE) this.addEmptyArea()
    } else if(value.unitId) {
      this.unit = {id: value.unitId, name: value.unitName}
      this.fillRoomForm();
      this.addEmptyArea()
    } else {
      this.fillRoomForm();
      this.addEmptyArea()
    }
  }
  get popupOpened() {
    return this._popupOpened
  }

  @Input() set popupOpened(value: PopupOpened) {
    this._popupOpened = value;
    switch (value) {
      case PopupOpened.ROOM_EDIT: {
        this.isShowBreadcrumb = true;
        this.isEditRoom = true;
        break
      }
      case PopupOpened.CREATE_EDIT_ROOM_CREATED_BASED_ON_TEMPLATE: {
        this.createRoomBasedOnTemplate = true;
        this.isShowBreadcrumb = true;
        break
      }
      case PopupOpened.TEMPLATE_EDIT: {
        this.isTemplate = true
        this.isShowBreadcrumb = false;
        break
      }
    }
  }


  constructor(readonly projectViewStore: ProjectViewStore,
              private fb: FormBuilder,
              private roomService: RoomsService,
              private templatesService: TemplatesService,
              private notifService: NotificationService,
              private templateService: TemplatesService,
              readonly globalStore: GlobalStore) {

    this.projectViewStore.roomTemplatesShortList$.subscribe(data => {
      this.roomTemplatesShortList = data;
      if(this.selectedRoomCopy && this.selectedRoomCopy.templateName || this.createRoomBasedOnTemplate) this.setTemplateInfo(this.selectedRoomCopy, this.selectedRoomCopy.templateName)
    })

    this.projectViewStore.currentFloor$.subscribe(data => this.currentFloor = data)
  }

  getTemplateById(templateId: number) {
    if(this.isTemplateEditInRoomFormOpened) return
    this.templateService.getTemplateById(templateId).subscribe(data => {
      this.template = data;
      this.selectedTemplateToCreateRoom = data;
      this.roomForm.controls['templateName'].setValue(templateId)
      this.selectedRoomCopy.areas = data.areas
      if (this.newTemplateId) this.roomForm.controls['name'].setValue(data.name);
      this.createRoomBasedOnTemplate = true;
    })
  }

  //Call when open room based on template
  setTemplateInfo(templateToCreateRoom: Room, templateName: string) {
    if (templateName) this.selectedTemplateToCreateRoom = this.roomTemplatesShortList.find(template => template.name === templateName)
    else if (!templateName && templateToCreateRoom) this.selectedTemplateToCreateRoom = this.roomTemplatesShortList.find(template => template.name === templateToCreateRoom.name)

    if (this.selectedTemplateToCreateRoom) this.getTemplateById(this.selectedTemplateToCreateRoom.id)
  }

  //Call when change template to edit or create room
  selectTemplate(template: Room) {
    this.getTemplateById(template.id)
    if(this.createRoomBasedOnTemplate) this.roomForm.controls['name'].setValue(template.name)
  }

  //Call when select Edit Template in room form based on template
  openEditTemplateFromEditRoom() {
    this.isTemplateEditInRoomFormOpened = true;
  }

  addEmptyArea() {
    const areaField = new Area();
    areaField.name = ''
    this.selectedRoomCopy.areas.push(areaField);
  }

  onInputAreaName(event: any, areaIdx: number) {
    this.selectedRoomCopy.areas.map((area, i) => {
      if(areaIdx === i && area.errorName === true && area.name !== event.target.value.trim()) area.errorName = false;
      if(areaIdx === i) {
        area.name = event.target.value.trim();
        area.changed = true;
      }
    })
  }

  //To drag area in edit room or template
  dropAreasField(event: CdkDragDrop<Area[]>) {
      moveItemInArray(this.selectedRoomCopy.areas, event.previousIndex, event.currentIndex);
  }

  deleteArea(areaIndex: number) {
      this.selectedRoomCopy.areas = this.selectedRoomCopy.areas.filter((area, i) => areaIndex !== i);
  }


  //Subscribe to form changes
  subscribeToFormChanges(){
    this.roomForm.controls['name'].valueChanges.subscribe(value => {
      this.roomNameError = false
      if((this.createRoomBasedOnTemplate || this.isTemplate) && !this.isChangingTemplateName) {
        this.roomForm.controls['templateName'].setValue(value)
        this.newTemplateName = this.roomForm.controls['templateName'].value;
      }
    });

    this.roomForm.valueChanges.subscribe(() => {
      this.selectedRoomCopy.name = this.roomForm.controls['name'].value;
      this.selectedRoomCopy.subtitle = this.roomForm.controls['subtitle'].value;
    })

    this.roomForm.controls['templateName'].valueChanges.subscribe((value) => {
      this.isChangingTemplateName = true;
      this.templateNameError = false
      this.newTemplateName = value
    });
  }

  //Call when push Save as template to add validatores and value to input or remove them
  updateTemplateChanged() {
    if (this.createRoomBasedOnTemplate) {
      this.roomForm.controls['templateName'].setValidators([minLengthValidator('ROOM_AREA_NAME'), requiredValidator()]);
      this.roomForm.controls['name'].enable();
      this.roomForm.controls['subtitle'].enable();
      this.roomForm.controls['templateName'].setValue(this.roomForm.controls['name'].value)
    } else if(this.isCreateTemplate) {
      this.roomForm.controls['templateName'].setValue(this.selectedRoomCopy.name);
    } else {
      this.roomForm.controls['templateName'].setValidators(null);
      this.roomForm.controls['templateName'].setValue(null);
      this.newTemplateName = null
    }

    this.roomForm.controls['templateName'].updateValueAndValidity();
    this.roomForm.controls['name'].updateValueAndValidity();
    this.roomForm.controls['subtitle'].updateValueAndValidity();
  }

  //Fill room form
  fillRoomForm() {
    this.roomForm = this.fb.group({
        id: [{value: this.selectedRoomCopy.id, disabled: true}],
        name: [this.selectedRoomCopy.name || this.selectedTemplateToCreateRoom.name, [minLengthValidator('ROOM_AREA_NAME'), requiredValidator()]],
        subtitle: [this.selectedRoomCopy.subtitle],
        templateName: [this.selectedRoomCopy.templateName ? this.selectedTemplateToCreateRoom.id : this.selectedRoomCopy.name, this.isCreateTemplate ? [minLengthValidator('NAME'), requiredValidator()]: []]
    })
    this.subscribeToFormChanges();
  }

  //Call when push detach to create simple room that isn't based on template
  detachRoom() {
    if(!this.selectedRoomCopy.templateName) {
      this.selectedRoomCopy.id = null
      this.selectedRoomCopy.areas = this.selectedRoomCopy.areas.map(area => {
        area.id = null
        return area
      })
    }
    this.isDetached = true;
    this.createRoomBasedOnTemplate = false;
    this.roomForm.controls['templateName'].setValue('');
    this.addEmptyArea();
  }


  //Submit
  submitForm() {
    if (this.roomNameError) this.roomForm.controls['name'].setErrors({error: 'Room with such name already exists'});
    if (this.templateNameError) this.roomForm.controls['templateName'].setErrors({error: 'Template with such name already exists'});

    let hasAreaError = false;
    this.inputAreaField.map(area => {
      if (area.nativeElement.getAttribute('invalid') === 'true' && area.nativeElement.value !== '') hasAreaError = true;
    })
    if (this.roomForm.invalid || hasAreaError) return;
    this.submitBtnDisabled = true;
    this.selectedRoomCopy.areas = this.selectedRoomCopy.areas.filter(area => area.name !== '');

    if(this.selectedRoomCopy.id && !this.isTemplate && !this.createRoomBasedOnTemplate) {
      this.roomService.updateRoom(this.selectedRoomCopy, this.newTemplateId, this.newTemplateName, this.isDetached).subscribe((data) => this.processSuccessResponse(data, 'update'), error => this.processErrorResponse(error))
    }
    else if(this.selectedRoomCopy.id && this.isTemplate) {
      this.templatesService.updateTemplate(this.selectedRoomCopy.id, this.selectedRoomCopy).subscribe(() => this.processSuccessResponse(null, 'updateTemplate'), error => this.processErrorResponse(error))
    }
    else if(this.createRoomBasedOnTemplate && this.selectedRoomCopy.templateName) {
      this.roomService.updateRoom(this.selectedRoomCopy, this.template.id,  this.template.name, this.isDetached).subscribe((data) => this.processSuccessResponse(data, 'update'), error => this.processErrorResponse(error))
    }
    else if(this.createRoomBasedOnTemplate && !this.selectedRoomCopy.templateName) {
      this.roomService.createRoom(this.unit.id, this.selectedTemplateToCreateRoom.id, this.isDetached, this.selectedTemplateToCreateRoom.name, this.selectedRoomCopy).subscribe((data) => this.processSuccessResponse(data, 'create'), error => this.processErrorResponse(error))
    }
    else this.roomService.createRoom(this.unit.id, this.newTemplateId, this.isDetached, this.newTemplateName?.trim(), this.selectedRoomCopy).subscribe((data) => this.processSuccessResponse(data, 'create'), error => this.processErrorResponse(error));
  }

  //Process success response
  processSuccessResponse(data: Room, event: 'update' | 'updateTemplate' | 'create') {
    switch (event) {
      case "update":
       this.projectViewStore.updateSelectedObject({obj: {room: data, unitId: this.unit.id, unitName: this.unit.name}})
        this.projectViewStore.updateRoom({room: data, unitId: this.unit.id, unitName: this.unit.name});
        if (this.isCreateTemplate) this.projectViewStore.loadRoomTemplatesShortList();
        this.updated.emit();
        break;
      case "updateTemplate":
        this.projectViewStore.reloadCurrentFloor()
        this.projectViewStore.loadRoomTemplatesShortList()
        this.updated.emit();
        break;
      case "create":
        this.projectViewStore.addRoom({room: data, unitId: this.unit.id, unitName: this.unit.name});
        this.projectViewStore.loadRoomTemplatesShortList();
        this.created.emit(data.id);
        break;
    }
    this.notifService.successNotification('Changes have been saved')
  }

  //Process error response
  processErrorResponse(error: HttpErrorResponse) {
    this.submitBtnDisabled = false;
    if (error.status === 400){
      if (error.error.error === 'Name already in use') {
        this.roomForm.controls['name'].setErrors({error: 'Room with such name already exists'});
        this.roomNameError = true;
      }
      else if (error.error.error === 'Name already in use') {
        this.roomForm.controls[this.isTemplate ? 'name' : 'templateName'].setErrors({error: 'Template with such name already exists'});
        this.templateNameError = true;
      }

      this.selectedRoomCopy.areas.map(area => {
        if (error.error.error.includes(area.name) && area.changed) area.errorName = true;
      })
    }
  }

  //Return true if open Edit simple room or room based on template, and return false if open edit Template or Add room
  isAnyTypeEditRoom() {
    return (this.selectedRoomCopy.id && !this.isTemplate && this.selectedRoomCopy.templateName) || this.isEditRoom;
  }

    protected readonly document = document;
    protected readonly FORM_ERRORS = FORM_ERRORS;
    protected readonly String = String;
  protected readonly PopupOpened = PopupOpened;
  protected readonly UserRole = UserRole;
}







