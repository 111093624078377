<div class="floorsSwiper-with-addFloorsBtn-and-btnStatuses-wrapper">
  <div [class.cut-width]="currentUser.role !== UserRole.ROLE_EMPLOYEE" class="floors-wrapper">
    <div class="swiper swiper-floor" [attr.isStandartUser]="currentUser.role === UserRole.ROLE_EMPLOYEE">
      <ul cdkDropList [cdkDropListAutoScrollStep]="40" cdkDropListOrientation="horizontal" class="floors-list"
          (cdkDropListDropped)="dropFloor($event)">
          <app-floors-swiper-el *ngFor="let floor of floorsShortList"
                                cdkDrag
                                [cdkDragDisabled]="(isMobile | async) || isCdkDragDisabled"
                                [floor]="floor"
                                (deleteFloor)="selectedFloor = $event; deletingPopupOpened=true"
                                [floorsShortList]="floorsShortList"
                                [currentFloor]="currentFloor"
                                (changeFloor)="goToFloor($event)"
                                [cdkDragData]="floor"
                                [isCdkDragDisabled]="isCdkDragDisabled">
          </app-floors-swiper-el>
      </ul>
    </div>
  </div>


  <div class="addfloor-with-btn-statuses-wrapper">
    <div *ngIf="currentUser.role !== UserRole.ROLE_EMPLOYEE"
         [@modalAnimation]
         (appOutsideClick)="closeAddFloorForm()"
         class="add-floor-menu-with-btn-wrapper"
    >
      <button type="button" id="addFloor" class="add-floor-btn"
              (click)="floorsShortList.length > 0 && !isAddFloorFormOpened ? isAddFloorMenuOpened = true : isAddFloorFormOpened = true;
              projectViewStore.updateIsCdkDragDisabled(true)">
        <svg class="create-icon">
          <use spriteVersion iconName="create-icon"></use>
        </svg>
        <span>Add Floor</span>
      </button>
      <div class="add-floor-menu" *ngIf="isAddFloorMenuOpened">
        <button type="button" id="createNewFloor" class="create-new-floor-btn" (click)="isAddFloorFormOpened = true; isAddFloorMenuOpened = false">
          <svg class="create-icon">
            <use spriteVersion iconName="create-icon"></use>
          </svg>
          Create New Floor
        </button>
        <p class="duplicate-text">Duplicate</p>
        <ul class="duplicate-floor-variants-list">
          <li *ngFor="let floor of floorsShortList" class="duplicate-floor-variants__item" [id]="floor.id"
              (click)="createNewFloor(null, floor.id); isAddFloorMenuOpened = false">{{ floor.name }}
          </li>
        </ul>
      </div>

      <form class="form-create-floor"
            [formGroup]="createFloorForm"
            firstInputAutofocus
            #createFloorNgForm="ngForm"
            (ngSubmit)="createFloor()"
            *ngIf="isAddFloorFormOpened">
        <input type="text" class="input-create-item" id="createFloorInput" formControlName="name"
               formInput="FLOOR_UNIT_NAME">
        <p class="small-error-text-field" id="floorCreateNameError"
           *ngIf="createFloorNgForm.submitted && createFloorForm.controls['name'].invalid">{{ createFloorForm.controls['name'].getError('error') }}</p>
        <div class="form-create-btn-wrapper">
          <button type="submit" id="createFloorBtn" class="create-item-btn">Add Floor</button>
          <button type="reset" id="canselBtnCreateFloor" class="cansel-create-btn"
                  (click)="isAddFloorFormOpened = false; projectViewStore.updateIsCdkDragDisabled(false); createFloorForm.controls['name'].setValue(null)">
            <svg class="close-icon-form">
              <use spriteVersion iconName="close-icon"></use>
            </svg>
          </button>
        </div>
      </form>
    </div>

    <div *ngIf="currentUser.role !== UserRole.ROLE_EMPLOYEE" class="customize-status-wrapper"
         (appOutsideClick)="statusMenuBtn.removeAttribute('open')" [enabled]="statusMenuBtn.hasAttribute('open')">
      <button #statusMenuBtn type="button" class="customize-status-btn"
              (click)="statusMenuBtn.toggleAttribute('open')"></button>
      <ul class="customize-menu">
        <li class="customize-menu__item" (click)="statusMenuBtn.removeAttribute('open'); openCustomizeStatusesForm = true">
          Customize Status
        </li>
      </ul>
    </div>

  </div>
</div>

<div class="backdrop manual" *ngIf="deletingPopupOpened" show="true">
  <div (appOutsideClick)="deletingPopupOpened = false" class="modal-narrow">
    <button type="button" id="btnCloseModal" class="close-modal-btn close-modal-deleting"
            (click)="deletingPopupOpened = false">
      <svg class="close-modal-icon">
        <use spriteVersion iconName="modal-close"></use>
      </svg>
    </button>

    <div class="confirmDeleting-wrapper">
      <p class="confirmDeleting-title">Delete “{{ selectedFloor.name }}”?</p>
      <p class="confirmDeleting-text">Delete an existing floor in the system. If you delete this floor, it will also
        delete:</p>
      <ul class="confirmDeleting-list">
        <li> - All units on this floor</li>
        <li> - All rooms of this floor</li>
      </ul>
    </div>

    <div class="form-btn-wrapper">
      <button type="button" id="btnCancel" class="btn-cancel" (click)="deletingPopupOpened = false">Cancel</button>
      <button type="submit" id="btnDeleteProject" class="btn-delete" [disabled]="deletingBtnDisabled" (click)="deleteFloor()">
        <svg class="btn-delete__icon">
          <use spriteVersion iconName="trash-delete-icon"></use>
        </svg>
        Delete Floor
      </button>
    </div>
  </div>
</div>

<app-status-color-picker *ngIf="openCustomizeStatusesForm" (close)="openCustomizeStatusesForm = false"
                         [projectStatuses]="projectViewStore.projectStatuses$ | async"
                         (deletedWithReplacement)="projectViewStore.deleteStatusWithReplacement($event)"
                         (created)="openCustomizeStatusesForm = false; projectViewStore.loadProjectStatuses();
                                    projectViewStore.loadFloor(of({floorId: this.currentFloor.id}))">
</app-status-color-picker>
