import {Company} from "./company";

export class Project {
  id: number;
  name: string;
  projectCode: string;
  publicKey: string;
  publicId: string;
  number: string;
  address1: string;
  address2: string;
  city: string;
  zipCode: string;
  state: string;
  clientName: string;
  startDate: number;
  endDate: number;
  membersCount: number;
  company: Company = new Company();
  status: number;
  version: number;
}

export class ProjectCustomStatuses {
  id: number;
  color: string;
  name: string;
  orderNumber: number;
  defaultStatus: boolean;
  inUse: boolean;
  errorName: boolean;
  error: boolean;
  changed: true;
}

export enum  ProjectCustomColors {
  RED = '#E3443A',
  ORANGE = '#EAA439',
  YELLOW = '#F4EF4F',
  MINT = '#8FBAAC',
  BLUE = '#1249EC',
  VIOLET = '#8555D1',
  LIGHT_VIOLET = '#DA33E9',
  BROWN = '#A85B2E',
  LIGHT_BLUE = '#338EE9',
  GREEN = '#33E96A',
  PINK = '#F30F76',
  GREY = '#C6CED9'
}
