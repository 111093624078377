import {Injectable} from "@angular/core";
import {from, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DomainsService {

  constructor() { }

  checkSite(subdomain: string): Observable<boolean> {
    const url = `https://${subdomain}.colorjob.com`;

    return from(
      fetch(url, { method: 'GET', mode: 'no-cors' })
        .then(() => true)
        .catch(() => false)
    );
  }
}
