<app-sub-header [title]="[userIdFromUrl ? 'User Profile' : 'My Profile']"></app-sub-header>

<div class="settings-container">
  <div class="userSettings-img-wrapper">
    <img class="user-img" [src]="userImg" alt="user avatar" *ngIf="userImg; else defaultAvatar">

    <ng-template #defaultAvatar>
      <svg class="default-avatar-icon">
        <use spriteVersion iconName="user-profile-colored"></use>
      </svg>
    </ng-template>
    <p class="userName-settings-title">{{userInfo && userInfo.name}}</p>
  </div>
  <div class="settings-wrapper userSettings-wrapper">
    <div class="settings-wrapper__header">
      <p class="settings-wrapper__title">Profile Information</p>
      <div class="userSettings-wrapper__header-wrapper-btns">
        <button type="button" id="btnNotification" class="settings-wrapper__btn-edit userSettings__btn-edit" [disabled]="isLoading" (click)="notificationSettingsOpened = true" *ngIf="currentUser.role !== UserRole.ROLE_SUPERADMIN && currentUser?.id === userInfo?.id">
          <svg class="edit-icon">
            <use spriteVersion iconName="bell-icon"></use>
          </svg>
          Notifications
        </button>

        <button type="button" id="btnEdit" class="settings-wrapper__btn-edit userSettings__btn-edit" [disabled]="isLoading" (click)="userEditFormOpened = true" *ngIf="isShowEditBtn()">
          <svg class="edit-icon">
            <use spriteVersion iconName="edit-icon"></use>
          </svg>
          Edit User
        </button>

        <button type="button" id="btnChangePassword" class="settings-wrapper__btn-edit userSettings__btn-edit" [disabled]="isLoading" *ngIf="currentUser?.id === userInfo?.id" (click)="userChangePasswordFormOpened = true">
          <svg class="edit-icon">
            <use spriteVersion iconName="profile-key"></use>
          </svg>
          Change Password
        </button>
      </div>
    </div>

    <div class="table-details-wrapper">

      <ul *ngIf="userInfo && !isLoading" class="table-details">
          <li class="table-details-info-row">
            <p class="table-details__title">ID</p>
            <p class="table-details__info">{{userInfo.id}}</p>
          </li>
          <li class="table-details-info-row">
            <p class="table-details__title">Full Name</p>
            <p class="table-details__info">{{userInfo.name}}</p>
          </li>
          <li class="table-details-info-row">
            <p class="table-details__title">Phone Number</p>
            <p class="table-details__info">{{userInfo.countryCode}} {{userInfo.phone | phonePrettify}}</p>
          </li>
          <li class="table-details-info-row">
            <p class="table-details__title">Email</p>
            <p class="table-details__info">{{userInfo.email}}</p>
          </li>
          <li class="table-details-info-row" *ngIf="userInfo.role !== UserRole.ROLE_SUPERADMIN ">
            <p class="table-details__title">Company</p>
            <p class="table-details__info">{{userInfo.company.name}}</p>
          </li>
          <li class="table-row-projects-wrapper table-details-info-row" *ngIf="userInfo.role === UserRole.ROLE_EMPLOYEE || userInfo.role === UserRole.ROLE_PROJECT_MANAGER">
            <p class="table-details__title">Projects</p>
            <div class="table-details__info table-details-short-projects-list">
              <ul><li *ngFor="let project of userInfo.projects">{{project.name}}</li></ul>
            </div>
          </li>
        <li class="table-details-info-row">
          <p class="table-details__title">Role</p>
          <p class="table-details__info">{{getRoleDescByEnum.get(userInfo.role)}}</p>
        </li>
      </ul>

      <app-loader-profile *ngIf="isLoading"></app-loader-profile>
    </div>

  </div>

<!--  projects list in user profile -->

  <div class="settings-wrapper userSettings-wrapper" *ngIf="userInfo && userInfo.projects.length !== 0 && !isLoading">
    <div class="settings-wrapper__header">
      <p class="settings-wrapper__title">Projects</p>
    </div>

    <div class="table-details-wrapper" >

      <table *ngIf="userInfo" class="table-details">
        <tr *ngFor="let project of userInfo.projects; let i = index" class="table-details__project-row">
          <td class="table-details__title table-details-projectTitle-wrapper" [attr.isPaddingTop]="i === 0" >
            <div class="table-details__projectInfo">
              <span class="table-details-projectNumber">{{project.publicId}}</span>
              <span class="table-details-projectName">{{project.name}}</span>
            </div>
          </td>
          <td class="viewProject-column" [attr.isPaddingTop]="i === 0"  *ngIf="(currentUser.role === UserRole.ROLE_PROJECT_MANAGER && findUserProject(project.id)) || currentUser.role !== UserRole.ROLE_PROJECT_MANAGER">
            <a id="viewProject" class="view-link" [routerLink]="currentUser.role !== UserRole.ROLE_EMPLOYEE  ?
             ['/system/company', userInfo.company.id, 'projects', project.id, 'settings'] : ['/system/company',  userInfo.company.id, 'projects', project.id, 'view'] ">
              View Project
            </a>
          </td>
        </tr>
      </table>
    </div>

  </div>


  <button type="button" id="deleteUserOpenModal" class="btn-delete" (click)="isOpenConfirmDeletingModal = true"
          *ngIf="isShowDeleteBtn()">
    <svg class="btn-delete__icon">
      <use spriteVersion iconName="trash-delete-icon"></use>
    </svg>
    {{userIdFromUrl && userInfo && currentUser.id !== userInfo.id ? 'Delete User' : 'Delete Account'}}
  </button>


  <button type="button" id="deleteUserOpenModalSuperAdmin" class="btn-delete" (click)="isOpenConfirmDeletingModal = true" *ngIf="!isShowDeleteBtn() && (currentUser.role === UserRole.ROLE_SUPERADMIN || currentUser.role === UserRole.ROLE_ADMIN) && userIdFromUrl">
    <svg class="btn-delete__icon">
      <use spriteVersion iconName="trash-delete-icon"></use>
    </svg>
    {{currentUser.id === userInfo?.id && currentUser.role === UserRole.ROLE_ADMIN ? 'Delete Account' : 'Delete User'}}
  </button>
</div>



<div class="backdrop manual" [attr.show]="isOpenConfirmDeletingModal">
  <div [enabled]="isOpenConfirmDeletingModal" (appOutsideClick)="isOpenConfirmDeletingModal = false" class="modal-delete modal-narrow">
    <button type="button" id="closeBtn" class="close-modal-btn close-modal-deleting" (click)="isOpenConfirmDeletingModal = false">
      <svg class="close-modal-icon">
        <use spriteVersion iconName="modal-close"></use>
      </svg>
    </button>

    <div class="confirmDeleting-wrapper">
      <p class="confirmDeleting-title">
        {{userIdFromUrl ? 'Delete User ' + userInfo?.name + '?' : 'Delete your account?'}}
      </p>
      <p class="confirmDeleting-text" *ngIf="userIdFromUrl">
        You really want to delete this user. All information about this user will be deleted, as well as access to the system
      </p>
      <p class="confirmDeleting-text" *ngIf="!userIdFromUrl">
        If your profile is deleted, you will lose access to this company. This action is irreversible
      </p>


    </div>

    <div class="form-btn-wrapper">
      <button type="button" class="btn-cancel" (click)="isOpenConfirmDeletingModal = false">Cancel</button>
      <button type="submit" id="btnDelete" class="btn-delete" [disabled]="deletingBtnDisabled" (click)="deleteUser()">
        <svg class="btn-delete__icon">
          <use spriteVersion iconName="trash-delete-icon"></use>
        </svg>
        Delete {{userIdFromUrl ? 'User' : 'Account'}}
      </button>
    </div>
  </div>
</div>

<app-email-notification-settings *ngIf="notificationSettingsOpened" (close)="notificationSettingsOpened = false" [currentUser]="currentUser"></app-email-notification-settings>

<app-user-profile-form *ngIf="userEditFormOpened" (close)="userEditFormOpened = false" (created)="userEditFormOpened = false; getUserInfo()"
                       [currentUser]="currentUser" [user]="userInfo"></app-user-profile-form>

<app-change-password-form *ngIf="userChangePasswordFormOpened" (close)="userChangePasswordFormOpened = false" (created)="userChangePasswordFormOpened = false; getUserInfo()"></app-change-password-form>



