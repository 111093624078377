import {Directive, ElementRef, OnInit, OnDestroy, Output, EventEmitter, Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

@Directive({
  selector: '[visible]'
})
export class VisibilityObserverDirective implements OnInit, OnDestroy {
  private observer: IntersectionObserver;
  @Output() visible: EventEmitter<any> = new EventEmitter<any>();
  constructor(private elementRef: ElementRef, @Inject(PLATFORM_ID) public platformId: any) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId) && this.elementRef.nativeElement.getAttribute('seen') !== 'true') {
      this.observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.visible.emit();
            this.observer.disconnect();
          }
        });
      });

      this.observer.observe(this.elementRef.nativeElement);
    }
  }

  ngOnDestroy() {
    if (this.observer) this.observer.disconnect();
  }
}
