import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  amountRowArr = 1;
  @Input() isDashboardListLoading = false;

  constructor() {
    if(!this.isDashboardListLoading) {
      this.amountRowArr = Math.floor(window.innerHeight / 45) - 3;
      return
    }
    this.amountRowArr = 4;
  }
}
