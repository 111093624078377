import {Company} from "./company";
import {Project} from "./project";

export class User {
  id: number;
  name: string;
  role: UserRole;
  email: string;
  countryCode: string;
  phone: string;
  company: Company;
  projects: Project[];
  projectIds: number[];
  version: number;
  currentCountryCode: string;
}

export enum UserRole {
  ROLE_SUPERADMIN = "ROLE_SUPERADMIN",
  ROLE_ADMIN = "ROLE_ADMIN",
  ROLE_EMPLOYEE = "ROLE_EMPLOYEE",
  ROLE_PROJECT_MANAGER = "ROLE_PROJECT_MANAGER"
}

export class InviteUser {
  email: string;
  role: UserRole;
  companyId: number;
  projectIds: number[];
}

export class UserLocation {
  country_name: string;
  country_code2: string;
}
