import {FormFile} from "./material";

export class Document {
  id: number;
  type: DocumentType;
  name: string;
  file: FormFile;
  link: string;
  createdAt: number;
}

export enum DocumentType {
  FILE = "FILE",
  LINK = "LINK"
}
