<div *ngFor="let config of list" #notif [ngClass]="config.type" [id]="'notification-' + config.id" class="notification">
  <span class="icon-wrapper">
    <svg class="notify-icon" width="16" height="16">
      <use spriteVersion [iconName]="config.type + '-icon-notify'"></use>
    </svg>
  </span>
  <p class="text">{{config.msg}}</p>
  <svg class="close-icon" width="20" height="20" (click)="notif.remove()">
    <use spriteVersion iconName="close-icon"></use>
  </svg>
</div>
