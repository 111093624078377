import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CompanyPlan} from "../_models/company";
import {apiUrl} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CompanyPlanService {

  constructor(private http: HttpClient) { }

  getCompanyPlans(): Observable<CompanyPlan[]>  {
    return this.http.get<CompanyPlan[]>(`${apiUrl}/companyPlan`, {withCredentials: true})
  }


}
