<div class="backdrop">
  <div class="modal-preview">
    <div class="modal-preview-header-wrapper">
      <div class="modal-preview-title-wrapper">
        <p class="modal-preview-title">{{document ? document.file.name : file.name}}</p>
        <p class="modal-preview-file-info">
          <span>{{document ? (document.file.sizeBytes | fileSizePrettify) : (file.sizeBytes | fileSizePrettify)}}</span>
          {{getTimeCreatedAtDocument(document ? document.createdAt : file.timestamp)}}
        </p>
      </div>
      <div class="modal-preview-btns-wrapper">
        <button class="modal-preview-download-btn" type="button" (click)="downloadFile()">
          <span>Download</span>
          <svg class="download-icon">
            <use spriteVersion iconName="download"></use>
          </svg>
        </button>
        <button type="button" id="btnCloseModalPreview" class="close-modal-btn close-modal-deleting" (click)="close.emit()">
          <svg class="close-modal-icon">
            <use spriteVersion iconName="modal-close"></use>
          </svg>
        </button>
      </div>
    </div>
    <div class="image-preview-wrapper" *ngIf="isImage">
      <img class="image-preview" [src]="fileUrlForViewer" [alt]="document ? document.file.name : file.name">
    </div>
    <ngx-doc-viewer
      class="doc-viewer"
      *ngIf="!isNoPreview && !isImage"
      [url]="fileUrlForViewer"
      [viewer]="viewerType"
      [googleCheckContentLoaded]="true"
      (loaded)="isLoading = false"
    >
    </ngx-doc-viewer>

    <ng-template [ngIf]="isNoPreview">
      <div class="no-preview-wrapper">
        <p class="no-preview-text">No preview available</p>
      </div>
    </ng-template>

    <ng-template [ngIf]="isLoading">
      <div class="loading-wrapper">
        <div class="loading-button" loader="white" [showLoader]="isLoading"></div>
      </div>
    </ng-template>
  </div>
</div>
