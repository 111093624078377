<div class="backdrop">
  <div class="modal">
    <div class="email-notif-settings-wrapper">
      <button type="button" id="btnClose" class="close-modal-btn" (click)="close.emit()">
        <svg class="close-modal-icon">
          <use spriteVersion iconName="modal-close"></use>
        </svg>
      </button>

      <div class="email-notif-settings-inner-wrapper">
        <p class="form-modal-title">Email Notifications</p>

        <ul class="email-notif-switcher-list">
          <li class="email-notif-switcher-item">
            <ng-toggle
              [value]="isSelectAllNotifications"
              [color]="{unchecked: '#D8D8D8', checked: '#37A702'}"
              (change)="switchNotifications('all')"
            ></ng-toggle>

            All notifications
          </li>
          <li class="email-notif-switcher-item" *ngIf="currentUser.role !== UserRole.ROLE_ADMIN">
            <ng-toggle
              [value]="notificationSettings.projects"
              [color]="{unchecked: '#D8D8D8', checked: '#37A702'}"
              (change)="switchNotifications('projects')"
            ></ng-toggle>

            <p class="email-notif-text">
              Projects
              <span class="email-notif-desc">When you are added to a new project</span>
            </p>
          </li>

          <li class="email-notif-switcher-item">
            <ng-toggle
              [value]="notificationSettings.tasks"
              [color]="{unchecked: '#D8D8D8', checked: '#37A702'}"
              (change)="switchNotifications('tasks')"
            ></ng-toggle>

            <p class="email-notif-text">
              Tasks
              <span class="email-notif-desc">When you are assigned a new task</span>
            </p>
          </li>

          <li class="email-notif-switcher-item">
            <ng-toggle
              [value]="notificationSettings.areaComments"
              [color]="{unchecked: '#D8D8D8', checked: '#37A702'}"
              (change)="switchNotifications('areaComments')"
            ></ng-toggle>

            <p class="email-notif-text">
              Area Comments
              <span class="email-notif-desc">When you are mentioned in an area comment</span>
            </p>
          </li>
        </ul>

      </div>


      <div class="form-btn-wrapper">
        <button type="button" class="btn-cancel" id="btnCancel" (click)="close.emit()">Cancel</button>
        <button type="submit" loader="white" [disabled]="submitBtnDisabled" [showLoader]="submitBtnDisabled" (click)="submit()" class="btn-invite" id="btnSave">Save</button>
      </div>
    </div>
  </div>
</div>
