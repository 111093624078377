import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Page} from "../_models/page";
import {apiUrl} from "../../environments/environment";
import {Material, OrderStatus, SubmittalStatus} from "../_models/material";

@Injectable({
  providedIn: 'root'
})
export class MaterialsService {

  constructor(private http: HttpClient) { }

  getAllMaterialsListByParams(projectId: number,
                              submittalStatus: SubmittalStatus,
                              orderStatus: OrderStatus,
                              paymentStatusId: number,
                              query: string, sortField: string,
                              sortDirection: string, page: number, pageSize: number):Observable<Page<Material>> {
    let params = new HttpParams().set('page', page);
    params = params.set('projectId', projectId);
    if (submittalStatus) params = params.set('submittalStatus', submittalStatus);
    if (orderStatus) params = params.set('orderStatus', orderStatus);
    if (paymentStatusId) params = params.set('paymentStatusId', paymentStatusId);
    if (query && query.length > 0) params = params.set('query', query);
    if (sortField) params = params.set('sortField', sortField);
    if (sortDirection) params = params.set('sortDirection', sortDirection);
    if (pageSize) params = params.set('pageSize', pageSize);
    return this.http.get<Page<Material>>(`${apiUrl}/material/list`, {params: params, withCredentials: true})
  }

  export(projectId: number,
         submittalStatus: SubmittalStatus,
         orderStatus: OrderStatus,
         paymentStatusId: number,
         query: string, sortField: string,
         sortDirection: string) {
    let params = new HttpParams();
    params = params.set('projectId', projectId);
    if (submittalStatus) params = params.set('submittalStatus', submittalStatus);
    if (orderStatus) params = params.set('orderStatus', orderStatus);
    if (paymentStatusId) params = params.set('paymentStatusId', paymentStatusId);
    if (query && query.length > 0) params = params.set('query', query);
    if (sortField) params = params.set('sortField', sortField);
    if (sortDirection) params = params.set('sortDirection', sortDirection);

    return this.http.get(`${apiUrl}/material/export`, {
      params: params,
      withCredentials: true,
      responseType: 'blob',
      observe: 'response'
    })
  }

  createMaterial(projectId: number, material: FormData) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(`${apiUrl}/material`, material,{params: {projectId: projectId}, headers: headers, observe: "response", withCredentials: true})
  }

  updateMaterial(material: FormData) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.put(`${apiUrl}/material`, material,{headers: headers, observe: "response", withCredentials: true})
  }

  updateSubmittalStatus(materialId: number, status: SubmittalStatus) {
    let params = new HttpParams().set('status', status);
    return this.http.put(`${apiUrl}/material/${materialId}/submittalStatus`, null,{params: params, withCredentials: true})
  }

  updateOrderStatus(materialId: number, status: OrderStatus) {
    let params = new HttpParams().set('status', status);
    return this.http.put(`${apiUrl}/material/${materialId}/orderStatus`, null,{params: params, withCredentials: true})
  }

  deleteMaterial(materialIds: number[]) {
    let params = new HttpParams();
    materialIds.forEach(e => params = params.append('ids', e));

    return this.http.delete(`${apiUrl}/material`, {
        params: params,
        observe: "response",
        withCredentials: true
      })
  }
}
