import {Area} from "./area";

export class Room {
  id: number;
  name: string;
  subtitle: string;
  publicId: string;
  orderNumber: number;
  uniqNumber: number;
  areas: Area[] = [];
  version: number;
  templateVersion: number;
  templateName: string;
  usageAmount: number = 1;
}
