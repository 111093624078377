import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {FormFile} from "../../../../../../_models/material";
import {Subject, takeUntil} from "rxjs";
import {Document, DocumentType} from "../../../../../../_models/document";
import {GlobalStore} from "../../../../../../global.store";
import {DocumentsService} from "../../../../../../_services/documents.service";
import {NotificationService} from "../../../../../../_services/notification.service";
import {minLengthValidator, requiredValidator} from "../../../../../../_util/validators";
import {UserRole} from "../../../../../../_models/user";
import {DrawingsService} from "../../../../../../_services/drawings.service";

@Component({
  selector: 'app-drawing-form',
  templateUrl: './drawing-form.component.html',
  styleUrls: ['./drawing-form.component.scss']
})
export class DrawingFormComponent {
  @Output() created: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  drawingForm: FormGroup;
  linkInitialName: string;
  filesList: FormFile[] = [];
  submitBtnDisabled = false;
  selectedDocumentType: DocumentType = DocumentType.FILE

  projectId: number

  private readonly destroy$ = new Subject<void>()
  private _drawing = new Document();

  get drawing(): Document {
    return this._drawing;
  }

  @Input()
  set drawing(value: Document) {
    if (value) {
      this._drawing = {...value};
      this.fillDocumentForm();
      this.linkInitialName = this._drawing.name
      if(value.type === DocumentType.LINK) this.selectedDocumentType = DocumentType.LINK
    } else {
      this.fillDocumentForm();
    }
  }

  constructor(private fb: FormBuilder,
              readonly globalStore: GlobalStore,
              private drawingsService: DrawingsService,
              private notifService: NotificationService) {
    this.globalStore.projectId$.pipe(takeUntil(this.destroy$)).subscribe(projectId => this.projectId = projectId)
  }

  subscribeToFormChanges() {
    this.drawingForm.valueChanges.subscribe(() => {
      this._drawing.name = this.drawingForm.controls['name'].value;
      this._drawing.link = this.drawingForm.controls['link'].value;
    })
  }

  fillDocumentForm() {
    this.drawingForm = this.fb.group({
      id: [{value: this._drawing.id, disabled: true}],
      name: [this._drawing.name, this.selectedDocumentType === DocumentType.LINK || this._drawing.id ? [minLengthValidator('LINK_NAME'), requiredValidator()] : []],
      file: [this._drawing.file],
      link: [{value: this._drawing.link, disabled: this._drawing.id}],
      createdAt: [this._drawing.createdAt],
    })
    this.subscribeToFormChanges();
  }

  changeDocumentType(type: 'file' | 'link') {
    switch (type) {
      case "file": {
        this.selectedDocumentType = DocumentType.FILE
        this.drawingForm.controls['name'].setValue(null);
        this.drawingForm.controls['link'].setValue(null);
        this.drawingForm.controls['name'].setValidators(null);
        this.drawingForm.controls['link'].setValidators(null);
        this.drawingForm.controls['name'].updateValueAndValidity();
        this.drawingForm.controls['link'].updateValueAndValidity();
        break
      }
      case 'link': {
        this.selectedDocumentType = DocumentType.LINK
        this.drawingForm.controls['name'].setValidators([minLengthValidator('LINK_NAME'), requiredValidator()]);
        this.drawingForm.controls['link'].setValidators([requiredValidator()]);
        this.filesList = []
      }
    }
  }

  updateFiles(files: FormFile[]) {
    this.filesList = [...files]
  }


  submitForm() {
    if (this.drawingForm.invalid) return
    this.submitBtnDisabled = true;

    const fd = new FormData();
    // @ts-ignore
    if(this.filesList !== 0) for (const file of this.filesList) fd.append('files', file.fileToSave);


    if (!this._drawing.id) {
      this.drawingsService.createDrawing(this.projectId, this._drawing.name, this._drawing.link, fd, this.filesList.length).subscribe((data) => {
        this.created.emit(data);
        this.notifService.successNotification("Changes have been saved");
      }, error => this.submitBtnDisabled = false)
    } else {
      this.drawingsService.updateDrawing(this.projectId, this._drawing.id, this._drawing.name).subscribe((data) => {
        this.created.emit(data);
        this.notifService.successNotification("Changes have been saved");
      }, error => this.submitBtnDisabled = false);
    }
  }

  protected readonly UserRole = UserRole;
  protected readonly DocumentType = DocumentType;
}
