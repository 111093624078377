import { Component } from '@angular/core';

@Component({
  selector: 'app-loader-units',
  templateUrl: './loader-units.component.html',
  styleUrls: ['./loader-units.component.scss']
})
export class LoaderUnitsComponent {

}
