import {Component, OnInit} from '@angular/core';
import {Page} from "../../../../../_models/page";
import {Company} from "../../../../../_models/company";
import {SortType} from "../../../../../config/constants";
import {CompaniesService} from "../../../../../_services/companies.service";
import {DashboardStore} from "../dashboard.store";
import {GlobalStore} from "../../../../../global.store";

@Component({
  selector: 'app-dashboard-companies-table',
  templateUrl: './dashboard-companies-table.component.html',
  styleUrls: ['./dashboard-companies-table.component.scss']
})
export class DashboardCompaniesTableComponent implements OnInit{
    companiesPage: Page<Company> = new Page<Company>();
    isLoadingCompanies = false;
    sorting: any = {};
    isIOS = this.globalStore.isIOS$;
    page = 0;
    pageSize = 10;

  constructor(private companyService: CompaniesService,
              readonly dashboardStore: DashboardStore,
              readonly globalStore: GlobalStore) {
  }

  ngOnInit() {
    this.getCompanyList();
  }

  getCompanyList() {
    let sortingField;
    let sortingDirection;

    if (Object.keys(this.sorting).length > 0) {
      sortingField = Object.keys(this.sorting)[0];
      sortingDirection = this.sorting[Object.keys(this.sorting)[0]];
    }

    this.isLoadingCompanies = true;

    this.companyService.getCompanyListByParams(null, null, null, null, sortingField, sortingDirection, this.page, this.pageSize).subscribe((data) => {
      if (data.totalPages > 0 && this.page + 1 > data.totalPages) {
        this.page = data.totalPages - 1;
        this.getCompanyList();
      } else {
        if (this.page === 0) this.companiesPage = data;
        else this.companiesPage = {
          ...data,
          content: [...this.companiesPage.content, ...data.content]
        };
        this.dashboardStore.updateCompaniesTotalAmount(data.totalElements)
        this.isLoadingCompanies = false;
      }
    });
  }

  onSortBy(sortBy: string) {
    this.page = 0;
    if (this.sorting[sortBy] !== undefined) this.sorting[sortBy] = this.sorting[sortBy] === SortType.ASC ? SortType.DESC : SortType.ASC;
    else {
      this.sorting = {};
      this.sorting[sortBy] = SortType.DESC;
    }
    this.getCompanyList()
  }

  getNextPage() {
    if (this.companiesPage.last) return;

    this.page += 1;
    this.getCompanyList()
  }


  protected readonly SortType = SortType;
}
