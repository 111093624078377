import {Directive, ElementRef, EventEmitter, Inject, Output, PLATFORM_ID} from "@angular/core";
import {isPlatformBrowser} from "@angular/common";

@Directive({
  selector: '[isRighterThenMiddleOfScreen]'
})
export class IsRighterThenMiddleOfScreenDirective {

  @Output() isRighterThenMiddleOfScreen: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private elementRef: ElementRef, @Inject(PLATFORM_ID) public platformId: any) {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        if(this.forceCheck(this.elementRef.nativeElement)) this.isRighterThenMiddleOfScreen.emit();
      });
    }
  }

  forceCheck(el: HTMLElement): boolean {
      const windowWidth = window.innerWidth;
      const elementLeft = el.getBoundingClientRect().left;
      const distanceToRight = windowWidth - elementLeft;
      return distanceToRight < windowWidth / 2;
  }
}
