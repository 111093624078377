<ng-scrollbar [style.height]="projectsPage.content.length > 0 && projectsPage.content.length < 4 ? projectsPage.content.length*48 + 46 + 'px' :  projectsPage.content.length === 0 ? '134px' : '218px'"
              [attr.isIOS]="(isIOS | async)" [autoHeightDisabled]="true" [track]="'all'" class="table-wrapper-with-dots">
  <div class="innerScrollBarWrapper">
    <div class="table-wrapper-with-scroll">
      <div class="table-projects" *ngIf="!projectsPage?.empty; else emptyList">
        <ul class="table-projects__header-row">
          <li class="table-projects__id-title fixed-left-column">
              <span class="table-title-wrapper" [attr.shouldBlack]="sorting['publicId']">
                ID
                <button class="table-sort-icon-btn" (click)="onSortBy('publicId')" [style.opacity]="sorting['publicId'] ? 1 : ''">
                  <svg class="table-sort" [attr.rotate]="sorting['publicId'] === SortType.DESC" *ngIf="sorting['publicId']; else grayicon">
                   <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                       <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>
          <li class="table-projects__name-title fixed-left-column-name" [attr.isWider]="companyId !== undefined ? 'true' : 'false'">
              <span class="table-title-wrapper" [style.min-width]="'142px'"  [attr.shouldBlack]="sorting['name']">
                Project Name
                <button class="table-sort-icon-btn" (click)="onSortBy('name')" [style.opacity]="sorting['name'] ? 1 : ''">
                  <svg class="table-sort" [attr.rotate]="sorting['name'] === SortType.DESC" *ngIf="sorting['name']; else grayicon">
                   <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                      <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>
          <li class="table-projects__status-title">
            <span class="table-title-wrapper" [attr.shouldBlack]="sorting['status']">
               Status
                <button class="table-sort-icon-btn" (click)="onSortBy('status')" [style.opacity]="sorting['status'] ? 1 : ''">
                  <svg class="table-sort" [attr.rotate]="sorting['status'] === SortType.DESC" *ngIf="sorting['status']; else grayicon">
                   <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                     <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>
          <li class="table-projects__project-number-title" [style.width]="'96px'">
              <span class="table-title-wrapper" [style.width]="'70px'" [attr.shouldBlack]="sorting['number']">
                <p class="text-ellipsis-wrapper" title="Project #">#</p>
                <button class="table-sort-icon-btn" (click)="onSortBy('number')" [style]="{'margin-left': '0', 'opacity': sorting['number'] ? 1 : ''}" >
                  <svg class="table-sort" [attr.rotate]="sorting['number'] === SortType.DESC" *ngIf="sorting['number']; else grayicon">
                    <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                      <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>

          <li class="table-projects__company-name-title" *ngIf="!companyId">
              <span class="table-title-wrapper" [style.min-width]="'99px'" [attr.shouldBlack]="sorting['company.name']">
                Company
                <button class="table-sort-icon-btn" (click)="onSortBy('company.name')" [style.opacity]="sorting['company.name'] ? 1 : ''">
                  <svg class="table-sort" [attr.rotate]="sorting['company.name'] === SortType.DESC" *ngIf="sorting['company.name']; else grayicon">
                    <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                      <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>

          <li class="table-projects__members-title">

              <span class="table-title-wrapper" [style.min-width]="'59px'" [attr.shouldBlack]="sorting['membersCount']">
                <p class="text-ellipsis-wrapper">Members</p>
                <button class="table-sort-icon-btn" (click)="onSortBy('membersCount')" [style]="{'margin-left': '0', 'opacity': sorting['membersCount'] ? 1 : ''}">
                  <svg class="table-sort" [attr.rotate]="sorting['membersCount'] === SortType.DESC" *ngIf="sorting['membersCount']; else grayicon">
                    <use spriteVersion iconName="sort-icon-up"></use>
                  </svg>

                  <ng-template #grayicon>
                    <svg class="table-sort">
                     <use spriteVersion iconName="sort-icon-default"></use>
                    </svg>
                  </ng-template>
                </button>
              </span>
          </li>
          <li class="table-projects__actions no-right-border"></li>
        </ul>

        <ul class="table-body-list">
          <li *ngFor="let project of projectsPage.content; index as i" [id]="project.id" class="table-projects__row">
            <p *ngIf="i == projectsPage.content.length - 2" (visible)="getNextPage()"></p>
            <ul class="item-info-list">
              <li class="company-id fixed-left-column" [routerLink]="['/system/company', project.company.id, 'projects', project.id, 'view']">
                <span class="item-code-way">
                  <span class="text-ellipsis-wrapper list-name-wrapper" [title]="project.publicId">
                    {{project.publicId}}
                  </span>
                </span>
              </li>
              <li class="company-name fixed-left-column-name"  [attr.isWider]="companyId !== undefined ? 'true' : 'false'" [routerLink]="['/system/company', project.company.id, 'projects', project.id, 'view']">
                <div class="project-name__wrapper">
                  <span class="list-name-wrapper" [title]="project.name">
                    {{project.name}}
                  </span>
                </div>
              </li>
              <li class="status-item">
                <div class="project-status-wrapper">
                  <div class="status-bar-colored" [attr.status]="project.status" [style.background]="'linear-gradient(to right, #FF4E00 0%, #FF4E00 ' + project.status + '%, #F0F2F6 ' + project.status + '%), #F0F2F6 100%'"></div>
                  {{project.status}} %
                </div>
              </li>
              <li class="project-number-item"><p class="text-ellipsis-wrapper project-number-wrapper" [title]="project.number">{{project.number}}</p></li>
              <li class="company-name-project-item" *ngIf="!companyId"><span class="project-company-wrapper">{{project.company.name}}</span></li>
              <li class="members-item">{{project.membersCount}}</li>
              <li tabindex="1" class="dots-actions fixed-column" id="dotsMenu"></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

  </div>
</ng-scrollbar>

<app-loader [isDashboardListLoading]="true" *ngIf="!projectsPage"></app-loader>



<ng-template #emptyList>
  <div class="empty-list-wrapper">
    <p class="empty-list-text" *ngIf="projectsPage.empty; else notFound">The list of projects is empty.</p>
    <ng-template #notFound>
      <p class="empty-list-text">No Projects found</p>
    </ng-template>
    <img class="empty-list-icon" src="assets/images/empty-list.png" alt="empty-list">
  </div>
</ng-template>


