import {Component, QueryList, ViewChildren} from '@angular/core';
import {NgSelectComponent} from "@ng-select/ng-select";
import {Page} from "../../../../../_models/page";
import {User, UserRole} from "../../../../../_models/user";
import {filter, Subject, takeUntil, tap} from "rxjs";
import {NotificationService} from "../../../../../_services/notification.service";
import {GlobalStore} from "../../../../../global.store";
import {
  documentTypeStringForSelector,
  modalAnimation,
  SortType,
} from "../../../../../config/constants";
import {originalOrder} from "../../../../../_util/utils";
import {Document, DocumentType} from "../../../../../_models/document";
import {DocumentsService} from "../../../../../_services/documents.service";
import {DatePipe} from "@angular/common";
import {TimePrettifyPipe} from "../../../../../_pipes/timePrettify.pipe";
import {FormFile} from "../../../../../_models/material";

@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.scss'],
  providers: [TimePrettifyPipe, DatePipe],
  animations: [modalAnimation],
})
export class DocumentsListComponent {
  @ViewChildren(NgSelectComponent) ngSelectComponent: QueryList<NgSelectComponent>;

  selectedDocumentType: DocumentType;

  sorting: any = {};
  query: string;
  hideCompleted = false;
  pageNumber = 0;
  pageSizeOnDesktop = 20;
  pageSizeOnMobile: number;
  isLoading = false;

  documentsPage: Page<Document>;
  multiSelectedDocuments: number[] = [];
  multiSelectEnabled = false;
  documentFormOpened = false;
  deletingPopupOpened = false;
  deletingBtnDisabled = false;
  isMultiSelectAll = false;

  selectedDocument: Document;
  selectedDocumentForPreview: Document;

  currentUser: User;

  isMobile= this.globalStore.isMobile$;
  isIOS = this.globalStore.isIOS$;

  companyId: number;
  projectId: number;

  currentTime = Date.now();

  private readonly destroy$ = new Subject<void>()

  constructor(private documentService: DocumentsService,
              private notifService: NotificationService,
              private date: DatePipe,
              private timePrettify: TimePrettifyPipe,
              readonly globalStore: GlobalStore) {
    this.globalStore.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId) => this.companyId = companyId);
    this.globalStore.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((currentUser) => this.currentUser = currentUser);

    this.globalStore.projectId$.pipe(
      takeUntil(this.destroy$),
      filter((projectId) => !!projectId),
      tap(projectId => this.projectId = projectId),
    ).subscribe(() => this.getDocumentsList());
  }

  getDocumentsList() {
    this.isLoading = true;
    this.multiSelectedDocuments = [];
    let sortingField;
    let sortingDirection;

    if (Object.keys(this.sorting).length > 0) {
      sortingField = Object.keys(this.sorting)[0];
      sortingDirection = this.sorting[Object.keys(this.sorting)[0]];
    }

    this.documentService.getDocumentsList(this.projectId, this.query, this.selectedDocumentType, sortingField, sortingDirection, this.pageNumber, this.pageSizeOnDesktop).subscribe(data => {
      if (data.totalPages > 0 && this.pageNumber + 1 > data.totalPages) {
        this.pageNumber = data.totalPages - 1;
        this.getDocumentsList();
      } else {
        this.documentsPage = data;
        this.isLoading = false;
      }
    });
  }

  changeShowNumber(newNumber: number) {
    this.pageSizeOnDesktop = newNumber;
    this.pageNumber = 0;
    this.pageSizeOnMobile = newNumber;
    this.getDocumentsList();
  }

  changeCurrentPage(newPage: number) {
    this.pageNumber = newPage;
    this.getDocumentsList();
    this.isMultiSelectAll = false;
  }


  deleteDocument(documentId: number) {
    this.deletingBtnDisabled = true;
    this.documentService.deleteDocuments(this.projectId,[documentId]).subscribe(() => {
      this.getDocumentsList();
      this.notifService.successNotification('Changes have been saved');
      this.deletingBtnDisabled = false;
    }, error => this.deletingBtnDisabled = false)
  }

  toggleCheckAll() {
    this.multiSelectedDocuments = [];
    if(this.isMultiSelectAll) {
      this.isMultiSelectAll = false;
    }
    else {
      this.documentsPage.content.map(task => this.multiSelectedDocuments.push(task.id))
      this.isMultiSelectAll = true
    }
  }

  toggleCheck(taskId: number) {
    if (this.multiSelectedDocuments.includes(taskId)) this.multiSelectedDocuments.splice(this.multiSelectedDocuments.indexOf(taskId), 1);
    else this.multiSelectedDocuments.push(taskId);

    this.multiSelectEnabled = this.multiSelectedDocuments.length > 0;
  }

  deleteSelected() {
    this.deletingBtnDisabled = true;
    this.documentService.deleteDocuments(this.projectId, this.multiSelectedDocuments).subscribe(() => {
      this.getDocumentsList()
      this.deletingPopupOpened = false;
      this.notifService.successNotification('Changes have been saved');
      this.deletingBtnDisabled = false;
    } ,error => this.deletingBtnDisabled = false)
  }

  getFiltersActiveAmount() {
    let result = 0;
    if (this.selectedDocumentType) result++;

    return result;
  }

  changeStatusInFilters(event: DocumentType) {
    this.selectedDocumentType = event
  }


  clearFilters() {
    this.selectedDocumentType = null;
    this.getDocumentsList();
  }

  getTimeCreatedAtDocument(createdAt: number) {
    let diffTime = this.currentTime - createdAt;
    let seconds = Math.floor(diffTime/1000);
    let minutes = Math.floor(seconds/60);
    let hours = Math.floor(minutes/60);
    let days = Math.floor(hours/24);
    if (days >= 2) return this.date.transform(createdAt, 'MMM d, y h:mm a')
    else return this.timePrettify.transform(createdAt)
  }
  onSortBy(sortBy: string) {
    if (this.sorting[sortBy] !== undefined) this.sorting[sortBy] = this.sorting[sortBy] === SortType.ASC ? SortType.DESC : SortType.ASC;
    else {
      this.sorting = {};
      this.sorting[sortBy] = SortType.DESC;
    }
    this.getDocumentsList();
  }

  isNoFilteringEnabled() {
    return this.getFiltersActiveAmount() === 0  && (!this.query || this.query.length === 0);
  }

  closeDeletingPopup() {
    this.deletingPopupOpened = false;
    setTimeout(() => this.selectedDocument = undefined, 250);
  }

  openPreviewDocument(document: Document) {
    this.selectedDocumentForPreview = document
  }


  isImageFormat(documentFile: FormFile) {
    return documentFile?.name.endsWith('.jpg') || documentFile?.name.endsWith('.jpeg') ||  documentFile?.name.endsWith('.png')
  }

  downloadFile(url: string, filename: string) {
   this.documentService.downloadFile(url).subscribe((blob) => {
      const urlBlob = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = urlBlob;
      link.download = filename;

      link.click();

      URL.revokeObjectURL(urlBlob);
    });
  }


  openLink(url: string, filename: string) {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.target = '_blank';
    link.setAttribute('data-title', filename)

    link.click();
    link.remove();
  }

  accessToDotsMenu(document: Document) {
    return (!document.link) || (document.link && this.currentUser.role !== UserRole.ROLE_EMPLOYEE)
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  protected readonly documentTypeByEnum = documentTypeStringForSelector;
  protected readonly SortType = SortType;
  protected readonly UserRole = UserRole;
  protected readonly originalOrder = originalOrder;
  protected readonly DocumentType = DocumentType;
}
