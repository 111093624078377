<div class="container">
  <div class="login-wrapper">
    <h1 class="auth-title add-info-title" *ngIf="currentUser && currentUser.role !== UserRole.ROLE_SUPERADMIN">Thank you for joining {{currentUser.company.name}}.</h1>
    <p class="add-info-subtitle">Please enter some additional information about yourself.</p>

    <form [formGroup]="addInfoForm" #form="ngForm" class="form-login" (ngSubmit)="onSubmit()">
      <div class="form__field-wrapper">
        <label class="form-label" for="name">Name
          <input formControlName="name" placeholder="Full Name" formInput="NAME" type="text" id="name" class="form-input"/>
        </label>
        <span class="error-message" id="nameError" *ngIf="form.submitted && addInfoForm.controls['name'].invalid">{{addInfoForm.controls['name'].getError('error')}}</span>
      </div>

      <div class="form__field-wrapper">
        <label class="form-label" for="phoneNumber">Phone Number</label>
          <div class="phone-with-code-wrapper">
            <app-countries-code-select
              formControlName="countryCode"
              [isError]="addInfoForm.controls['phone'].invalid && form.submitted"></app-countries-code-select>
            <span class="input-with-error-wrapper">
              <input formControlName="phone" [mask]="phoneMask" placeholder="(000) 000-0000" type="tel" id="phoneNumber" class="form-input"/>
            </span>
          </div>
        <span class="error-message" id="phoneError" *ngIf="form.submitted && addInfoForm.controls['phone'].invalid">{{addInfoForm.controls['phone'].getError('error')}}</span>
      </div>

      <button loader="white" [showLoader]="showLoader" class="btn-login" type="submit" id="btn-submit">Save and Continue</button>
    </form>
  </div>
</div>

